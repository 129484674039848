import styled, { css } from 'styled-components';
import { InputSize } from './types';

export const HtmlInput = styled.input`
  padding: 0;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  width: 100%;
  font-family: var(--font-family);

  &::placeholder {
    font-weight: 400;
    color: #9c9caa;
    font-size: 12px;
  }
`;

export const Container = styled.div<{
  size: InputSize;
  minWidth?: string;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #dfe1e5;
  border-radius: 4px;
  background-color: #fff;
  transition: border-color 0.2s ease-in-out;
  color: #1d1d35;
  min-width: ${({ minWidth }) => minWidth || 'auto'};

  &:hover {
    border-color: #b3b5c1;
  }

  &:focus-within {
    border-color: #009688;
  }

  &:disabled {
    background-color: #dfdfe8;
    color: #9c9caa;
    cursor: not-allowed;
  }

  padding: 0px 12px;

  ${({ size }) => {
    switch (size) {
      case InputSize.Small:
        return css`
          height: 32px;
        `;
      default:
        return css`
          height: 40px;
        `;
    }
  }};
`;
