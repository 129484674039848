export const TOKEN_CLASS_NAME = 'cq-token-strong';

const TOKEN_QUILL_PATTERN = new RegExp(
  `(<(span|strong) class="${TOKEN_CLASS_NAME}".+?</\\2>(\\s?</span>)?)`,
  'g',
);

const getTokenAsMarkdown = (title: string, propertyId: string) =>
  `[${title}|~property:${propertyId}]`;

const convertQuillFormatToText = (value: string) => {
  return value
    .replace(TOKEN_QUILL_PATTERN, (...rest) => {
      const elem = document.createElement('div');
      elem.innerHTML = rest[0];
      const dataset = (elem.querySelector(`.${TOKEN_CLASS_NAME}`) as any)
        ?.dataset;

      return dataset ? getTokenAsMarkdown(dataset.value, dataset.id) : '';
    })
    .replace(/(<br>)?<\/p><p>/g, '<br>');
};

const clearQuillValue = (value: string) => {
  const elem = document.createElement('div');
  elem.innerHTML = value.replace(/<br>/g, '\n');

  return elem.innerText;
};

export const makeShareableMessage = ({
  message,
  companyName,
  referralLink,
}: {
  message: string;
  companyName: string;
  referralLink: string;
}) => {
  return clearQuillValue(convertQuillFormatToText(message))
    .replaceAll(/\[Company name\|~property:companyName\]/g, companyName)
    .replaceAll(/\[Referral link\|~property:referralLink\]/g, referralLink);
};
