import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { FormField } from '../FormField';
import { FormControl, FormInputPropsToOmit } from '../types';
import { useControllerWithValidation } from '../useControllerWithValidation';
import { MultiSelect, Select } from '../../../ui/Select';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<React.ComponentProps<typeof Select>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  isMulti?: boolean;
}

export const SelectField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  label,
  description,
  name,
  control,
  clearOnUnmount,
  isMulti = false,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { value, ...controlProps },
    fieldState: { error },
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  return (
    <FormField
      name={name}
      label={label}
      error={error?.message}
      description={description}
    >
      {!isMulti && (
        <Select
          {...controlProps}
          {...inputProps}
          value={value ?? ''}
          data-test-id={`field-${controlProps.name}`}
        />
      )}

      {isMulti && (
        <MultiSelect
          {...controlProps}
          {...inputProps}
          value={value ?? []}
          options={inputProps.options as string[]}
          data-test-id={`field-${controlProps.name}`}
        />
      )}
    </FormField>
  );
};
