import React, { useCallback, useState } from 'react';
import { ModalBody, ModalFooter } from '../../kit/components/PromiseModal';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  IconButton,
} from '../../kit/ui/Button';
import {
  ActionUploadContainer,
  ActionUploadHeader,
  AddressLink,
  Description,
  FileName,
  FileRow,
  FileRowLeft,
  FileRowRight,
  ModalContent,
  Thumbnail,
  ThumbnailContainer,
  Title,
  When,
} from './styled';
import { FileUpIcon } from '../../kit/ui/icons/FileUp';
import { getGeoLink } from '../../utils/getGeoLink';
import { MapPinIcon } from '../../kit/ui/icons/MapPin';
import { FileDropZone } from '../../kit/components/FileDropZone';
import { useUploadFile } from '../../apiHooks/useUploadFile';
import { FileIcon } from '../../kit/ui/icons/File';
import { TrashIcon } from '../../kit/ui/icons/Trash';
import { formatFileSize } from '../Docs/helpers';
import { useUpdateAction } from '../../apiHooks/useUpdateAction';
import { ActionStatus } from '../../gql/graphql';
import { ProjectAction } from '../../apiHooks/useProjects';

const formatDate = (date: Date) => {
  return new Date(date).toLocaleString('en-US', {
    dateStyle: 'medium',
  });
};

const isPreviewable = (file: File) => {
  const fileType = file.type;
  return fileType.startsWith('image/') || fileType.startsWith('video/');
};

interface Props {
  action: ProjectAction;
  onClose: () => void;
}

enum UploadStatus {
  New = 'New',
  Uploading = 'Uploading',
  Success = 'Success',
  Error = 'Error',
}

type FileItem = {
  file: File;
  status: UploadStatus;
  doc: {
    id: number;
    name: string | null;
    metaData: { size: number; thumbnailUrl: string };
  } | null;
};

export const ActionUpload = ({ action, onClose }: Props) => {
  const { mutateAsync: uploadFile } = useUploadFile();
  const { mutateAsync: update } = useUpdateAction();
  const [newFiles, setNewFiles] = useState<FileItem[]>(() =>
    action.files.map((doc) => ({
      file: new File([], doc.name ?? 'unknown'),
      status: UploadStatus.Success,
      doc,
    })),
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const address = action.project?.address?.[0] ?? null;

  const handleAddFiles = useCallback((files: File[]) => {
    setNewFiles((prev) => [
      ...prev,
      ...files.map((file) => ({
        file,
        status: UploadStatus.New,
        doc: null,
      })),
    ]);
  }, []);

  const handleRemove = useCallback(
    (file: FileItem) => () => {
      setNewFiles((prev) => prev.filter((f) => f !== file));
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const filesToUpload = newFiles.filter((f) => f.status === UploadStatus.New);

    const uploadedFiles = await Promise.all(
      filesToUpload.map(async (file) => {
        try {
          const doc = await uploadFile({
            actionId: action.id,
            file: file.file,
          });

          return { file, status: UploadStatus.Success, doc };
        } catch (error) {
          return { file, status: UploadStatus.Error, doc: null };
        }
      }),
    );

    await update({
      uuid: action.uuid,
      dto: {
        status: ActionStatus.InReview,
        fileIds: [
          ...newFiles
            .filter((f) => f.doc)
            .map((f) => f.doc?.id)
            .filter((id): id is number => id !== undefined),
          ...uploadedFiles
            .map((f) => f.doc?.id)
            .filter((id): id is number => id !== undefined),
        ],
      },
    });

    setIsSubmitting(false);

    onClose();
  }, [action, newFiles, update, onClose, uploadFile, isSubmitting]);

  const isCompleted = action.status === ActionStatus.Completed;
  const isCancelled = action.status === ActionStatus.Cancelled;

  const isToday =
    !isCompleted &&
    !isCancelled &&
    action.dueDate &&
    new Date(action.dueDate).toDateString() === new Date().toDateString();
  const isOverdue =
    !isCompleted &&
    !isCancelled &&
    action.dueDate &&
    !isToday &&
    new Date(action.dueDate) < new Date();

  return (
    <ActionUploadContainer>
      <ModalBody width="750px">
        <ModalContent>
          <ActionUploadHeader>
            <FileUpIcon
              size="24px"
              color={
                (isToday && '#FFBD13') || (isOverdue && '#D54855') || '#9C9CAA'
              }
            />
            <div>
              <When>
                {action.dueDate ? formatDate(action.dueDate) : 'No due date'}

                {action.title && (
                  <>
                    {' · '}
                    <Title>{action.title}</Title>
                  </>
                )}
              </When>
              {address && (
                <AddressLink href={getGeoLink(address)}>
                  <MapPinIcon size="16px" />
                  {address}
                </AddressLink>
              )}

              {action.description && (
                <Description>{action.description}</Description>
              )}
            </div>
          </ActionUploadHeader>

          <FileDropZone onAddFiles={handleAddFiles} />

          {newFiles.length > 0 &&
            newFiles.map((file) => (
              <FileRow
                key={file.doc?.id || `${file.file.name}_${file.file.size}`}
              >
                {file.doc && (
                  <>
                    <FileRowLeft>
                      <ThumbnailContainer>
                        {file.doc.metaData?.thumbnailUrl ? (
                          <Thumbnail url={file.doc.metaData.thumbnailUrl} />
                        ) : (
                          <FileIcon size="16px" color="#9C9CAA" />
                        )}
                      </ThumbnailContainer>
                      <FileName>{file.doc.name}</FileName>
                    </FileRowLeft>
                    <FileRowRight>
                      {formatFileSize(file.doc.metaData.size)}
                      <IconButton
                        variant={ButtonVariant.Flat}
                        onClick={handleRemove(file)}
                      >
                        <TrashIcon size="16px" color="#9C9CAA" />
                      </IconButton>
                    </FileRowRight>
                  </>
                )}
                {file.status === UploadStatus.New && (
                  <>
                    <FileRowLeft>
                      <ThumbnailContainer>
                        {isPreviewable(file.file) ? (
                          <Thumbnail url={URL.createObjectURL(file.file)} />
                        ) : (
                          <FileIcon size="16px" color="#9C9CAA" />
                        )}
                      </ThumbnailContainer>
                      <FileName>{file.file.name}</FileName>
                    </FileRowLeft>
                    <FileRowRight>
                      {formatFileSize(file.file.size)}
                      <IconButton
                        variant={ButtonVariant.Flat}
                        onClick={handleRemove(file)}
                      >
                        <TrashIcon size="16px" color="#9C9CAA" />
                      </IconButton>
                    </FileRowRight>
                  </>
                )}
              </FileRow>
            ))}
        </ModalContent>
      </ModalBody>
      <ModalFooter>
        <Button
          className="cancel"
          size={ButtonSize.Large}
          onClick={onClose}
          variant={ButtonVariant.Secondary}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={newFiles.length === 0 || isSubmitting}
          size={ButtonSize.Large}
          variant={ButtonVariant.Primary}
        >
          Send {newFiles.length} file{newFiles.length > 1 ? 's' : ''} to review
        </Button>
      </ModalFooter>
    </ActionUploadContainer>
  );
};
