import React, { useMemo } from 'react';
import {
  List,
  PlaceholderPrimary,
  PlaceholderSecondary,
  Title,
} from './styled';
import { Panel } from '../../kit/ui/Panel';
import { useServiceVisits } from '../../apiHooks/useServiceVisits';
import { Visit } from '../../kit/components/Visit';

interface Props {
  hideIfEmpty?: boolean;
  showCompleted?: boolean;
}

export const Services = ({
  hideIfEmpty = false,
  showCompleted = false,
}: Props) => {
  const { data: visits = [], isLoading } = useServiceVisits();

  const filteredVisits = useMemo(() => {
    if (showCompleted) {
      return visits;
    }

    const result = visits.filter((visit) => !visit.isCompleted);

    result.sort((a, b) => {
      const aDate = a.startDate;
      const bDate = b.startDate;

      const aCompleted = a.isCompleted;
      const bCompleted = b.isCompleted;

      if (aCompleted && bCompleted) {
        return new Date(bDate).getTime() - new Date(aDate).getTime();
      }

      if (aCompleted) {
        return 1;
      }

      if (bCompleted) {
        return -1;
      }

      return new Date(bDate).getTime() - new Date(aDate).getTime();
    });

    return result;
  }, [visits, showCompleted]);

  if (isLoading) {
    return null;
  }

  if (hideIfEmpty && filteredVisits.length === 0) {
    return null;
  }

  return (
    <Panel>
      <Title>Service Visits ({filteredVisits.length})</Title>

      {filteredVisits.length === 0 && (
        <div>
          <PlaceholderPrimary>You have no services yet</PlaceholderPrimary>
          <PlaceholderSecondary>
            We look forward to work with you!
          </PlaceholderSecondary>
        </div>
      )}
      {filteredVisits.length > 0 && (
        <List>
          {filteredVisits.map((visit) => (
            <Visit key={visit.id} visit={visit} />
          ))}
        </List>
      )}
    </Panel>
  );
};
