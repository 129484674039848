import { useMutation } from '@tanstack/react-query';
import { documentsApi } from '../api/documents';

function downloadFile(blob: Blob, name: string) {
  const href = URL.createObjectURL(blob);
  const a = Object.assign(document.createElement('a'), {
    href,
    style: 'display:none',
    download: name,
  });
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(href);
  a.remove();
}

export const useDownloadDoc = () => {
  return useMutation<void, Error, string>(async (uuid) => {
    try {
      const { data, fileName } = await documentsApi.download(uuid);

      downloadFile(data, fileName);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  });
};
