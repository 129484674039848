import React, { useCallback, useMemo, useState } from 'react';
import { Container, Header, List } from './styled';
import { H2 } from '../../kit/ui/Heading';
import { useDocList } from '../../apiHooks/useDocList';
import { Input, InputSize } from '../../kit/ui/Input';
import { SearchIcon } from '../../kit/ui/icons/Search';
import { PlaceholderWithImage } from '../../kit/components/PlaceholderWithImage';
import placeholderImage from './placeholderImage.png';
import { DocRow } from './DocRow';
import { Button, ButtonVariant, IconButton } from '../../kit/ui/Button';
import { useModal } from '../../kit/components/PromiseModal';
import { Upload } from './Upload';
import { PlusIcon } from '../../kit/ui/icons/Plus';

export const Docs = () => {
  const { data: docs = [], isLoading } = useDocList();

  const [search, setSearch] = useState('');

  const { openModal } = useModal();

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [],
  );

  const filteredDocs = useMemo(() => {
    if (!search) {
      return docs;
    }

    return docs.filter(
      (doc) => doc.name?.toLowerCase().includes(search.toLowerCase()),
    );
  }, [docs, search]);

  const handleUploadCLick = useCallback(() => {
    openModal<void>(({ onClose }) => <Upload onClose={onClose} />, {
      title: 'Upload files',
    });
  }, [openModal]);

  if (isLoading) {
    return null;
  }

  return (
    <Container>
      <Header>
        <H2>Documents ({docs.length})</H2>
        <Input
          value={search}
          className="search"
          onChange={handleSearchChange}
          leftAddon={<SearchIcon size="12px" color="#9C9CAA" />}
          minWidth="240px"
          placeholder="Search..."
          size={InputSize.Small}
        />
        <IconButton
          variant={ButtonVariant.Primary}
          className="mobile"
          onClick={handleUploadCLick}
        >
          <PlusIcon size="16px" />
        </IconButton>
        <Button
          className="desktop"
          variant={ButtonVariant.Primary}
          onClick={handleUploadCLick}
        >
          Upload
        </Button>
      </Header>

      {docs.length === 0 && (
        <PlaceholderWithImage
          imgSrc={placeholderImage}
          imgMaxWidth="250px"
          primaryText="No documents here yet"
        />
      )}

      {docs.length > 0 && filteredDocs.length === 0 && (
        <PlaceholderWithImage
          imgSrc={placeholderImage}
          imgMaxWidth="250px"
          primaryText="No documents found"
          secondaryText="Try to search for something else"
        />
      )}

      {filteredDocs.length > 0 && (
        <List>
          {filteredDocs.map((doc) => (
            <DocRow key={doc.id} doc={doc} />
          ))}
        </List>
      )}
    </Container>
  );
};
