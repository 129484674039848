export const elementOrParentHasClass = (
  element: HTMLElement | null,
  className: string,
) => {
  let el = element;

  while (el) {
    if ('classList' in el && el.classList.contains(className)) {
      return true;
    }

    el = el.parentElement;
  }

  return false;
};
