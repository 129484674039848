import { sessionService } from '../services/SessionService';
import { ENV } from '../utils/env';
import { parseWorkspaceFromUrl } from '../utils/parseWorkspaceFromUrl';
import { ApiError } from './ApiError';

const BASE_URL = ENV.API_URL;

export const apiFetch = async <Response = any, Data = any>({
  url,
  method,
  data,
  headers: providedHeaders,
  requestContentType = 'application/json; charset=UTF-8',
}: {
  url: string;
  method: string;
  data?: Data;
  headers?: HeadersInit;
  requestContentType?: string;
}): Promise<Response> => {
  const headers = new Headers(providedHeaders);

  const isFormData = data instanceof FormData;

  //headers.append('Authorization', `Session ${getToken()}`);

  headers.append('Accept', '*/*');

  if (!isFormData && requestContentType) {
    headers.append('Content-Type', requestContentType);
  }

  let body;

  if (data) {
    body = isFormData ? data : JSON.stringify(data);
  }

  const res = await fetch(`${BASE_URL}${url}`, { headers, method, body });

  if (res.status === 401) {
    // TODO
    /*if (location.pathname !== '/login') {
          removeToken();
          location.href = `/login?redirect=${location.pathname}`;
        }*/
  }

  const contentType = res.headers.get('content-type');
  const isBlob = Boolean(res.headers.get('content-disposition'));

  if (contentType && contentType.includes('application/json')) {
    const json = await res.json();

    if (res.status >= 400) {
      if (res.status === 401 || res.status === 403) {
        window.location.href = `/${parseWorkspaceFromUrl(
          window.location.href,
        )}/auth/login`;
      }

      throw new ApiError(json.error, json);
    }

    return json;
  } else if (isBlob) {
    const blob = await res.blob();

    const contentType = res.headers.get('content-type') || '';

    const contentDisposition = res.headers.get('content-disposition') || '';

    const fileName = contentDisposition
      ? decodeURIComponent(
          (res.headers.get('content-disposition') ?? '')
            .split(/;(.+)/)[1]
            .split(/=(.+)/)[1]
            .replace(/['"]/g, ''),
        )
      : 'unknown';

    return {
      data: blob,
      fileName,
      contentType,
    } as Response;
  } else {
    const text = await res.text();
    return text as any;
  }
};

export const apiFetchWithAuth = async <Response = any, Data = any>({
  url,
  method,
  data,
  headers: providedHeaders,
  requestContentType,
}: {
  url: string;
  method: string;
  data?: Data;
  headers?: HeadersInit;
  requestContentType?: string;
}): Promise<Response> => {
  const headers = new Headers(providedHeaders);

  const token = sessionService.getToken(
    parseWorkspaceFromUrl(window.location.href) ?? '',
  );

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  return apiFetch<Response, Data>({
    url,
    method,
    data,
    headers,
    requestContentType,
  });
};
