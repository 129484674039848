import React from 'react';

import { Container } from './styled';
import { ActionStatus } from '../../../gql/graphql';

interface Props {
  status: ActionStatus;
  className?: string;
  isArchived?: boolean;
}

export const ACTION_STATUS_LABELS: Record<ActionStatus, string> = {
  [ActionStatus.Requested]: 'Requested',
  [ActionStatus.InReview]: 'In review',
  [ActionStatus.Completed]: 'Completed',
  [ActionStatus.Cancelled]: 'Cancelled',
};

export const ACTION_ITEM_STATUS_COLORS: {
  [Type in ActionStatus]: { color: string; background?: string };
} = {
  [ActionStatus.Requested]: { color: '#F1AA12', background: '#FCF2DB' },
  [ActionStatus.InReview]: { color: '#009A47' },
  [ActionStatus.Completed]: { color: '#009A47', background: '#CDF3DF' },
  [ActionStatus.Cancelled]: { color: '#D54855', background: '#F7DADD' },
};

export const Status = ({ status, className, isArchived = false }: Props) => {
  const statusColor = ACTION_ITEM_STATUS_COLORS[status];

  if (isArchived) {
    return (
      <Container className={className} color="#778CA2" background="#DFDFE8">
        Archived
      </Container>
    );
  }

  return (
    <Container
      className={className}
      color={statusColor.color}
      background={statusColor.background}
    >
      {ACTION_STATUS_LABELS[status]}
    </Container>
  );
};
