import styled from 'styled-components';
import { BadgeProps, BadgeSize } from './types';

const WHITES = ['white', '#fff', '#ffffff'];

const isWhite = (color: string) => WHITES.includes(color.toLowerCase());

export const Container = styled.div<BadgeProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  max-width: 100%;
  border-radius: 4px;
  color: ${(props) => props.color};
  background-color: ${({ bgColor }) => bgColor};
  border: ${({ bgColor }) => (isWhite(bgColor) ? '1px solid #DFDFE8' : 'none')};
  white-space: nowrap;

  svg {
    flex-shrink: 0;
  }

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  ${({ size = BadgeSize.Default }) => {
    switch (size) {
      case BadgeSize.Small:
        return `
            height: 20px;
            padding: 0 6px;
            font-size: 10px;
            font-weight: 500;
            `;
      default:
        return `
            height: 24px;
            padding: 0 6px;
            font-size: 12px;
            font-weight: 500;
            `;
    }
  }}
`;
