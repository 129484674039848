export enum ReactQueryKeys {
  Me = 'Me',
  ContractorContacts = 'ContractorContacts',
  Appointments = 'Appointments',
  Chat = 'Chat',
  Company = 'Company',
  Projects = 'Projects',
  Services = 'Services',
  Requests = 'Requests',
  Docs = 'Docs',
  RequestProperties = 'RequestProperties',
  WorkerLocation = 'WorkerLocation',
  MapboxDirections = 'MapboxDirections',
  ServiceVisits = 'ServiceVisits',
  SystemHealthChart = 'SystemHealthChart',
}
