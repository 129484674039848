import { useQuery } from '@tanstack/react-query';
import { graphql } from '../gql';
import { ReactQueryKeys } from './reactQueryKeys';
import { graphqlRequest } from '../api/graphqlRequest';
import { filterNotNull } from '../utils/types/filterNotNull';
import { ProjectColumnVisibility } from '../gql/graphql';
import { CompanyInfo } from '../api/company';

const query = graphql(/* GraphQL */ `
  query RequestProperties {
    columnToGroups(
      filter: { scope: { equalTo: "DEAL" }, groupExists: false }
      orderBy: POSITION_ASC
    ) {
      column {
        id
        name
        type
        multiple
        additional
        externalVisibility
      }
    }

    columnGroups(
      filter: { scope: { equalTo: "DEAL" } }
      orderBy: POSITION_ASC
    ) {
      id
      name
      columnToGroupsByGroupId(orderBy: POSITION_ASC) {
        column {
          id
          name
          type
          multiple
          additional
          externalVisibility
        }
      }
    }
  }
`);

export type RequestProperty = {
  id: number;
  name: string;
  type: string;
  multiple: boolean;
  externalVisibility: ProjectColumnVisibility;
  additional?: {
    defaultValue?: string | null;
    values?: string[] | null;
  };
};

export type RequestPropertyGroup = {
  id: number;
  name: string;
  properties: RequestProperty[];
};

export const useRequestProperties = (company?: CompanyInfo) => {
  return useQuery({
    queryKey: [ReactQueryKeys.RequestProperties],
    queryFn: async () => {
      const { columnToGroups, columnGroups } = await graphqlRequest(
        query,
        undefined,
        { 'pg-company-id': (company?.id ?? 0).toString() },
      );

      const groups: RequestPropertyGroup[] = (columnGroups ?? []).map(
        (group) => ({
          id: group.id,
          name: group.name ?? 'Unknown',
          properties: filterNotNull(
            group.columnToGroupsByGroupId.map(({ column }) => {
              if (!column) {
                return null;
              }

              return {
                ...column,
                name: column.name ?? 'Unknown',
              };
            }),
          ),
        }),
      );

      if (columnToGroups?.length) {
        groups.push({
          id: -1,
          name: 'Other',
          properties: filterNotNull(
            columnToGroups.map(({ column }) => {
              if (!column) {
                return null;
              }

              return {
                ...column,
                name: column.name ?? 'Unknown',
              };
            }),
          ),
        });
      }

      return groups;
    },
    enabled: Boolean(company),
  });
};
