import React from 'react';
import { ReferralSettings } from '../../api/company';
import {
  Body,
  Description,
  FieldLabel,
  FieldsRow,
  ImgContainer,
  ShareButton,
  ShareButtons,
} from './styled';
import defaultPromptImage from './defaultPromptImage.png';
import {
  Form,
  FormValidationRules,
  IUseFormProps,
  InputField,
  useForm,
} from '../../kit/components/Form';
import { Button, ButtonSize, ButtonVariant } from '../../kit/ui/Button';
import { SocialSmsIcon } from '../../kit/ui/icons/social/Sms';
import { SocialMailIcon } from '../../kit/ui/icons/social/Mail';
import { SocialGmailIcon } from '../../kit/ui/icons/social/Gmail';
import { SocialOutlookIcon } from '../../kit/ui/icons/social/Outlook';
import { SocialWhatsappIcon } from '../../kit/ui/icons/social/Whatsapp';
import { SocialFacebookIcon } from '../../kit/ui/icons/social/Facebook';
import { useSocialShareLinks } from './useSocialShareLinks';
import { useParams } from 'react-router-dom';
import { useMe } from '../../apiHooks/useMe';
import { useSubmitReferral } from '../../apiHooks/useSubmitReferral';
import { ReferralLink } from './ReferralLink';
import { useCompany } from '../../apiHooks/useCompany';
import { makeShareableMessage } from './helpers';

interface FormValues {
  contactName: string;
  primaryPhone: string;
  primaryEmail: string;
}

const RULES: FormValidationRules<FormValues> = {
  contactName: {
    isRequired: true,
  },
  primaryPhone: {
    isRequired: true,
  },
};

interface Props extends ReferralSettings {
  onClose: () => void;
}

export const Referral = ({
  promptDescription,
  promptImageUrl,
  shareableMessage,
  onClose,
}: Props) => {
  const { workspaceSlug } = useParams();

  const { data: company } = useCompany(workspaceSlug ?? '');

  const { data: currentUser } = useMe();

  const { mutateAsync: submitReferral } = useSubmitReferral();

  const portalNewRequestUrl = `${window.location.origin}/${workspaceSlug}/new?r=${currentUser?.me?.referralCode}`;

  const socialLinks = useSocialShareLinks({
    message: makeShareableMessage({
      message: shareableMessage,
      companyName: company?.name || '',
      referralLink: portalNewRequestUrl,
    }),
    url: portalNewRequestUrl,
  });

  const postForm: IUseFormProps<FormValues>['onSubmit'] = async (values) => {
    await submitReferral(values);

    onClose();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    defaultValues: {
      contactName: '',
      primaryPhone: '',
      primaryEmail: '',
    },
    onSubmit: postForm,
  });

  const {
    control,
    formState: { isSubmitting },
  } = form;

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <Body>
        <div>
          <Description>{promptDescription}</Description>

          <InputField
            name="contactName"
            label="Referral name"
            placeholder="Enter referral name here"
            control={control}
          />
          <FieldsRow>
            <InputField
              type="tel"
              name="primaryPhone"
              label="Referral phone"
              placeholder="+1 (___) ___ __ __"
              control={control}
            />
            <InputField
              type="email"
              name="primaryEmail"
              label="Referral email"
              placeholder="name@mail.com"
              control={control}
            />
          </FieldsRow>

          <Button
            type="submit"
            isBlock
            variant={ButtonVariant.Primary}
            size={ButtonSize.Large}
            isLoading={isSubmitting}
          >
            Submit referral
          </Button>
        </div>
        <div>
          <ImgContainer>
            <img src={promptImageUrl || defaultPromptImage} alt="prompt" />
          </ImgContainer>

          <div>
            <FieldLabel>Share your link:</FieldLabel>
            <ReferralLink value={portalNewRequestUrl} />
          </div>

          <ShareButtons>
            <ShareButton href={socialLinks.sms} target="_blank">
              <SocialSmsIcon />
            </ShareButton>
            <ShareButton href={socialLinks.mail} target="_blank">
              <SocialMailIcon />
            </ShareButton>
            <ShareButton href={socialLinks.gmail} target="_blank">
              <SocialGmailIcon />
            </ShareButton>
            <ShareButton href={socialLinks.outlook} target="_blank">
              <SocialOutlookIcon />
            </ShareButton>
            <ShareButton href={socialLinks.whatsapp} target="_blank">
              <SocialWhatsappIcon />
            </ShareButton>
            <ShareButton href={socialLinks.facebook} target="_blank">
              <SocialFacebookIcon />
            </ShareButton>
          </ShareButtons>
        </div>
      </Body>
    </Form>
  );
};
