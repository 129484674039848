import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #dfdfe8;
  overflow: hidden;

  > *:not(:last-child) {
    border-bottom: 1px solid #dfdfe8;
  }
`;

export const ListTitle = styled.div`
  color: #1d1d35;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;
