import React from 'react';

export const SocialWhatsappIcon = ({
  size = 24,
}: {
  size?: string | number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.75C17.799 23.75 22.5 19.049 22.5 13.25C22.5 7.45101 17.799 2.75 12 2.75C6.20101 2.75 1.5 7.45101 1.5 13.25C1.5 15.1332 1.99575 16.9005 2.86386 18.4287L1.5 23.75L6.98615 22.4778C8.47603 23.2891 10.1842 23.75 12 23.75ZM12 22.1346C16.9068 22.1346 20.8846 18.1568 20.8846 13.25C20.8846 8.34316 16.9068 4.36538 12 4.36538C7.09316 4.36538 3.11538 8.34316 3.11538 13.25C3.11538 15.1445 3.70838 16.9006 4.71888 18.3427L3.92308 21.3269L6.95995 20.5677C8.39202 21.5559 10.1284 22.1346 12 22.1346Z"
      fill="#BFC8D0"
    />
    <path
      d="M21 12.5C21 17.4706 16.9706 21.5 12 21.5C10.1041 21.5 8.34516 20.9138 6.89449 19.9127L3.81818 20.6818L4.62432 17.6588C3.60069 16.198 3 14.4192 3 12.5C3 7.52944 7.02944 3.5 12 3.5C16.9706 3.5 21 7.52944 21 12.5Z"
      fill="url(#paint0_linear_12006_299629)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23C17.799 23 22.5 18.299 22.5 12.5C22.5 6.70101 17.799 2 12 2C6.20101 2 1.5 6.70101 1.5 12.5C1.5 14.3832 1.99575 16.1505 2.86386 17.6787L1.5 23L6.98615 21.7278C8.47603 22.5391 10.1842 23 12 23ZM12 21.3846C16.9068 21.3846 20.8846 17.4068 20.8846 12.5C20.8846 7.59316 16.9068 3.61538 12 3.61538C7.09316 3.61538 3.11538 7.59316 3.11538 12.5C3.11538 14.3945 3.70838 16.1506 4.71888 17.5927L3.92308 20.5769L6.95995 19.8177C8.39202 20.8059 10.1284 21.3846 12 21.3846Z"
      fill="white"
    />
    <path
      d="M9.37502 7.62504C9.12537 7.1236 8.7424 7.168 8.35551 7.168C7.66407 7.168 6.58594 7.99621 6.58594 9.53759C6.58594 10.8008 7.14259 12.1836 9.01831 14.2522C10.8285 16.2485 13.207 17.2812 15.1817 17.2461C17.1563 17.2109 17.5625 15.5117 17.5625 14.9379C17.5625 14.6835 17.4047 14.5566 17.296 14.5221C16.6231 14.1992 15.382 13.5975 15.0996 13.4844C14.8172 13.3714 14.6698 13.5243 14.5781 13.6075C14.3221 13.8515 13.8144 14.5707 13.6406 14.7325C13.4668 14.8942 13.2077 14.8124 13.0999 14.7512C12.7031 14.592 11.6272 14.1134 10.7696 13.2821C9.70899 12.254 9.64675 11.9002 9.44693 11.5854C9.28707 11.3335 9.40437 11.1789 9.46291 11.1114C9.69142 10.8477 10.007 10.4406 10.1485 10.2383C10.29 10.036 10.1776 9.72889 10.1102 9.53759C9.82033 8.71488 9.57474 8.02618 9.37502 7.62504Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_12006_299629"
        x1="19.875"
        y1="5.75"
        x2="3"
        y2="21.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5BD066" />
        <stop offset="1" stopColor="#27B43E" />
      </linearGradient>
    </defs>
  </svg>
);
