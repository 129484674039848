import styled from 'styled-components';

export const About = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;

  p {
    margin: 0;
  }
`;

export const MetaData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f7f7fc;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    flex-direction: row;

    > *:last-child {
      flex: 1;
    }
  }
`;

export const ManagerAndUpload = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const ProjectImageContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;

  @media (min-width: 768px) {
    height: 226px;

    width: 250px;
  }

  @media (min-width: 1336px) {
    width: 320px;
  }
`;

export const Container = styled.div<{
  isArchived: boolean;
  isExpanded: boolean;
}>`
  border-radius: 16px;
  padding: 16px;
  border: 1px solid #dfdfe8;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  opacity: ${({ isArchived }) => (isArchived ? 0.7 : 1)};
  background-color: #f7f7fc;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 74, 0.04);

  @media (min-width: 768px) {
    border-radius: 24px;
    padding: 24px;
  }

  ${({ isExpanded }) =>
    !isExpanded &&
    `
    & {
        position: relative;
        padding-bottom: 48px;

        ${About} {
          position: absolute;
          z-index: -1;
        }

        ${ManagerAndUpload} {
          margin-bottom: 0;
        }

        @media (min-width: 768px) {
        ${About} {
          position: static;
          z-index: initial;
        }

        ${ManagerAndUpload} {
          margin-bottom: 16px;
}
        }
    }
    `}
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const AddressLink = styled.a`
  color: #235dff;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  text-decoration: none;
  display: flex;
  gap: 8px;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 16px;
  svg {
    flex-shrink: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    color: #235dff;
  }
`;

export const ProjectManager = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ContactPhoto = styled.img<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 8px;
`;

export const ContactPhotoPlaceholder = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 8px;
  background-color: #f7f7fc;
  color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContactName = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const ContactJob = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
`;

export const Heading = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const StageHeadingLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Chips = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  position: absolute;
  left: 12px;
  bottom: 12px;
`;

export const CollapseButton = styled.div<{ isCollapsed?: boolean }>`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #009688;

  ${({ isCollapsed }) =>
    isCollapsed &&
    `
    background-color: #E3F6F4;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  `};
`;

export const ArchivedMark = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  color: #fff;
  background-color: rgba(29, 29, 53);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  z-index: 1;
`;
