import React, { useMemo, useState } from 'react';
import { DateTime, Interval } from 'luxon';
import { Legend } from 'recharts';
import homeImg from './home.png';
import {
  AnnotationBorderRect,
  AnnotationRect,
  AnnotationRectInner,
  AnnotationSubtitle,
  AnnotationTitle,
  BatteryToRectLine,
  Chart,
  ChartContainer,
  ChartLegend,
  ChartLegendLabel,
  ChartLegendLabels,
  ChartTitle,
  ConsumedAnnotation,
  ConsumedLine,
  Container,
  Header,
  HomeImage,
  HomeImageAndStats,
  NextSync,
  PeriodAndArrows,
  PeriodControls,
  RectBottomArrow,
  RectLeftArrow,
  RectRightArrow,
  RectTopArrow,
  SolarAnnotation,
  SolarLine,
  SystemSyncDate,
  Title,
} from './styled';
import { HomeIcon } from '../../ui/icons/Home';
import { BatteryChargingIcon } from '../../ui/icons/BatteryCharging';
import { TransmissionTowerIcon } from '../../ui/icons/TransmissionTower';
import { SolarPowerIcon } from '../../ui/icons/SolarPower';
import { ChevronLeftIcon } from '../../ui/icons/ChevronLeft';
import { ChevronRightIcon } from '../../ui/icons/ChevronRight';
import { ChevronUpIcon } from '../../ui/icons/ChevronUp';
import { ChevronDownIcon } from '../../ui/icons/ChevronDown';
import { System as TSystem } from '../../../gql/graphql';
import { ButtonSize, ButtonVariant, IconButton } from '../../ui/Button';
import { Select } from '../../ui/Select';
import { formatEnergy, gapfillPoints, getObservedInterval } from './helpers';
import { Switch, SwitchVariant } from '../../ui/Switch';
import { SystemChart } from './SystemChart';
import { useSystemHealthChart } from '../../../apiHooks/useSystemHealthChart';
import { ChartLegendPointIcon } from '../../ui/icons/ChartLegendPoint';
import { ChartPeriod, chartPeriodConfig } from '../../../models/system';

const PERIOD_OPTIONS = Object.values(ChartPeriod).map((period) => ({
  value: period,
  label: chartPeriodConfig[period].title,
}));

type Props = {
  system: Pick<
    TSystem,
    | 'id'
    | 'uuid'
    | 'operationalAt'
    | 'createdAt'
    | 'lastReportAt'
    | 'operationStatus'
    | 'addressForced'
  > & {
    systemLogsBySystemIdConnection: {
      aggregates: {
        max: {
          createdAt: string;
        } | null;
      } | null;
    };
  };
};

export const System = ({ system }: Props) => {
  const earliestDate = DateTime.fromISO(
    system.operationalAt || system.createdAt,
  );

  const [period, setPeriod] = useState<ChartPeriod>(PERIOD_OPTIONS[0].value);

  const [anchorTime, setAnchorTime] = useState<DateTime>(
    DateTime.now().minus({ days: 1 }),
  );

  const observedInterval = useMemo(
    () => getObservedInterval(anchorTime, period),
    [anchorTime, period],
  );

  const hasPrevPeriod = useMemo(
    () => observedInterval && +observedInterval.start >= +earliestDate,
    [observedInterval, earliestDate],
  );

  const hasNextPeriod = useMemo(
    () => observedInterval && +observedInterval.end <= +DateTime.now(),
    [observedInterval],
  );

  const goPrevInterval = () => {
    if (chartPeriodConfig[period].duration === null) {
      return;
    }

    setAnchorTime(anchorTime.minus(chartPeriodConfig[period].duration));
  };

  const goNextInterval = () => {
    if (chartPeriodConfig[period].duration === null) {
      return;
    }

    setAnchorTime(anchorTime.plus(chartPeriodConfig[period].duration));
  };

  const [showPower, setShowPower] = useState(false);

  const {
    data: systemHealthChartData = {
      totalConsumption: null,
      totalProduction: null,
      totalExport: null,
      totalImport: null,
      totalCharge: null,
      totalDischarge: null,
      energy: [],
      power: [],
    },
  } = useSystemHealthChart(
    system.id,
    period,
    observedInterval?.start,
    observedInterval?.end,
  );

  const {
    totalProduction,
    totalConsumption,
    totalExport,
    totalImport,
    totalCharge,
    totalDischarge,
    energy,
    power,
  } = systemHealthChartData;

  const gapfilledEnergy = useMemo(
    () => period && gapfillPoints(energy, period, 'energy', observedInterval),
    [energy, period, observedInterval],
  );

  const gapfilledPower = useMemo(
    () => period && gapfillPoints(power, period, 'power', observedInterval),
    [power, period, observedInterval],
  );

  const actualInterval = useMemo(
    () =>
      Interval.fromDateTimes(
        observedInterval?.start || earliestDate,
        observedInterval?.end.minus(1) || DateTime.now(),
      ),
    [observedInterval, earliestDate],
  );

  if (!system) {
    return null;
  }

  const lastSyncDate =
    system.systemLogsBySystemIdConnection?.aggregates?.max?.createdAt;

  return (
    <Container>
      <Header>
        <Title>Your system health</Title>

        <PeriodControls>
          <PeriodAndArrows>
            {actualInterval.toLocaleString({
              day: 'numeric',
              month: 'short',
              year: '2-digit',
            })}
            <IconButton
              variant={ButtonVariant.Flat}
              size={ButtonSize.Small}
              onClick={goPrevInterval}
              disabled={!hasPrevPeriod}
            >
              <ChevronLeftIcon size="16px" />
            </IconButton>

            <IconButton
              variant={ButtonVariant.Flat}
              size={ButtonSize.Small}
              onClick={goNextInterval}
              disabled={!hasNextPeriod}
            >
              <ChevronRightIcon size="16px" />
            </IconButton>
          </PeriodAndArrows>
          <div>
            <Select
              value={period}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(newPeriod) => setPeriod(newPeriod ?? ChartPeriod.day)}
              options={PERIOD_OPTIONS}
              isClearable={false}
            />
          </div>
        </PeriodControls>
      </Header>

      <HomeImageAndStats>
        <HomeImage src={homeImg} alt="Solar System" />

        <SolarAnnotation>
          <AnnotationTitle>
            <SolarPowerIcon size="16px" color="#FFBD13" />
            {formatEnergy(totalProduction)}
          </AnnotationTitle>
          <AnnotationSubtitle>solar</AnnotationSubtitle>
        </SolarAnnotation>
        <SolarLine />

        <ConsumedAnnotation>
          <AnnotationTitle>
            <HomeIcon size="16px" color="#9C9CAA" />
            {formatEnergy(totalConsumption)}
          </AnnotationTitle>
          <AnnotationSubtitle>consumed</AnnotationSubtitle>
        </ConsumedAnnotation>
        <ConsumedLine />

        <BatteryToRectLine />

        <AnnotationBorderRect>
          <RectLeftArrow>
            <ChevronUpIcon size="12px" />
          </RectLeftArrow>
          <RectTopArrow>
            <ChevronRightIcon size="12px" />
          </RectTopArrow>
          <RectRightArrow>
            <ChevronDownIcon size="12px" />
          </RectRightArrow>
          <RectBottomArrow>
            <ChevronLeftIcon size="12px" />
          </RectBottomArrow>
        </AnnotationBorderRect>

        <AnnotationRect>
          <AnnotationRectInner>
            <div>
              <AnnotationTitle>
                <TransmissionTowerIcon size="16px" color="#9C9CAA" />
                {formatEnergy(totalExport)}
              </AnnotationTitle>
              <AnnotationSubtitle>exported</AnnotationSubtitle>
            </div>
            <div>
              <AnnotationTitle>
                <TransmissionTowerIcon size="16px" color="#9C9CAA" />
                {formatEnergy(totalImport)}
              </AnnotationTitle>
              <AnnotationSubtitle>imported</AnnotationSubtitle>
            </div>
            <div>
              <AnnotationTitle>
                <BatteryChargingIcon size="16px" color="#9C9CAA" />
                {formatEnergy(totalCharge)}
              </AnnotationTitle>
              <AnnotationSubtitle>charged</AnnotationSubtitle>
            </div>
            <div>
              <AnnotationTitle>
                <BatteryChargingIcon size="16px" color="#9C9CAA" />
                {formatEnergy(totalDischarge)}
              </AnnotationTitle>
              <AnnotationSubtitle>discharged</AnnotationSubtitle>
            </div>
          </AnnotationRectInner>
        </AnnotationRect>
      </HomeImageAndStats>

      <SystemSyncDate>
        <div>
          Last sync:{' '}
          {lastSyncDate
            ? DateTime.fromISO(lastSyncDate).toLocaleString(
                DateTime.DATETIME_SHORT,
              )
            : 'never'}
        </div>
        <NextSync>(sync once a day)</NextSync>
      </SystemSyncDate>

      <ChartContainer>
        <ChartTitle>
          <div>System production & consumption, {showPower ? 'W' : 'Wh'}</div>
          <Switch
            variant={SwitchVariant.TwoWay}
            label="Power"
            secondLabel="Energy"
            isActive={showPower}
            onChange={(showPower) => setShowPower(showPower)}
          />
        </ChartTitle>

        <Chart>
          <SystemChart
            points={showPower ? gapfilledPower : gapfilledEnergy}
            period={period}
            kind={showPower ? 'power' : 'energy'}
            meters={{ production: true, consumption: true }}
          >
            <Legend
              verticalAlign="bottom"
              align="center"
              // eslint-disable-next-line
              content={({ payload = [] }) => (
                <ChartLegend>
                  <ChartLegendLabels>
                    {payload.map(({ value, color }) => (
                      <ChartLegendLabel key={value}>
                        <ChartLegendPointIcon size="16px" color={color} />
                        {value}
                      </ChartLegendLabel>
                    ))}
                  </ChartLegendLabels>
                </ChartLegend>
              )}
            />
          </SystemChart>
        </Chart>
      </ChartContainer>
    </Container>
  );
};
