import React, { useCallback, useState } from 'react';

import {
  Container,
  Content,
  FormContainer,
  FormTitle,
  LogoImage,
  LogoName,
  Nav,
  SuccessCircle,
  SuccessContainer,
  SuccessDescription,
  SuccessFooter,
  SuccessTitle,
} from './styled';
import { Link, useParams } from 'react-router-dom';
import { useCompany } from '../../apiHooks/useCompany';
import { RequestForm } from '../../features/RequestForm';
import { CheckIcon } from '../../kit/ui/icons/Check';
import { Button, ButtonVariant } from '../../kit/ui/Button';
import { ArrowRightIcon } from '../../kit/ui/icons/ArrowRight';

export const RequestAndSignUp = () => {
  const { workspaceSlug } = useParams();
  const { data: company } = useCompany(workspaceSlug ?? '');

  const [isSuccessShown, setIsSuccessShown] = useState(false);

  const handleRequestCreated = useCallback(() => {
    setIsSuccessShown(true);
  }, []);

  return (
    <Container>
      <Nav>
        {company?.logoUrl && <LogoImage src={company.logoUrl} />}

        {!company?.logoUrl && <LogoName>{company?.name}</LogoName>}
      </Nav>
      <Content>
        {isSuccessShown && (
          <>
            <SuccessContainer>
              <SuccessCircle>
                <CheckIcon size="48px" color="#009688" />
              </SuccessCircle>
              <SuccessTitle>Success!</SuccessTitle>
              <SuccessDescription>
                Your request was sent successfully. We'll contact you as soon as
                possible. Looking forward to working with you!
              </SuccessDescription>
            </SuccessContainer>
            <SuccessFooter>
              <div>
                If you want to track your request status, please check your
                Client portal.
              </div>
              <Link to="../auth/login">
                <Button variant={ButtonVariant.Flat}>
                  Go to Portal
                  <ArrowRightIcon size="16px" color="#009688" />
                </Button>
              </Link>
            </SuccessFooter>
          </>
        )}
        {!isSuccessShown && (
          <FormContainer>
            <FormTitle>Submit Request</FormTitle>
            <RequestForm isInModal={false} onClose={handleRequestCreated} />
          </FormContainer>
        )}
      </Content>
    </Container>
  );
};
