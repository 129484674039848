import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { sessionService } from '../../services/SessionService';

export const LoginAsCompany = () => {
  const { workspaceSlug } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      sessionService.setToken(workspaceSlug!, token, 3600);
      navigate(`/${workspaceSlug}`, { replace: true });

      return;
    }

    navigate(`/${workspaceSlug}/auth/login`, { replace: true });
  }, [navigate, searchParams, workspaceSlug]);

  return null;
};
