import React from 'react';

export const TruckIcon = ({
  size = '16px',
  color = 'currentColor',
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3C0 2.44772 0.447715 2 1 2H16C16.5523 2 17 2.44772 17 3V7H20C20.2652 7 20.5196 7.10536 20.7071 7.29289L23.7071 10.2929C23.8946 10.4804 24 10.7348 24 11V16C24 16.5523 23.5523 17 23 17H21.6632C21.8792 17.4546 22 17.9632 22 18.5C22 20.433 20.433 22 18.5 22C16.567 22 15 20.433 15 18.5C15 17.9632 15.1208 17.4546 15.3368 17H8.66319C8.87916 17.4546 9 17.9632 9 18.5C9 20.433 7.433 22 5.5 22C3.567 22 2 20.433 2 18.5C2 17.9632 2.12085 17.4546 2.33682 17H1C0.447715 17 0 16.5523 0 16V3ZM15 15V4H2V15H15ZM17 15V9H19.5858L22 11.4142V15H17ZM5.5 17C4.67157 17 4 17.6716 4 18.5C4 19.3284 4.67157 20 5.5 20C6.32843 20 7 19.3284 7 18.5C7 17.6716 6.32843 17 5.5 17ZM18.5 17C17.6716 17 17 17.6716 17 18.5C17 19.3284 17.6716 20 18.5 20C19.3284 20 20 19.3284 20 18.5C20 17.6716 19.3284 17 18.5 17Z"
      fill={color}
    />
  </svg>
);
