import React from 'react';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Flat = 'flat',
}

export enum ButtonSize {
  Default = 'default',
  Small = 'small',
  Large = 'large',
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant;
  isBlock?: boolean;
  size?: ButtonSize;
  isLoading?: boolean;
}
