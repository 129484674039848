import { useMutation } from '@tanstack/react-query';
import { SubmitRequestAndSignUpPayload, requestsApi } from '../api/requests';
import { showToast } from '../kit/components/Toast';

export const useSubmitRequestAndSignUp = () => {
  return useMutation({
    mutationFn: async (payload: SubmitRequestAndSignUpPayload) => {
      await requestsApi.submitRequestAndSignUp(payload);
    },
    onSuccess: () => {
      showToast('Request submitted!', 'success');
    },
  });
};
