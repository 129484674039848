import { isPlainObject } from './isPlainObject';

type TInput = { [key: string]: any };

const omitDeep = (input: TInput, excludes: Array<string>): TInput =>
  Object.entries(input).reduce<TInput>((nextInput, [key, value]) => {
    const shouldExclude = excludes.includes(key);

    if (shouldExclude) {
      return nextInput;
    }

    if (Array.isArray(value)) {
      const nextValue = value.map((arrItem) => {
        if (isPlainObject(arrItem)) {
          return omitDeep(arrItem, excludes);
        }

        return arrItem;
      });

      nextInput[key] = nextValue;
    } else if (isPlainObject(value)) {
      nextInput[key] = omitDeep(value, excludes);
    } else {
      nextInput[key] = value;
    }

    return nextInput;
  }, {});

export { omitDeep };
