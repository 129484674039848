import { DateTime, Interval } from 'luxon';
import { ChartPeriod, chartPeriodConfig } from '../../../models/system';

const powerToLocaleString = (watts: number) =>
  watts.toLocaleString('en-US', { maximumFractionDigits: 2 });

export const formatPower = (watts?: number | null) => {
  if (watts === null || watts === undefined) {
    return '';
  }

  const abs = Math.abs(watts);

  if (abs < 1000) {
    return powerToLocaleString(watts) + ' W';
  } else if (abs < 1_000_000) {
    return powerToLocaleString(watts / 1000.0) + ' kW';
  } else if (abs < 1_000_000_000) {
    return powerToLocaleString(watts / 1_000_000) + ' MW';
  } else {
    return powerToLocaleString(watts / 1_000_000_000) + ' GW';
  }
};

const energyToLocaleString = (watthours: number) =>
  watthours.toLocaleString('en-US', { maximumFractionDigits: 2 });

export const formatEnergy = (watthours?: number | null) => {
  if (watthours === null || watthours === undefined) {
    return '';
  }

  const abs = Math.abs(watthours);

  if (abs < 1000) {
    return energyToLocaleString(watthours) + ' Wh';
  } else if (abs < 1_000_000) {
    return energyToLocaleString(watthours / 1000.0) + ' kWh';
  } else if (abs < 1_000_000_000) {
    return energyToLocaleString(watthours / 1_000_000) + ' MWh';
  } else {
    return energyToLocaleString(watthours / 1_000_000_000) + ' GWh';
  }
};

/**
 * Returns aligned time interval which includes given base time: `[aligned base - period, aligned base)`.
 * E.g. if base is now and period is day, then observed interval is `[start of today, start of tomorrow)`.
 */
export const getObservedInterval = (
  base: DateTime<true>,
  period: ChartPeriod,
) => {
  const duration = chartPeriodConfig[period].duration;
  if (!duration) {
    return null;
  }

  const end = base.endOf(chartPeriodConfig[period].alignment).plus(1);
  return end.minus(duration).until(end);
};

export const gapfillPoints = <T extends { time: DateTime }>(
  points: T[],
  period: ChartPeriod,
  kind: 'power' | 'energy',
  observedInterval: Interval<true> | null,
): T[] => {
  const periodConfig = chartPeriodConfig[period];
  const periodAlign = periodConfig.pointAlignment;

  const gapfillStart =
    observedInterval?.start ||
    (points.length && points[0].time.startOf(periodAlign)) ||
    null;

  const gapfillEnd =
    observedInterval?.end ||
    (points.length && points[points.length - 1].time.startOf(periodAlign)) ||
    null;

  if (!gapfillStart || !gapfillEnd) {
    return points;
  }

  const fillInterval = Interval.fromDateTimes(gapfillStart, gapfillEnd);
  if (!fillInterval.isValid || fillInterval.start.equals(fillInterval.end)) {
    return points;
  }

  const subintervals = fillInterval.splitBy(
    periodConfig.pointGranularity,
  ) as Interval<true>[];

  let pointsIndex = 0;
  return subintervals.map((interval) => {
    const point = points[pointsIndex];

    switch (kind) {
      case 'power': {
        if (
          point &&
          +point.time.startOf(periodAlign) ===
            +interval.start.startOf(periodAlign)
        ) {
          pointsIndex += 1;

          return point;
        }

        return { time: interval.start } as T;
      }

      case 'energy': {
        if (
          point &&
          +point.time.startOf(periodAlign) ===
            +interval.end.startOf(periodAlign)
        ) {
          pointsIndex += 1;

          return point;
        }

        return { time: interval.end } as T;
      }

      default:
        throw new Error(`Unrecognized points kind ${kind satisfies never}`);
    }
  });
};
