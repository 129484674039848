import styled from 'styled-components';

export const Container = styled.div`
  margin: 70px 16px 0 16px;
  border-radius: 16px;
  background-color: #fff;
  padding: 16px;
  max-width: 752px;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 24px;

  img {
    width: 100%;
    max-width: 340px;
  }

  @media (min-width: 768px) {
    padding: 56px 76px;
    margin: 135px auto 0 auto;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
`;

export const Description = styled.div`
  color: #828d9a;
  font-size: 20px;
  text-align: center;
  max-width: 600px;
`;
