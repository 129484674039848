import React from 'react';

export const SocialOutlookIcon = ({
  size = 24,
}: {
  size?: string | number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9634_97052)">
      <rect
        x="8.16797"
        y="1.5"
        width="15"
        height="21"
        rx="1.5"
        fill="#1066B5"
      />
      <rect
        x="8.16797"
        y="1.5"
        width="15"
        height="21"
        rx="1.5"
        fill="url(#paint0_linear_9634_97052)"
      />
      <rect x="8.16797" y="3.75" width="7.5" height="7.5" fill="#32A9E7" />
      <rect x="8.16797" y="11.25" width="7.5" height="7.5" fill="#167EB4" />
      <rect x="15.668" y="11.25" width="7.5" height="7.5" fill="#32A9E7" />
      <rect x="15.668" y="3.75" width="7.5" height="7.5" fill="#58D9FD" />
      <mask
        id="mask0_9634_97052"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="10"
        width="19"
        height="13"
      >
        <path
          d="M6.66797 10.5H23.168C23.9964 10.5 24.668 11.1716 24.668 12V21C24.668 21.8284 23.9964 22.5 23.168 22.5H8.16797C7.33954 22.5 6.66797 21.8284 6.66797 21V10.5Z"
          fill="url(#paint1_linear_9634_97052)"
        />
      </mask>
      <g mask="url(#mask0_9634_97052)">
        <path d="M24.668 10.5V13.5H23.168V10.5H24.668Z" fill="#135298" />
        <path
          d="M24.668 22.5V12L5.91797 22.5H24.668Z"
          fill="url(#paint2_linear_9634_97052)"
        />
        <path
          d="M6.66797 22.5V12L25.418 22.5H6.66797Z"
          fill="url(#paint3_linear_9634_97052)"
        />
      </g>
      <path
        d="M6.66797 9C6.66797 7.75736 7.67533 6.75 8.91797 6.75H13.418C14.6606 6.75 15.668 7.75736 15.668 9V18C15.668 19.2426 14.6606 20.25 13.418 20.25H6.66797V9Z"
        fill="black"
        fillOpacity="0.3"
      />
      <rect
        x="0.667969"
        y="5.25"
        width="13.5"
        height="13.5"
        rx="1.5"
        fill="url(#paint4_linear_9634_97052)"
      />
      <path
        d="M11.168 12.0519V11.9273C11.168 9.76662 9.61338 8.25 7.42984 8.25C5.23442 8.25 3.66797 9.77701 3.66797 11.9481V12.0727C3.66797 14.2334 5.22256 15.75 7.41797 15.75C9.60151 15.75 11.168 14.223 11.168 12.0519ZM9.39977 12.0727C9.39977 13.5062 8.59281 14.3684 7.42984 14.3684C6.26686 14.3684 5.44803 13.4855 5.44803 12.0519V11.9273C5.44803 10.4938 6.25499 9.63158 7.41797 9.63158C8.56908 9.63158 9.39977 10.5145 9.39977 11.9481V12.0727Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_9634_97052"
        x1="8.16797"
        y1="12"
        x2="23.168"
        y2="12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#064484" />
        <stop offset="1" stopColor="#0F65B5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9634_97052"
        x1="6.66797"
        y1="20.0769"
        x2="24.668"
        y2="20.0769"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1B366F" />
        <stop offset="1" stopColor="#2657B0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_9634_97052"
        x1="24.668"
        y1="17.25"
        x2="6.66797"
        y2="17.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#44DCFD" />
        <stop offset="0.453125" stopColor="#259ED0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_9634_97052"
        x1="6.66797"
        y1="17.25"
        x2="24.668"
        y2="17.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#259ED0" />
        <stop offset="1" stopColor="#44DCFD" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_9634_97052"
        x1="0.667969"
        y1="12"
        x2="14.168"
        y2="12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#064484" />
        <stop offset="1" stopColor="#0F65B5" />
      </linearGradient>
      <clipPath id="clip0_9634_97052">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.667969)"
        />
      </clipPath>
    </defs>
  </svg>
);
