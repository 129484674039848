import React, { useCallback } from 'react';
import { DisabledInputWithCopy } from './styled';
import { CopyIcon } from '../../kit/ui/icons/Copy';
import { showToast } from '../../kit/components/Toast';

interface Props {
  value: string;
}

export const ReferralLink = ({ value }: Props) => {
  const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation();
      navigator.clipboard.writeText(value);
      showToast('Link copied to clipboard', 'info');
    },
    [value],
  );

  return (
    <DisabledInputWithCopy onClick={handleClick}>
      <div>{value}</div>

      <CopyIcon size="16px" />
    </DisabledInputWithCopy>
  );
};
