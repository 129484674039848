import React from 'react';
import { Container, Right, Title, When } from './styled';
import { CalendarIcon } from '../../ui/icons/Calendar';
import { UserCard } from './UserCard';
import { Badge } from '../../ui/Badge';
import { BadgeSize } from '../../ui/Badge/types';
import { CheckCircleIcon } from '../../ui/icons/CheckCircle';
import { RequestAppointemnt } from '../../../apiHooks/useRequests';

const formatDate = (startDate: Date) => {
  return new Date(startDate).toLocaleString('en-US', {
    dateStyle: 'medium',
    timeStyle: 'short',
  });
};

const getStatus = (appt: RequestAppointemnt) => {
  if (appt.isCompleted) {
    return {
      color: '#009A47',
      bgColor: '#CDF3DF',
      text: 'Completed',
    };
  }

  return {
    color: '#F1AA12',
    bgColor: '#FCF2DB',
    text: 'Confirmed',
  };
};

interface Props {
  appointment: RequestAppointemnt;
}

export const Appointment = ({ appointment }: Props) => {
  const status = getStatus(appointment);

  const isToday =
    new Date(appointment.dueDate).toDateString() === new Date().toDateString();

  const isDimmed =
    appointment.isCompleted ||
    (!isToday && new Date(appointment.dueDate) < new Date());

  return (
    <Container isHighlighted={isToday} isDimmed={isDimmed}>
      <div>
        <Title>
          {appointment.isCompleted && (
            <CheckCircleIcon size="16px" color="#71E7A9" />
          )}

          {appointment.title ?? 'No title'}
        </Title>
        <When>
          <CalendarIcon size="16px" color={isToday ? '#FFBD13' : '#9C9CAA'} />
          {formatDate(new Date(appointment.dueDate))}
        </When>
      </div>

      <Right>
        <Badge
          size={BadgeSize.Small}
          color={status.color}
          bgColor={status.bgColor}
        >
          {status.text.toUpperCase()}
        </Badge>
        {appointment.assignee && (
          <UserCard
            isDimmed={isDimmed}
            avatarSize={20}
            user={appointment.assignee}
          />
        )}
      </Right>
    </Container>
  );
};
