import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SimpleText, ToogleMore } from './styled';
import { ButtonSize, ButtonVariant } from '../Button';
import { ChevronDownIcon } from '../icons/ChevronDown';
import { ChevronUpIcon } from '../icons/ChevronUp';

export function cleanHtml(emailContent: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(emailContent, 'text/html');
  const { body } = doc;

  if (!body) {
    return '';
  }

  const extractedText: string[] = [];

  const traverseNodes = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      extractedText.push((node.textContent || '').trim());
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      Array.from(node.childNodes).forEach((childNode) => {
        traverseNodes(childNode);
      });
    }
  };

  traverseNodes(body);

  return extractedText.join('\n').trim();
}

interface Props {
  text: string;
  maxChars: number;
  isHtml?: boolean;
}

export const TruncatedText = ({ text, maxChars, isHtml = false }: Props) => {
  const [isMoreShown, setIsMoreShown] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const rawText = useMemo(
    () => (isHtml ? cleanHtml(text) : text),
    [text, isHtml],
  );

  const toggleExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (rawText.length < maxChars) {
      setIsMoreShown(false);
    }
  }, [rawText, maxChars]);

  if (!isMoreShown || isExpanded) {
    return (
      <SimpleText noNewLines={false}>
        {isHtml ? (
          <div
            dangerouslySetInnerHTML={{ __html: text.replace(/<br \/>/g, '') }}
          />
        ) : (
          text
        )}

        {isMoreShown && (
          <ToogleMore
            size={ButtonSize.Small}
            variant={ButtonVariant.Flat}
            onClick={toggleExpand}
          >
            Collapse
            <ChevronUpIcon size="16px" />
          </ToogleMore>
        )}
      </SimpleText>
    );
  }

  return (
    <SimpleText noNewLines={isHtml}>
      {rawText.slice(0, maxChars)}...
      <ToogleMore
        size={ButtonSize.Small}
        className="expand"
        variant={ButtonVariant.Flat}
        onClick={toggleExpand}
      >
        More
        <ChevronDownIcon size="16px" />
      </ToogleMore>
    </SimpleText>
  );
};
