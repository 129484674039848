import { INVALID_INPUT_SELECTOR } from '../UserInputError';

const scrollToFirstUserInputError = (arg?: boolean | ScrollIntoViewOptions) => {
  const target = window.document.querySelectorAll(
    INVALID_INPUT_SELECTOR,
  )?.[0] as HTMLSpanElement;

  if (!target) {
    return;
  }

  target.scrollIntoView(arg);
};

const scrollToFirstError = () =>
  setTimeout(() => {
    scrollToFirstUserInputError({ block: 'center', behavior: 'smooth' });
  });

export { scrollToFirstError };
