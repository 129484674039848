import { DateTimeUnit, Duration } from 'luxon';

export enum ChartPeriod {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
  lifetime = 'lifetime',
}

export const chartPeriodConfig: {
  [period in ChartPeriod]: {
    /**
     * Human-readable name
     */
    title: string;
    /**
     * Total period duration
     */
    duration: Duration<true> | null;
    /**
     * How to align periods to compare them
     */
    alignment: DateTimeUnit;
    /**
     * Duration on a point in the period
     */
    pointGranularity: Duration<true>;
    /**
     * How to align points in the period to compare them. The goal is to have data for days/months/years
     * to match accross different timzones. So e.g. production for Jan 10 is the same for users in UTC-7 and UTC+4 despite
     * starts/ends of Jan 10 in different timezones are different points in time.
     */
    pointAlignment: DateTimeUnit;
    /**
     * Duration of a 'point' when aggregating totals over the period
     */
    totalGranularity: Duration<true>;
  };
} = {
  [ChartPeriod.day]: {
    title: 'Day',
    duration: Duration.fromObject({ day: 1 }),
    alignment: 'day',
    pointGranularity: Duration.fromObject({ minutes: 15 }),
    pointAlignment: 'minute',
    totalGranularity: Duration.fromObject({ day: 1 }),
  },
  [ChartPeriod.week]: {
    title: 'Week',
    duration: Duration.fromObject({ week: 1 }),
    alignment: 'week',
    pointGranularity: Duration.fromObject({ day: 1 }),
    pointAlignment: 'day',
    totalGranularity: Duration.fromObject({ day: 1 }),
  },
  [ChartPeriod.month]: {
    title: 'Month',
    duration: Duration.fromObject({ month: 1 }),
    alignment: 'month',
    pointGranularity: Duration.fromObject({ day: 1 }),
    pointAlignment: 'day',
    totalGranularity: Duration.fromObject({ day: 1 }),
  },
  [ChartPeriod.year]: {
    title: 'Year',
    duration: Duration.fromObject({ year: 1 }),
    alignment: 'year',
    pointGranularity: Duration.fromObject({ month: 1 }),
    pointAlignment: 'month',
    totalGranularity: Duration.fromObject({ month: 1 }),
  },
  [ChartPeriod.lifetime]: {
    title: 'Lifetime',
    duration: null,
    alignment: 'year',
    pointGranularity: Duration.fromObject({ year: 1 }),
    pointAlignment: 'year',
    totalGranularity: Duration.fromObject({ year: 1 }),
  },
};
