export const formatFileSize = (sizeInBytes: number): string => {
  if (sizeInBytes > 1024 * 1024) {
    return `${Math.round(sizeInBytes / (1024 * 1024))} MB`;
  }

  return `${Math.round(sizeInBytes / 1024)} KB`;
};

export const cutFileName = (fileName: string, maxLength = 32) => {
  if (fileName.length > maxLength) {
    const partMaxLength = Math.floor(maxLength / 2);

    return (
      fileName.substr(0, partMaxLength) +
      '...' +
      fileName.substr(-partMaxLength)
    );
  }

  return fileName;
};
