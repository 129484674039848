import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const showToast = (
  message: string,
  type: 'success' | 'error' | 'info' | 'warning' = 'success',
) =>
  toast(message, {
    type,
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
