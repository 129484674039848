import styled from 'styled-components';
import { Button } from '../Button';

export const SimpleText = styled.div<{ noNewLines?: boolean }>`
  white-space: ${({ noNewLines }) => (noNewLines ? 'initial' : 'pre-wrap')};
`;

export const ToogleMore = styled(Button)`
  padding: 0 2px;
  height: 16px;
  font-size: 12px;
  margin-left: 4px;

  &:focus {
    border-color: transparent;
  }
`;
