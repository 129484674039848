import styled from 'styled-components';

export const Container = styled.div<{
  isHighlighted?: boolean;
  isDimmed?: boolean;
}>`
  padding: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
  }

  ${({ isDimmed }) =>
    isDimmed &&
    `
    color: #828D9A;
  `}
`;

export const When = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 16px;

  @media (min-width: 768px) {
    gap: 4px;
    flex-direction: column;
    align-items: flex-end;

    > *:first-child {
      width: max-content;
    }
  }
`;
