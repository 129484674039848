import styled from 'styled-components';

export const PlaceholderPrimary = styled.div<{
  fontSize?: 'default' | 'small';
}>`
  font-size: ${({ fontSize }) => (fontSize === 'small' ? '20px' : '24px')};
  font-weight: 500;
  margin-bottom: ${({ fontSize }) => (fontSize === 'small' ? '8px' : '16px')};
  text-align: center;
`;

export const PlaceholderSecondary = styled.div<{
  fontSize?: 'default' | 'small';
}>`
  color: #828d9a;
  font-size: ${({ fontSize }) => (fontSize === 'small' ? '14px' : '20px')};
  font-weight: 400;
  text-align: center;
`;

export const Placeholder = styled.div<{
  imgMaxWidth?: string;
  layout: 'vertical' | 'horizontal';
}>`
  margin: 0 auto;
  text-align: center;

  img {
    max-width: ${({ imgMaxWidth }) => imgMaxWidth || '200px'};
    width: 100%;
    margin-bottom: 40px;
  }

  @media (min-width: 768px) {
    ${({ layout, imgMaxWidth }) =>
      layout === 'horizontal' &&
      `
      display: flex;
      align-items: center; 
      column-gap: 16px;

      img {
        margin-bottom: 0;
        flex: 0 0 ${imgMaxWidth || '200px'};
        order: 1;
      }

      > *:not(img) {
        flex: 1;
      }

      ${PlaceholderPrimary}, ${PlaceholderSecondary} {
        text-align: left;
      }

  `};
  }
`;
