import React, { useCallback } from 'react';
import {
  useForm as useFormOriginal,
  FieldValues,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';

import { applyServerValidation, scrollToFirstError } from './utils';
import { showToast } from '../Toast';

export interface IUseFormProps<TFieldValues extends FieldValues>
  extends Omit<UseFormProps<TFieldValues>, 'shouldUnregister'> {
  onSubmit: (
    formValues: TFieldValues,
    form: UseFormReturn<TFieldValues>,
  ) => Promise<void>;
}

interface IUseFormReturn<TFieldValues extends FieldValues> {
  form: UseFormReturn<TFieldValues>;
  hasErrors: boolean;
  handleSubmit: (e?: React.BaseSyntheticEvent) => void;
}

const useForm = <TFieldValues extends FieldValues = FieldValues>(
  props: IUseFormProps<TFieldValues>,
): IUseFormReturn<TFieldValues> => {
  const { onSubmit, ...useFormProps } = props;

  const form = useFormOriginal({ mode: 'onChange', ...useFormProps });

  const hasErrors = Object.keys(form.formState.errors).length > 0;

  const handleSubmitWrapped = useCallback(
    async (formValues: TFieldValues) => {
      try {
        await onSubmit(formValues, form);
      } catch (error: any) {
        if (error.payload) {
          applyServerValidation(form.setError, error.payload);
        }

        if (typeof error.payload === 'string') {
          showToast(error.payload, 'error');
        } else if (error.message) {
          showToast(
            error.message === 'Bad Request'
              ? 'Please correct marked fields'
              : error.message,
            'error',
          );
        }

        // TODO log other exceptions somewhere, sentry for example
      }
    },
    [onSubmit, form],
  );

  return {
    form,
    hasErrors,
    handleSubmit: form.handleSubmit(handleSubmitWrapped, scrollToFirstError),
  };
};

export { useForm };
