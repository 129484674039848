import React, { useMemo } from 'react';

import {
  AddressLink,
  Description,
  Title,
  Meta,
  Chips,
  Person,
  ContactPhoto,
  ContactPhotoPlaceholder,
  ContactName,
  ContactJob,
  ListTitle,
  AppointemntsContainer,
  List,
  Container,
  Header,
  HeaderStatus,
  Content,
} from './styled';
import { getGeoLink } from '../../../utils/getGeoLink';
import { MapPinIcon } from '../../ui/icons/MapPin';
import { Badge } from '../../ui/Badge';
import { formatMoney } from '../../../utils/formatMoney';
import { Request as TRequest } from '../../../apiHooks/useRequests';
import { UserIcon } from '../../ui/icons/User';
import { Appointment } from '../Appointment';
import { CheckCircleIcon } from '../../ui/icons/CheckCircle';
import { ArrowRightIcon } from '../../ui/icons/ArrowRight';
import { ProjectRequestStatus } from '../../../gql/graphql';
import { CircleIcon } from '../../ui/icons/Circle';
import { TruncatedText } from '../../ui/TruncatedText';

interface Props {
  request: TRequest;
}

const Status = ({
  title,
  isCompleted,
}: {
  title: string;
  isCompleted: boolean;
}) => (
  <HeaderStatus isCompleted={isCompleted}>
    {isCompleted && <CheckCircleIcon size="16px" />}
    {!isCompleted && <CircleIcon size="16px" />}
    {title}
  </HeaderStatus>
);

const STATUS_ORDER = {
  [ProjectRequestStatus.New]: 0,
  [ProjectRequestStatus.AppointmentScheduled]: 1,
  [ProjectRequestStatus.Overdue]: 2,
  [ProjectRequestStatus.AppointmentCompleted]: 3,
};

export const Request = ({ request }: Props) => {
  const sortedAppointments = useMemo(() => {
    const result = [...request.remindersByProjectId];

    result.sort((a, b) => {
      const aDate = a.dueDate;
      const bDate = b.dueDate;

      const aCompleted = a.isCompleted;
      const bCompleted = b.isCompleted;

      if (aCompleted && bCompleted) {
        return new Date(bDate).getTime() - new Date(aDate).getTime();
      }

      if (aCompleted) {
        return 1;
      }

      if (bCompleted) {
        return -1;
      }

      return new Date(bDate).getTime() - new Date(aDate).getTime();
    });

    return result;
  }, [request.remindersByProjectId]);

  const status = request.requestStatus ?? ProjectRequestStatus.New;

  const isRequestCompleted =
    request.stage?.type === 'WON' || request.stage?.type === 'LOST';

  return (
    <Container>
      <Header>
        <Status title="Request sent" isCompleted />

        <ArrowRightIcon size="16px" color="#C8C8D3" />

        <Status
          title="Appointment scheduled"
          isCompleted={
            isRequestCompleted ||
            STATUS_ORDER[status] >=
              STATUS_ORDER[ProjectRequestStatus.AppointmentScheduled]
          }
        />

        <ArrowRightIcon size="16px" color="#C8C8D3" />

        <Status
          title="Appointment completed"
          isCompleted={
            isRequestCompleted ||
            STATUS_ORDER[status] >=
              STATUS_ORDER[ProjectRequestStatus.AppointmentCompleted]
          }
        />

        <ArrowRightIcon size="16px" color="#C8C8D3" />

        <Status title="Request closed" isCompleted={isRequestCompleted} />
      </Header>
      <Content>
        <Title>{request.title}</Title>
        <Meta>
          <div>
            {Boolean(request.address?.[0]) && (
              <AddressLink href={getGeoLink(request.address?.[0] ?? '')}>
                <MapPinIcon size="16px" />
                {request.address?.[0]}
              </AddressLink>
            )}

            <Chips>
              {request.trades?.map((trade) => (
                <Badge key={trade} color="#1D1D35" bgColor="#FFF">
                  {trade}
                </Badge>
              ))}

              {Boolean(request.dealSize) && (
                <Badge color="#1D1D35" bgColor="#FFF">
                  {request.dealSize} kW
                </Badge>
              )}

              {Boolean(request.dealValue) && request.dealValue !== null && (
                <Badge color="#1D1D35" bgColor="#FFF">
                  {formatMoney(request.dealValue)}
                </Badge>
              )}
            </Chips>
          </div>

          {request.salesRep && (
            <Person>
              {request.salesRep.avatarUrl && (
                <ContactPhoto
                  size={32}
                  src={request.salesRep.avatarUrl}
                  alt={`${request.salesRep.firstName} ${request.salesRep.lastName} `}
                />
              )}
              {!request.salesRep.avatarUrl && (
                <ContactPhotoPlaceholder size={32}>
                  <UserIcon size="24px" />
                </ContactPhotoPlaceholder>
              )}
              <div>
                <ContactName>
                  {request.salesRep.firstName} {request.salesRep.lastName}
                </ContactName>
                <ContactJob>Your Sales Rep</ContactJob>
              </div>
            </Person>
          )}
        </Meta>

        <Description>
          <TruncatedText
            isHtml
            text={request.description ?? ''}
            maxChars={300}
          />
        </Description>

        {sortedAppointments.length > 0 && (
          <AppointemntsContainer>
            <ListTitle>Appointments ({sortedAppointments.length})</ListTitle>

            <List>
              {sortedAppointments.map((appointment) => (
                <Appointment key={appointment.id} appointment={appointment} />
              ))}
            </List>
          </AppointemntsContainer>
        )}
      </Content>
    </Container>
  );
};
