import styled from 'styled-components';

export const Panel = styled.div`
  border-radius: 24px;
  border: 1px solid #d2d9e1;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 74, 0.04);
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 16px;
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  z-index: 1;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 74, 0.04);
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #1d1d35;
`;

export const TitleBold = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const MapContainer = styled.div`
  min-height: 200px;
  height: 400px;
  width: 100%;
`;

export const DestinationMarker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #4c4c59;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  background-color: #f7f7fc;
`;

export const WorkerMarker = styled.div`
  border: 2px solid #ffffff;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  background-color: #009688;
  box-shadow: 0 4px 12px 4px rgba(0, 150, 136, 0.4);
`;
