import React from 'react';

export const ChartLegendPointIcon = ({
  size = '16px',
  color = 'currentColor',
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.874 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H11.874C11.4299 10.7252 9.86384 12 8 12C6.13616 12 4.57006 10.7252 4.12602 9H1C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7H4.12602C4.57006 5.27477 6.13616 4 8 4C9.86384 4 11.4299 5.27477 11.874 7Z"
      fill={color}
    />
  </svg>
);
