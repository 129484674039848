import React from 'react';
import { Container } from './styled';

interface Props {
  progress: number;
}

export const Progress = ({ progress }: Props) => {
  const rounded = Math.round(progress);

  return (
    <Container>
      <progress max="100" value={progress} />
      {rounded === 100 && progress < 100 ? 99 : rounded}%
    </Container>
  );
};
