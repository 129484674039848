import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sessionService } from '../../services/SessionService';

export const Invite = () => {
  const { workspaceSlug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionService.isAuthorized(workspaceSlug!)) {
      navigate(`/${workspaceSlug}/auth/login${window.location.search}`);
    } else {
      navigate(`/${workspaceSlug}`);
    }
  }, [navigate, workspaceSlug]);

  return null;
};
