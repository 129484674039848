import { apiFetch } from './fetch';

export const authApi = {
  requestOtp: (phoneOrEmail: string, companyId: number) =>
    apiFetch<{ validTimeInMin: number; canRequestAgainInSec: number }>({
      url: '/portal-service/auth/send-login-token',
      method: 'POST',
      data: { phoneOrEmail, companyId },
    }),
  login: (phoneOrEmail: string, otpCode: string, companyId: number) =>
    apiFetch<{ expiresIn: number; accessToken: string }>({
      url: '/portal-service/auth/login-with-otp',
      method: 'POST',
      data: { phoneOrEmail, otpCode, companyId },
    }),
};
