import React from 'react';

export const StrikeThroughIcon = ({
  size = '16px',
  color = 'currentColor',
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99935 3.33333C6.38002 3.33333 5.33268 4.36 5.33268 5.33333C5.33268 5.86933 5.51935 6.24133 5.90935 6.54533C6.33002 6.87333 7.01935 7.14333 8.05868 7.33333H12.666C12.8428 7.33333 13.0124 7.40357 13.1374 7.5286C13.2624 7.65362 13.3327 7.82319 13.3327 8C13.3327 8.17681 13.2624 8.34638 13.1374 8.4714C13.0124 8.59643 12.8428 8.66667 12.666 8.66667H11.2067C11.732 9.196 11.9993 9.86867 11.9993 10.6667C11.9993 12.6387 10.062 14 7.99935 14C6.66735 14 5.44802 13.4573 4.70868 12.5673C4.65089 12.5002 4.60703 12.4223 4.57968 12.3381C4.55233 12.2539 4.54203 12.1651 4.54939 12.0768C4.55674 11.9886 4.5816 11.9027 4.62252 11.8242C4.66343 11.7456 4.71958 11.676 4.78768 11.6194C4.85579 11.5629 4.93448 11.5204 5.01918 11.4945C5.10387 11.4687 5.19286 11.46 5.28096 11.4689C5.36906 11.4778 5.45449 11.5042 5.53228 11.5465C5.61007 11.5889 5.67865 11.6462 5.73402 11.7153C6.18935 12.262 7.01268 12.6667 7.99935 12.6667C9.61868 12.6667 10.666 11.64 10.666 10.6667C10.666 10.1307 10.4793 9.75867 10.0893 9.45467C9.66868 9.12667 8.97935 8.85667 7.94002 8.66667H3.33268C3.15587 8.66667 2.9863 8.59643 2.86128 8.4714C2.73625 8.34638 2.66602 8.17681 2.66602 8C2.66602 7.82319 2.73625 7.65362 2.86128 7.5286C2.9863 7.40357 3.15587 7.33333 3.33268 7.33333H4.79202C4.26668 6.804 3.99935 6.13133 3.99935 5.33333C3.99935 3.36133 5.93668 2 7.99935 2C8.98402 2 9.90068 2.296 10.6093 2.80333C10.7532 2.90624 10.8503 3.06207 10.8792 3.23654C10.9081 3.41101 10.8666 3.58983 10.7637 3.73367C10.6608 3.8775 10.505 3.97457 10.3305 4.00351C10.156 4.03245 9.97718 3.9909 9.83335 3.888C9.36335 3.55133 8.72202 3.33333 7.99935 3.33333Z"
      fill={color}
    />
  </svg>
);
