import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateActionPayload, actionsApi } from '../api/actions';
import { ReactQueryKeys } from './reactQueryKeys';

export const useUpdateAction = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      uuid,
      dto,
    }: {
      uuid: string;
      dto: UpdateActionPayload;
    }) => {
      await actionsApi.update({ uuid, dto });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.Projects]);
    },
  });
};
