import React, { useCallback, useRef, useState } from 'react';
import { UploadIcon } from '../../ui/icons/Upload';
import {
  Description,
  DropZone,
  DropZoneInner,
  HiddenFileInput,
  HightlightedText,
  MobileButton,
  MobileButtons,
} from './styled';
import { ButtonVariant } from '../../ui/Button';
import { CameraIcon } from '../../ui/icons/Camera';

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];

const MAX_SIZE_IN_MB = 25;

const filterFiles = (files: File[]) => {
  return files.filter((file) => {
    const isAllowed = ALLOWED_FILE_TYPES.includes(file.type);
    const isSizeOk = file.size <= MAX_SIZE_IN_MB * 1024 * 1024;

    return isAllowed && isSizeOk;
  });
};

interface Props {
  onAddFiles: (files: File[]) => void;
}

export const FileDropZone = ({ onAddFiles }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const filePhotoInputRef = useRef<HTMLInputElement>(null);

  const [isOver, setIsOver] = useState(false);

  const onDrop = useCallback<React.DragEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
      const droppedFiles = event.dataTransfer.files;

      if (droppedFiles.length) {
        onAddFiles(filterFiles([...droppedFiles]));
      }

      setIsOver(false);
    },
    [onAddFiles],
  );

  const onDragOver = useCallback<React.DragEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
    },
    [],
  );

  const handleDragEnter = useCallback(() => {
    setIsOver(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setIsOver(false);
  }, []);

  const onFileChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const selectedFiles = event.target.files ?? [];

      if (selectedFiles.length) {
        onAddFiles(filterFiles([...selectedFiles]));
      }
    },
    [onAddFiles],
  );

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  const openCamera = () => {
    filePhotoInputRef.current?.click();
  };

  return (
    <div>
      <DropZone
        onClick={openFileDialog}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <DropZoneInner isOver={isOver}>
          <UploadIcon size="16px" color="#009688" />
          {!isOver && (
            <>
              <HightlightedText>Click here to select files</HightlightedText> or
              drag&drop it here
            </>
          )}
          {isOver && 'Drop files here'}
        </DropZoneInner>
        <HiddenFileInput
          type="file"
          ref={fileInputRef}
          onChange={onFileChange}
          multiple
        />
      </DropZone>

      <MobileButtons>
        <MobileButton variant={ButtonVariant.Flat} onClick={openCamera}>
          <CameraIcon size="16px" />
          Take a photo
        </MobileButton>
        <MobileButton variant={ButtonVariant.Flat} onClick={openFileDialog}>
          <UploadIcon size="16px" />
          Upload files
        </MobileButton>

        <HiddenFileInput
          type="file"
          ref={fileInputRef}
          onChange={onFileChange}
          multiple
        />
        <HiddenFileInput
          type="file"
          accept="image/*"
          capture="environment"
          ref={filePhotoInputRef}
          onChange={onFileChange}
          multiple
        />
      </MobileButtons>

      <Description>
        <div>Supported formats: jpeg, png, pdf</div>
        <div>Maximum size: 25mb</div>
      </Description>
    </div>
  );
};
