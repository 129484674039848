import { apiFetchWithAuth } from './fetch';

export type ChartDTO = {
  metric: 'energy' | 'power' | 'storageEnergy' | 'storagePower';
  kind: 'points' | 'totalSystem' | 'totalTime' | 'total';
  // Postgres interval syntax, see https://www.postgresql.org/docs/current/datatype-datetime.html#DATATYPE-INTERVAL-INPUT
  period: string;
  meter?: Meter;
  startAt?: Date | string;
  endBefore?: Date | string;
};

export type ChartResult =
  // kind=points
  | SystemEnergy[]
  | SystemPower[]
  | SystemStorageEnergy[]
  | SystemStoragePower[]
  // kind=totalSystem
  | SystemTotalEnergy[]
  | SystemTotalPower[]
  | SystemTotalStorageEnergy[]
  | SystemTotalStoragePower[]
  // kind=totalTime
  | TimeTotalEnergy[]
  | TimeTotalPower[]
  | TimeTotalStorageEnergy[]
  | TimeTotalStoragePower[]
  // kind=total
  | TotalEnergy
  | TotalPower
  | TotalStorageEnergy
  | TotalStoragePower;

export enum Meter {
  Consumption = 'CONSUMPTION',
  Export = 'EXPORT',
  Import = 'IMPORT',
  Production = 'PRODUCTION',
}

export type SystemEnergy = {
  system: string;
  time: string;
  value: number;
};

export type SystemPower = {
  system: string;
  time: string;
  value: number;
};

export type SystemStorageEnergy = {
  system: string;
  time: string;
  charge: number;
  discharge: number;
};

export type SystemStoragePower = {
  system: string;
  time: string;
  charge: number;
  discharge: number;
  percent: number;
};

export type SystemTotalEnergy = {
  system: string;
  value: number;
};

export type SystemTotalPower = {
  system: string;
  value: number;
};

export type SystemTotalStorageEnergy = {
  system: string;
  charge: number;
  discharge: number;
};

export type SystemTotalStoragePower = {
  system: string;
  charge: number;
  discharge: number;
  percent: number;
};

export type TimeTotalEnergy = {
  time: string;
  value: number;
};

export type TimeTotalPower = {
  time: string;
  value: number;
};

export type TimeTotalStorageEnergy = {
  time: string;
  charge: number;
  discharge: number;
};

export type TimeTotalStoragePower = {
  time: string;
  charge: number;
  discharge: number;
  percent: number;
};

export type TotalEnergy = {
  value: number;
};

export type TotalPower = {
  value: number;
};

export type TotalStorageEnergy = {
  charge: number;
  discharge: number;
};

export type TotalStoragePower = {
  charge: number;
  discharge: number;
  percent: number;
};

export const fleetChartsApi = {
  getSystemsCharts: (dto: { systems: number[]; charts: ChartDTO[] }) =>
    apiFetchWithAuth<ChartResult[]>({
      url: '/fleet-service/charts/systems',
      method: 'POST',
      data: dto,
    }),
};
