import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;

  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #f7f7fc;

  gap: 16px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f8fafb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d2d9e1;
    outline: none;
    border-radius: 4px;
  }
`;

export const StageContainer = styled.div<{ isFuture: boolean }>`
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 74, 0.04);

  color: ${(props) => {
    if (props.isFuture) return '#828D9A';
    return '#1D1D35';
  }};
`;

export const StageHeader = styled.div`
  display: flex;
  align-items: center;

  white-space: nowrap;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  padding: 16px;

  svg {
    flex-shrink: 0;
  }
`;

export const StageName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;

  + svg {
    margin-right: auto;
  }
`;

export const StageContent = styled.div`
  > div {
    padding: 0 16px 16px 16px;
  }
`;

export const TasksProgress = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;

export const StageDescription = styled.div`
  margin-bottom: 24px;
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`;
