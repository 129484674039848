import { $getRoot, $insertNodes } from 'lexical';
import React, { useCallback } from 'react';
import type { EditorState, LexicalEditor } from 'lexical';

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';

import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { ListNode, ListItemNode } from '@lexical/list';
import { LinkNode, AutoLinkNode } from '@lexical/link';
import { theme } from './theme';
import { Container } from './styled';
import { ToolbarPlugin } from './plugins/Toolbar';

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: any) {
  // eslint-disable-next-line no-console
  console.error(error);
}

interface Props {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export const RichEditor = ({
  value: initialValue,
  placeholder = 'Type here...',
  onChange,
}: Props) => {
  const handleChange = useCallback(
    (_editorState: EditorState, editor: LexicalEditor) => {
      editor.update(() => {
        const html = $generateHtmlFromNodes(editor, null);
        onChange(html);
      });
    },
    [onChange],
  );

  const initialConfig = {
    nodes: [ListNode, ListItemNode, AutoLinkNode, LinkNode],
    namespace: 'coperniq-editor',
    theme,
    onError,
    editorState: (editor: LexicalEditor) => {
      if (!initialValue) return;

      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(initialValue, 'text/html');

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);

        // Select the root
        $getRoot().select();

        // Insert them at a selection.
        $insertNodes(nodes);
      });
    },
  };

  return (
    <Container>
      <LexicalComposer initialConfig={initialConfig}>
        <div className="editor-container">
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={
                <div className="editor-placeholder">{placeholder}</div>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <ListPlugin />
            <AutoFocusPlugin />
            <OnChangePlugin onChange={handleChange} ignoreSelectionChange />
          </div>
          <ToolbarPlugin />
        </div>
      </LexicalComposer>
    </Container>
  );
};
