import { apiFetchWithAuth } from './fetch';

export type SubmitRequestPayload = {
  address: string;
  description: string;
  additional?: Record<string, any>;
};

export type SubmitRequestAndSignUpPayload = SubmitRequestPayload & {
  contactName: string;
  primaryEmail: string;
  primaryPhone: string;
  referralCode?: string;
  companyId: number;
};

export const requestsApi = {
  submitRequest: (payload: SubmitRequestPayload) =>
    apiFetchWithAuth({
      url: '/project-service/contacts/requests',
      method: 'POST',
      data: payload,
    }),

  submitRequestAndSignUp: ({
    companyId,
    ...payload
  }: SubmitRequestAndSignUpPayload) =>
    apiFetchWithAuth({
      url: `/project-service/contacts/company/${companyId}/sign-up`,
      method: 'POST',
      data: payload,
    }),
};
