import React from 'react';
import {
  Placeholder,
  PlaceholderPrimary,
  PlaceholderSecondary,
} from './styled';

interface Props {
  imgSrc: string;
  imgMaxWidth?: string;
  primaryText: string;
  secondaryText?: React.ReactNode;
  fontSize?: 'default' | 'small';
  layout?: 'vertical' | 'horizontal';
}

export const PlaceholderWithImage = ({
  imgSrc,
  imgMaxWidth,
  primaryText,
  secondaryText,
  layout = 'vertical',
  fontSize = 'default',
}: Props) => {
  return (
    <Placeholder layout={layout} imgMaxWidth={imgMaxWidth}>
      <img src={imgSrc} alt={primaryText} />
      <div>
        <PlaceholderPrimary fontSize={fontSize}>
          {primaryText}
        </PlaceholderPrimary>
        {secondaryText && (
          <PlaceholderSecondary fontSize={fontSize}>
            {secondaryText}
          </PlaceholderSecondary>
        )}
      </div>
    </Placeholder>
  );
};
