import React from 'react';
import { Container, Label } from './styled';

interface Props<TValue extends string> {
  value: TValue;
  name: string;
  options: { label: string; value: TValue }[];
  onChange: (value: TValue) => void;
}

export const RadioGroup = <TValue extends string>({
  name,
  value,
  options,
  onChange,
}: Props<TValue>) => {
  return (
    <Container>
      {options.map((option) => (
        <>
          <input
            name={name}
            type="radio"
            id={`${name}_${option.value}`}
            value={option.value}
            checked={option.value === value}
            onChange={() => onChange(option.value)}
          />
          <Label htmlFor={`${name}_${option.value}`}>{option.label}</Label>
        </>
      ))}
    </Container>
  );
};
