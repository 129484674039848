import styled from 'styled-components';

export const Content = styled.div`
  z-index: 1001;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;
