import React, { useCallback } from 'react';
import { Container, SwitchContainer, Toggle, Label } from './styled';
import { SwitchVariant } from './types';

interface Props {
  isActive: boolean;
  onChange: (active: boolean) => void;
  label?: string;
  id?: string;
  labelPosition?: 'left' | 'right';
  variant?: SwitchVariant;
  secondLabel?: string;
}

export const Switch = ({
  isActive,
  label,
  id,
  onChange,
  labelPosition = 'right',
  secondLabel,
  variant = SwitchVariant.OneWay,
}: Props) => {
  const handleClick = useCallback(() => {
    onChange(!isActive);
  }, [isActive, onChange]);

  return (
    <Container isReverse={labelPosition === 'left'}>
      {secondLabel && (
        <Label
          data-type="switch-left"
          isActive={!isActive || variant === SwitchVariant.OneWay}
          onClick={handleClick}
          htmlFor={id}
        >
          {secondLabel}
        </Label>
      )}
      <SwitchContainer
        onClick={handleClick}
        isActive={isActive}
        role="checkbox"
        aria-checked={isActive}
        tabIndex={0}
        id={id}
      >
        <Toggle isActive={isActive} variant={variant} />
      </SwitchContainer>
      {label && (
        <Label
          data-type="switch-right"
          isActive={isActive || variant === SwitchVariant.OneWay}
          onClick={handleClick}
          htmlFor={id}
        >
          {label}
        </Label>
      )}
    </Container>
  );
};
