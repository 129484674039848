import { useMutation } from '@tanstack/react-query';
import { SubmitReferralPayload, referralsApi } from '../api/referrals';
import { showToast } from '../kit/components/Toast';

export const useSubmitReferral = () => {
  return useMutation({
    mutationFn: async (payload: SubmitReferralPayload) => {
      await referralsApi.submitReferral({
        contactName: payload.contactName,
        primaryEmail: payload.primaryEmail || null,
        primaryPhone: payload.primaryPhone,
      });
    },
    onSuccess: () => {
      showToast('Referral submitted!', 'success');
    },
  });
};
