import { request, RequestDocument, Variables } from 'graphql-request';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

import { ENV } from '../utils/env';
import { sessionService } from '../services/SessionService';
import { parseWorkspaceFromUrl } from '../utils/parseWorkspaceFromUrl';

const BASE_URL = `${ENV.API_URL}/portal-service/graphql`;

export const graphqlRequest = async <T>(
  query: RequestDocument | TypedDocumentNode<T>,
  variables?: Variables,
  initHeaders?: HeadersInit | undefined,
) => {
  const token = sessionService.getToken(
    parseWorkspaceFromUrl(window.location.href) ?? '',
  );

  const headers = new Headers(initHeaders ?? {});

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return request<T>(BASE_URL, query, variables, headers);
};
