import { useQuery } from '@tanstack/react-query';
import { graphql } from '../gql';
import { ReactQueryKeys } from './reactQueryKeys';
import { graphqlRequest } from '../api/graphqlRequest';
import { ChatQuery } from '../gql/graphql';

const query = graphql(/* GraphQL */ `
  query Chat {
    commentsConnection(orderBy: CREATED_AT_ASC) {
      nodes {
        id
        createdAt
        createdByType
        createdByUser {
          id
          firstName
          lastName
          avatarUrl
        }
        comment
      }
    }
  }
`);

export type Message = NonNullable<
  NonNullable<ChatQuery['commentsConnection']>['nodes'][number]
>;

export const useMessages = () => {
  return useQuery({
    queryKey: [ReactQueryKeys.Chat],
    queryFn: async () => {
      const { commentsConnection } = await graphqlRequest(query);

      return (commentsConnection?.nodes ?? []) as Message[];
    },
  });
};
