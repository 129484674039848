import styled from 'styled-components';
import { Panel } from '../../../kit/ui/Panel';

export const Container = styled(Panel)`
  background-color: #f0f5ff;
  border-color: transparent;
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const Stars = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

export const ChannelList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 16px;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const Channel = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 16px;
  font-weight: 500;

  > *:last-child {
    flex: 1;
    display: none;
  }

  @media (min-width: 768px) {
    > *:last-child {
      display: flex;
    }
  }
`;

export const ImgContainer = styled.div`
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}

    img {
        width: 100%;
        height: auto;
    }
`;
