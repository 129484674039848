import React, { useMemo } from 'react';
import { useProjects } from '../../../apiHooks/useProjects';
import { Project } from '../../../kit/components/Project';

export const ActiveProjects = () => {
  const { data: projects = [] } = useProjects();

  const activeProjects = useMemo(
    () =>
      projects.filter(
        (project) =>
          project.isActive &&
          !['CANCELLED', 'COMPLETE'].includes(project.stage?.type ?? ''),
      ),
    [projects],
  );

  if (activeProjects.length === 0) {
    return null;
  }

  return (
    <>
      {activeProjects.map((project) => (
        <Project key={project.id} project={project} />
      ))}
    </>
  );
};
