import React, { useCallback } from 'react';
import {
  CompletedAt,
  Container,
  Description,
  HeaderLeft,
  StatusAndDate,
  Title,
  UploadButton,
  When,
} from './styled';

import { Button, ButtonVariant } from '../../kit/ui/Button';
import { ActionStatus } from '../../gql/graphql';
import { useModal } from '../../kit/components/PromiseModal';
import { ActionUpload } from './ActionUpload';
import { Status } from './Status';
import { ProjectAction } from '../../apiHooks/useProjects';
import { UploadIcon } from '../../kit/ui/icons/Upload';
import { CalendarIcon } from '../../kit/ui/icons/Calendar';
import { FileUpIcon } from '../../kit/ui/icons/FileUp';
import { CheckCircleIcon } from '../../kit/ui/icons/CheckCircle';

const formatDate = (date: Date) => {
  return new Date(date).toLocaleString('en-US', {
    dateStyle: 'medium',
  });
};

interface Props {
  action: ProjectAction;
}

export const Action = ({ action }: Props) => {
  const { openModal } = useModal();

  const handleUploadClick = useCallback(() => {
    openModal<void>(
      ({ onClose }) => <ActionUpload action={action} onClose={onClose} />,
      { title: 'Document upload' },
    );
  }, [openModal, action]);

  const isCompleted = action.status === ActionStatus.Completed;
  const isCancelled = action.status === ActionStatus.Cancelled;

  const isToday =
    !isCompleted &&
    !isCancelled &&
    action.dueDate &&
    new Date(action.dueDate).toDateString() === new Date().toDateString();
  const isOverdue =
    !isCompleted &&
    !isCancelled &&
    action.dueDate &&
    !isToday &&
    new Date(action.dueDate) < new Date();

  return (
    <Container
      isDimmed={
        action.status === ActionStatus.Completed ||
        action.status === ActionStatus.Cancelled
      }
    >
      <HeaderLeft>
        <Title>
          {!isCompleted && <FileUpIcon size="16px" color="#235DFF" />}
          {isCompleted && <CheckCircleIcon size="16px" color="#71E7A9" />}
          {action.title ?? 'No title'}
        </Title>
        <When>
          <CalendarIcon
            size="16px"
            color={
              (isToday && '#FFBD13') || (isOverdue && '#D54855') || '#9C9CAA'
            }
          />
          {action.dueDate
            ? `Until ${formatDate(action.dueDate)}`
            : 'No due date'}
        </When>
      </HeaderLeft>

      <UploadButton>
        {action.status === ActionStatus.Requested && (
          <Button
            onClick={handleUploadClick}
            isBlock
            variant={ButtonVariant.Primary}
          >
            <UploadIcon size="16px" />
            Upload
          </Button>
        )}
        {action.status !== ActionStatus.Requested && (
          <StatusAndDate>
            <Status status={action.status} />
            {action.status === ActionStatus.Completed && (
              <CompletedAt>
                {new Date(action.completedAt).toLocaleString('en-US', {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                })}
              </CompletedAt>
            )}
          </StatusAndDate>
        )}
      </UploadButton>

      {action.description && <Description>{action.description}</Description>}
    </Container>
  );
};
