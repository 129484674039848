import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Item = styled.div`
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #dfdfe8;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  flex: 1;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const ProjectManager = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (min-width: 768px) {
    margin-left: auto;
  }
`;

export const AddressLink = styled.a`
  color: #235dff;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  text-decoration: none;
  display: flex;
  gap: 8px;
  text-decoration: none;
  cursor: pointer;
  svg {
    flex-shrink: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    color: #235dff;
  }
`;

export const ContactPhoto = styled.img<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 8px;
`;

export const ContactPhotoPlaceholder = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 8px;
  background-color: #f7f7fc;
  color: #c4c4c4;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
`;

export const ContactName = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const ContactJob = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
`;

export const StagesAndActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const PlaceholderPrimary = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const PlaceholderSecondary = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
`;
