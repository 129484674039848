import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { X } from 'react-feather';
import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  IconButton,
  Backdrop,
  ModalBodyContainer,
} from './styled';
import { MODALS_ROOT_ID } from './ModalsRoot';

interface ModalProps {
  isOpen?: boolean;
  onClose: () => void;
  title?: string;
  maxWidth?: string;
  children?: React.ReactNode;
  isHeaderShown?: boolean;
  isMobileFullScreen?: boolean;
}

const Modal = ({
  isOpen,
  onClose,
  isHeaderShown = true,
  isMobileFullScreen = false,
  children,
  title,
  maxWidth,
}: ModalProps) => {
  useEffect(() => {
    const scrollBarWidth =
      window.innerWidth - document.documentElement.clientWidth;

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    } else {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <Backdrop>
      <ModalContainer
        maxWidth={maxWidth}
        isMobileFullScreen={isMobileFullScreen}
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
      >
        {isHeaderShown && (
          <ModalHeader isMobileFullScreen={isMobileFullScreen}>
            <ModalTitle>{title}</ModalTitle>

            <IconButton onClick={onClose}>
              <X />
            </IconButton>
          </ModalHeader>
        )}
        <ModalBodyContainer>{children}</ModalBodyContainer>
      </ModalContainer>
    </Backdrop>,
    document.getElementById(MODALS_ROOT_ID) as HTMLElement,
  );
};

export { Modal };
export type { ModalProps };
