import React from 'react';
import { CoperniqUser } from '../../../../gql/graphql';
import {
  Contact,
  ContactName,
  ContactPhoto,
  ContactPhotoPlaceholder,
  ContactRight,
} from './styled';
import { UserIcon } from '../../../../kit/ui/icons/User';

interface Props {
  avatarSize?: number;
  user: Pick<CoperniqUser, 'firstName' | 'lastName' | 'avatarUrl'>;
  isDimmed?: boolean;
}

export const UserCard = ({ user, avatarSize = 24, isDimmed }: Props) => {
  return (
    <Contact isDimmed={isDimmed}>
      <div>
        {user.avatarUrl && (
          <ContactPhoto
            size={avatarSize}
            src={user.avatarUrl}
            alt={`${user.firstName} ${user.lastName} `}
          />
        )}
        {!user.avatarUrl && (
          <ContactPhotoPlaceholder size={avatarSize}>
            <UserIcon size="16px" />
          </ContactPhotoPlaceholder>
        )}
      </div>
      <ContactRight>
        <ContactName>
          {user.firstName} {user.lastName}
        </ContactName>
      </ContactRight>
    </Contact>
  );
};
