import React from 'react';
import { Container } from './styled';

interface Props {
  size?: number;
  className?: string;
  color?: string;
}

export const Loader = ({ size = 80, className, color = '#009688' }: Props) => {
  return (
    <Container color={color} size={size} className={className}>
      <div />
      <div />
      <div />
      <div />
    </Container>
  );
};
