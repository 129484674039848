import { apiFetchWithAuth } from './fetch';

export type SubmitReferralPayload = {
  contactName: string;
  primaryEmail: string | null;
  primaryPhone: string;
};

export const referralsApi = {
  submitReferral: (payload: SubmitReferralPayload) =>
    apiFetchWithAuth({
      url: '/project-service/contacts/referrals',
      method: 'POST',
      data: payload,
    }),
};
