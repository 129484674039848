import styled from 'styled-components';

export const Panel = styled.div`
  background-color: #f7f7fc;
  border-radius: 24px;
  border: 1px solid #dfdfe8;
  padding: 16px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 74, 0.04);

  @media (min-width: 768px) {
    padding: 24px;
  }
`;
