import React from 'react';
import notFoundImg from './404.png';
import { Container, Description, Title } from './styled';

export const NotFound = () => {
  return (
    <Container>
      <img src={notFoundImg} alt="Not found" />
      <div>
        <Title>404</Title>
        <Description>
          The page you are requesting does not exist. It may have been deleted,
          or you may have entered the wrong address
        </Description>
      </div>
    </Container>
  );
};
