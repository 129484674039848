import styled from 'styled-components';
import { H2 } from '../../kit/ui/Heading';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 40px;
  row-gap: 16px;

  ${H2} {
    margin-bottom: 0;
    margin-right: auto;
  }

  margin-bottom: 24px;

  .search {
    order: 1;
    width: 100%;
  }

  @media (min-width: 768px) {
    .search {
      order: initial;
      width: auto;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f7fc;
  border: 1px solid #e4e8ec;
  border-radius: 16px;
  padding: 8px 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 64px;
  padding: 12px 16px;
  border-bottom: 1px solid #e4e8ec;

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: 768px) {
    padding: 12px 24px;
  }
`;

export const Cell = styled.div<{
  width: number;
  align?: string;
  onlyDesktop?: boolean;
}>`
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  flex: 0 0 ${({ width }) => width}px;
  display: flex;
  justify-content: ${({ align = 'flex-start' }) => align};

  ${({ onlyDesktop }) =>
    onlyDesktop &&
    `
    @media (max-width: 768px) {
      display: none;
    }
  `}
`;

export const FileNameCell = styled.div`
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CreatedAt = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const FileIconThumbnail = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfdfe8;
  background-color: #fff;
`;

export const FileImageThumbnail = styled.img<{ size: number }>`
  object-fit: fill;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 4px;
`;

export const ProjectBadge = styled.div`
  border-radius: 16px;
  border: 1px solid #dfdfe8;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #828d9a;
  background-color: #fff;
`;
