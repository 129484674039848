import React from 'react';
import {
  AlertContainer,
  AnnotationBorderRect,
  AnnotationRect,
  AnnotationRectInner,
  AnnotationSubtitle,
  AnnotationTitle,
  BatteryToRectLine,
  ConsumedAnnotation,
  ConsumedLine,
  Container,
  HomeImage,
  HomeImageAndStats,
  RectBottomArrow,
  RectLeftArrow,
  RectRightArrow,
  RectTopArrow,
  SolarAnnotation,
  SolarLine,
  Title,
} from './styled';
import homeImg from './home.png';
import { SolarPowerIcon } from '../../ui/icons/SolarPower';
import { HomeIcon } from '../../ui/icons/Home';
import { ChevronUpIcon } from '../../ui/icons/ChevronUp';
import { ChevronRightIcon } from '../../ui/icons/ChevronRight';
import { ChevronDownIcon } from '../../ui/icons/ChevronDown';
import { ChevronLeftIcon } from '../../ui/icons/ChevronLeft';
import { TransmissionTowerIcon } from '../../ui/icons/TransmissionTower';
import { BatteryChargingIcon } from '../../ui/icons/BatteryCharging';
import { Alert, AlertType } from '../../ui/Alert';

export const Placeholder = () => {
  return (
    <Container>
      <Title>Your system health</Title>

      <AlertContainer>
        <Alert
          type={AlertType.Info}
          title="Work in progress"
          description="Once the work is completed, you’ll be able to view all the details and data about your system right here. Stay tuned for updates!"
        />
      </AlertContainer>
      <HomeImageAndStats>
        <HomeImage src={homeImg} alt="Solar System" />

        <SolarAnnotation>
          <AnnotationTitle isPlaceholder>
            <SolarPowerIcon size="16px" color="#FFBD13" />- kW
          </AnnotationTitle>
          <AnnotationSubtitle>solar</AnnotationSubtitle>
        </SolarAnnotation>
        <SolarLine />

        <ConsumedAnnotation>
          <AnnotationTitle isPlaceholder>
            <HomeIcon size="16px" color="#9C9CAA" />- kW
          </AnnotationTitle>
          <AnnotationSubtitle>consumed</AnnotationSubtitle>
        </ConsumedAnnotation>
        <ConsumedLine />

        <BatteryToRectLine />

        <AnnotationBorderRect>
          <RectLeftArrow>
            <ChevronUpIcon size="12px" />
          </RectLeftArrow>
          <RectTopArrow>
            <ChevronRightIcon size="12px" />
          </RectTopArrow>
          <RectRightArrow>
            <ChevronDownIcon size="12px" />
          </RectRightArrow>
          <RectBottomArrow>
            <ChevronLeftIcon size="12px" />
          </RectBottomArrow>
        </AnnotationBorderRect>

        <AnnotationRect>
          <AnnotationRectInner>
            <div>
              <AnnotationTitle isPlaceholder>
                <TransmissionTowerIcon size="16px" color="#9C9CAA" />- kW
              </AnnotationTitle>
              <AnnotationSubtitle>exported</AnnotationSubtitle>
            </div>
            <div>
              <AnnotationTitle isPlaceholder>
                <TransmissionTowerIcon size="16px" color="#9C9CAA" />- kW
              </AnnotationTitle>
              <AnnotationSubtitle>imported</AnnotationSubtitle>
            </div>
            <div>
              <AnnotationTitle isPlaceholder>
                <BatteryChargingIcon size="16px" color="#9C9CAA" />- kW
              </AnnotationTitle>
              <AnnotationSubtitle>charged</AnnotationSubtitle>
            </div>
            <div>
              <AnnotationTitle isPlaceholder>
                <BatteryChargingIcon size="16px" color="#9C9CAA" />- kW
              </AnnotationTitle>
              <AnnotationSubtitle>discharged</AnnotationSubtitle>
            </div>
          </AnnotationRectInner>
        </AnnotationRect>
      </HomeImageAndStats>
    </Container>
  );
};
