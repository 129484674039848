import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useCompany } from '../../../apiHooks/useCompany';
import { Container, ImgContainer, Title } from './styled';
import { Button, ButtonVariant } from '../../../kit/ui/Button';
import defaultPromptImage from '../defaultPromptImage.png';
import { Referral } from '../Referral';
import { useModal } from '../../../kit/components/PromiseModal';
import { PresentIcon } from '../../../kit/ui/icons/Present';

export const Widget = () => {
  const { workspaceSlug } = useParams();
  const { data: company } = useCompany(workspaceSlug ?? '');
  const { openModal } = useModal();

  const referralSettings = company?.settings?.referral;

  const { enabled, promptTitle, promptDescription, promptImageUrl } =
    referralSettings || {};

  const handleReferClick = useCallback(() => {
    if (!referralSettings) {
      return;
    }

    openModal<void>(
      ({ onClose }) => <Referral {...referralSettings} onClose={onClose} />,
      {
        title: referralSettings?.promptTitle,
        maxWidth: '90vw',
        isMobileFullScreen: true,
      },
    );
  }, [openModal, referralSettings]);

  if (!enabled) {
    return null;
  }

  return (
    <Container>
      <ImgContainer>
        <img src={promptImageUrl || defaultPromptImage} alt="prompt" />
      </ImgContainer>
      <div>
        <Title>{promptTitle}</Title>

        {promptDescription}
      </div>
      <Button variant={ButtonVariant.Primary} onClick={handleReferClick}>
        <PresentIcon size="16px" />
        Refer & Earn
      </Button>
    </Container>
  );
};
