import React, { useEffect, useMemo } from 'react';
import {
  AddressField,
  Form,
  FormValidationRules,
  InputField,
  RichEditorField,
  useForm,
  validateUrl,
} from '../../kit/components/Form';
import { Button, ButtonSize, ButtonVariant } from '../../kit/ui/Button';
import { Body, Footer, Grid, Group, GroupName } from './styled';
import { sessionService } from '../../services/SessionService';
import { useSubmitRequest } from '../../apiHooks/useSubmitRequest';
import { useSubmitRequestAndSignUp } from '../../apiHooks/useSubmitRequestAndSignUp';
import { useCompany } from '../../apiHooks/useCompany';
import { useLocation, useParams } from 'react-router-dom';
import { useRequestProperties } from '../../apiHooks/useRequestProperties';
import { FormValues, PropertyType } from './types';
import { CustomPropertyField } from './CustomPropertyField';
import { ProjectColumnVisibility } from '../../gql/graphql';

interface Props {
  isInModal?: boolean;
  onClose?: () => void;
}

export const RequestForm = ({ onClose, isInModal = true }: Props) => {
  const { workspaceSlug } = useParams();
  const isAuthorized = sessionService.isAuthorized(workspaceSlug!);
  const { search } = useLocation();

  const referralCode = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('r');
  }, [search]);

  const isPreview = useMemo(() => {
    const params = new URLSearchParams(search);
    return Boolean(params.get('preview'));
  }, [search]);

  const { data: company } = useCompany(workspaceSlug ?? '');
  const { data: groups, isLoading: isPropertiesLoading } =
    useRequestProperties(company);

  const { mutateAsync: submitRequest } = useSubmitRequest();
  const { mutateAsync: submitRequestAndSignUp } = useSubmitRequestAndSignUp();

  const postForm = async (values: FormValues) => {
    if (!isAuthorized) {
      if (!company) {
        return;
      }

      await submitRequestAndSignUp({
        ...values,
        referralCode: referralCode ?? undefined,
        contactName: values.contactName ?? '',
        primaryEmail: values.primaryEmail ?? '',
        primaryPhone: values.primaryPhone ?? '',
        companyId: company.id,
      });
    } else {
      await submitRequest(values);
    }

    onClose?.();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: {},
  });

  const {
    formState: { isSubmitting },
    control,
    reset,
  } = form;

  useEffect(() => {
    if (!isPropertiesLoading && groups?.length) {
      reset({
        additional: groups.reduce(
          (acc, group) => {
            group.properties.forEach((property) => {
              if (property.additional?.defaultValue) {
                acc[property.id] = property.additional.defaultValue;
              }
            });

            return acc;
          },
          {} as Record<string, any>,
        ),
      });
    }
  }, [groups, isPropertiesLoading, reset]);

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      address: {
        isRequired: true,
      },
      description: {
        isRequired: true,
      },
      ...(isAuthorized
        ? {}
        : {
            contactName: {
              isRequired: true,
            },
            primaryEmail: {
              isRequired: true,
              isEmail: true,
            },
            primaryPhone: {
              isRequired: true,
              isPhone: true,
            },
          }),
      ...groups?.reduce((acc, group) => {
        group.properties.forEach((property) => {
          if (
            property.externalVisibility === ProjectColumnVisibility.Required
          ) {
            acc[`additional.${property.id}`] = {
              isRequired: true,
            };
          }

          if (property.type === PropertyType.Link) {
            acc[`additional.${property.id}`] = {
              ...acc[`additional.${property.id}`],
              validate: validateUrl,
            };
          }
        });

        return acc;
      }, {} as FormValidationRules<FormValues>),
    }),
    [groups, isAuthorized],
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body isInModal={isInModal} width={isInModal ? '750px' : undefined}>
        {!isAuthorized && (
          <Grid>
            <InputField
              name="contactName"
              control={control}
              label="Your full name"
              placeholder="Enter full name here"
            />
            <InputField
              name="primaryEmail"
              control={control}
              label="Email"
              placeholder="youremail@mail.com"
            />
            <InputField
              name="primaryPhone"
              control={control}
              label="Phone"
              placeholder="+1 (___) ___ __ __"
            />
          </Grid>
        )}
        <AddressField label="Address" name="address" control={control} />
        <RichEditorField
          name="description"
          control={control}
          label="Description"
        />
        {groups?.map((group) => (
          <Group key={group.id}>
            <GroupName>{group.name}</GroupName>

            <Grid>
              {group.properties.map((property) => (
                <CustomPropertyField
                  key={property.id}
                  property={property}
                  control={control}
                />
              ))}
            </Grid>
          </Group>
        ))}
      </Body>
      <Footer isInModal={isInModal}>
        {isInModal && (
          <Button
            type="button"
            size={ButtonSize.Large}
            variant={ButtonVariant.Secondary}
            onClick={onClose}
          >
            Cancel
          </Button>
        )}
        <Button
          size={ButtonSize.Large}
          type="submit"
          variant={ButtonVariant.Primary}
          disabled={isSubmitting || isPreview}
        >
          Submit
        </Button>
      </Footer>
    </Form>
  );
};
