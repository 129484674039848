import { ActionStatus } from '../gql/graphql';
import { apiFetchWithAuth } from './fetch';

export type UpdateActionPayload = {
  status: ActionStatus;
  fileIds: number[];
};

export const actionsApi = {
  update: ({ uuid, dto }: { uuid: string; dto: UpdateActionPayload }) =>
    apiFetchWithAuth<{ id: number; status: ActionStatus }>({
      url: `/project-service/action/portal/${uuid}`,
      method: 'PATCH',
      data: dto,
    }),
};
