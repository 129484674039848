import React, { createContext, useContext, useMemo } from 'react';
import { FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';

type FormValidationRules<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  [fieldName in TName]?: Omit<
    RegisterOptions<TFieldValues, TName>,
    | 'valueAsNumber'
    | 'valueAsDate'
    | 'setValueAs'
    | 'disabled'
    | 'required'
    | 'minLength'
    | 'maxLength'
    | 'min'
    | 'max'
    | 'validate'
  > & {
    isRequired?: boolean;
    minLength?: number;
    maxLength?: number;
    min?: number;
    max?: number;
    validate?: (value: unknown) => string | undefined;
  };
};

type FormValidationRulesForHookForm<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  [fieldName in TName]?: (
    fieldName: React.ReactNode,
  ) => Omit<
    RegisterOptions<TFieldValues, TName>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

type MessageMap = {
  required: (fieldName: React.ReactNode) => string;
  minLength: (value: number) => string;
  maxLength: (value: number) => string;
  min: (value: number) => string;
  max: (value: number) => string;
};

const transformRules = (
  rules: FormValidationRules,
  messagesMap: MessageMap,
): FormValidationRulesForHookForm =>
  Object.keys(rules).reduce((acc, field) => {
    const { minLength, maxLength, isRequired, min, max, ...otherRules } =
      rules[field] || {};

    acc[field] = (fieldName: React.ReactNode) => ({
      ...otherRules,
      minLength:
        minLength !== undefined
          ? { value: minLength, message: messagesMap.minLength(minLength) }
          : undefined,
      maxLength:
        maxLength !== undefined
          ? { value: maxLength, message: messagesMap.maxLength(maxLength) }
          : undefined,
      min:
        min !== undefined
          ? { value: min, message: messagesMap.min(min) }
          : undefined,
      max:
        max !== undefined
          ? { value: max, message: messagesMap.max(max) }
          : undefined,
      required: isRequired
        ? { value: isRequired, message: messagesMap.required(fieldName) }
        : undefined,
    });

    return acc;
  }, {} as FormValidationRulesForHookForm);

const FormValidationContext =
  createContext<FormValidationRulesForHookForm | null>(null);

const FormValidationProvider = ({
  value,
  children,
}: {
  value: FormValidationRules;
  children: React.ReactNode;
}) => {
  const rules = useMemo(
    () =>
      transformRules(value, {
        required: (fieldName) =>
          typeof fieldName === 'string'
            ? `${fieldName} is required`
            : 'This is required',
        minLength: (minLength) => `Minimum length is ${minLength} characters`,

        maxLength: (maxLength) => `Maximum length is ${maxLength} characters`,
        min: (min) => `Minimum value is ${min}`,
        max: (max) => `Maximum value is ${max}`,
      }),
    [value],
  );

  return (
    <FormValidationContext.Provider value={rules}>
      {children}
    </FormValidationContext.Provider>
  );
};

const useFormValidationContext = () => useContext(FormValidationContext);

export { FormValidationProvider, useFormValidationContext };
export type { FormValidationRules };
