import React from 'react';

import { ButtonProps } from './types';
import { Container, IconButton } from './styled';
import { Loader } from '../Loader';

export const Button = ({
  isLoading,
  children,
  onClick,
  type = 'button',
  ...props
}: ButtonProps) => {
  if (isLoading) {
    return (
      <Container type={type} isLoading {...props}>
        <Loader size={40} color="#fff" />
      </Container>
    );
  }

  return (
    <Container type={type} onClick={onClick} {...props}>
      {children}
    </Container>
  );
};

export { IconButton };
