export enum BadgeSize {
  Default = 'default',
  Small = 'small',
}

export interface BadgeProps {
  size?: BadgeSize;
  children?: React.ReactNode;
  color: string;
  bgColor: string;
}
