import styled from 'styled-components';

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
`;

export const List = styled.div`
  border-radius: 12px;
  border: 1px solid #dfdfe8;
  overflow: hidden;

  > *:not(:last-child) {
    border-bottom: 1px solid #dfdfe8;
  }
`;

export const PlaceholderPrimary = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const PlaceholderSecondary = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
`;
