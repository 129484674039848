import { useQuery } from '@tanstack/react-query';
import { graphql } from '../gql';
import { ReactQueryKeys } from './reactQueryKeys';
import { graphqlRequest } from '../api/graphqlRequest';

const query = graphql(/* GraphQL */ `
  query Projects {
    projects(filter: { type: { equalTo: "PROJECT" } }) {
      id
      isActive
      address
      title
      description
      projectSize
      projectValue
      trades
      type
      stageId
      stage {
        type
      }
      imageUrl
      streetViewUrl
      projectManager {
        id
        firstName
        lastName
        avatarUrl
        email
        phone
      }

      blueprint {
        blueprintProjectStages {
          position
          projectStage {
            id
            name
            type
            description
          }
        }
      }

      actionsByProjectId {
        id
        uuid
        createdAt
        title
        description
        status
        dueDate
        completedAt
        project {
          id
          address
        }

        projectStageId

        projectStage {
          id
        }

        files {
          id
          name
          downloadUrl
          metaData
        }
      }

      tasksByProjectIdConnection {
        nodes {
          taskVisits {
            id
            createdAt
            startDate
            endDate
            isCompleted
            task {
              id
              title
              address
              status
              projectStageId
              project {
                type
                address
                geoLocation
                accountType
              }
              assignee {
                id
                firstName
                lastName
                avatarUrl
                email
                phone
              }
            }
          }
        }
      }
    }
  }
`);

export const useProjects = () => {
  return useQuery({
    queryKey: [ReactQueryKeys.Projects],
    queryFn: async () => {
      const response = await graphqlRequest(query);

      if (!response.projects) {
        return [];
      }

      return response.projects;
    },
  });
};

export type Project = Exclude<
  ReturnType<typeof useProjects>['data'],
  undefined
>[number];
export type ProjectWorkOrderVisit = Exclude<
  Exclude<
    ReturnType<typeof useProjects>['data'],
    undefined
  >[number]['tasksByProjectIdConnection']['nodes'][number],
  null
>['taskVisits'][number];
export type ProjectAction = Exclude<
  Exclude<
    ReturnType<typeof useProjects>['data'],
    undefined
  >[number]['actionsByProjectId'][number],
  null
>;
