import { useQuery } from '@tanstack/react-query';
import { graphql } from '../gql';
import { ReactQueryKeys } from './reactQueryKeys';
import { graphqlRequest } from '../api/graphqlRequest';

const query = graphql(/* GraphQL */ `
  query ServiceVisits {
    taskVisits(
      filter: { task: { project: { type: { equalTo: "ACCOUNT" } } } }
    ) {
      id
      createdAt
      startDate
      endDate
      isCompleted

      task {
        id
        title
        address
        status
        projectStageId
        project {
          type
          address
          geoLocation
          accountType
        }
        assignee {
          id
          firstName
          lastName
          avatarUrl
          email
          phone
        }
      }
    }
  }
`);

export const useServiceVisits = () => {
  return useQuery({
    queryKey: [ReactQueryKeys.ServiceVisits],
    queryFn: async () => {
      const response = await graphqlRequest(query);

      if (!response?.taskVisits) {
        return [];
      }

      return response.taskVisits;
    },
  });
};

export type ServiceVisit = Exclude<
  ReturnType<typeof useServiceVisits>['data'],
  undefined
>[number];
