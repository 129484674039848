import React from 'react';
import { InfoIcon } from '../icons/Info';
import { AlertType } from './types';

export const ALERT_COLORS: Record<
  AlertType,
  { bgColor: string; color: string }
> = {
  [AlertType.Info]: {
    bgColor: '#DBE5FF',
    color: '#235DFF',
  },
};

export const ALERT_ICONS: Record<AlertType, React.ReactNode> = {
  [AlertType.Info]: (
    <InfoIcon size="24px" color={ALERT_COLORS[AlertType.Info].color} />
  ),
};
