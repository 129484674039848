import React, { useCallback, useState } from 'react';

import {
  Container,
  Header,
  Content,
  Footer,
  IncomingMessageContainer,
  IncomingMessageContent,
  IncomingMessageHeader,
  Author,
  Time,
  SendButton,
  OutgoingMessageContainer,
  OutgoingMessageContent,
  Scrollable,
  LogoImage,
  CompanyName,
  Description,
} from './styled';
import { Input } from '../../kit/ui/Input';
import { useMessages, type Message } from '../../apiHooks/useMessages';
import { CommentCreatedBy } from '../../gql/graphql';
import { Avatar } from '../../kit/ui/Avatar';
import { Send } from 'react-feather';
import { useCreateMessage } from '../../apiHooks/useCreateMessage';
import { useMe } from '../../apiHooks/useMe';
import { sessionService } from '../../services/SessionService';
import { useParams } from 'react-router-dom';
import { useCompany } from '../../apiHooks/useCompany';
import placeholderImg from './placeholder.png';
import { PlaceholderWithImage } from '../../kit/components/PlaceholderWithImage';

const IncomingMessage = ({ message }: { message: Message }) => {
  return (
    <IncomingMessageContainer>
      <Avatar
        size={32}
        src={message.createdByUser?.avatarUrl}
        letters={[
          message?.createdByUser?.firstName?.[0],
          message?.createdByUser?.lastName?.[0],
        ]
          .filter(Boolean)
          .join('')}
      />
      <IncomingMessageContent>
        <IncomingMessageHeader>
          <Author>
            {message?.createdByUser?.firstName}{' '}
            {message?.createdByUser?.lastName}
          </Author>
          <Time>
            {new Intl.DateTimeFormat('en-US', {
              dateStyle: 'short',
              timeStyle: 'short',
            }).format(new Date(message?.createdAt))}
          </Time>
        </IncomingMessageHeader>
        <div dangerouslySetInnerHTML={{ __html: message?.comment ?? '' }}></div>
      </IncomingMessageContent>
    </IncomingMessageContainer>
  );
};

const OutgoingMessage = ({ message }: { message: Message }) => {
  return (
    <OutgoingMessageContainer>
      <OutgoingMessageContent>
        <div dangerouslySetInnerHTML={{ __html: message?.comment ?? '' }}></div>
        <IncomingMessageHeader>
          <div></div>
          <Time>
            {new Intl.DateTimeFormat('en-US', {
              dateStyle: 'short',
              timeStyle: 'short',
            }).format(new Date(message?.createdAt))}
          </Time>
        </IncomingMessageHeader>
      </OutgoingMessageContent>
    </OutgoingMessageContainer>
  );
};

export const Chat = () => {
  const { data } = useMe();
  const { workspaceSlug } = useParams();
  const { data: company } = useCompany(workspaceSlug ?? '');

  const { data: messages = [], isLoading } = useMessages();
  const { mutateAsync: createMessage } = useCreateMessage();
  const [inputValue, setInputValue] = useState('');
  const scrollContainerNode = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (scrollContainerNode.current) {
      scrollContainerNode.current.scrollTop =
        scrollContainerNode.current.scrollHeight;
    }
  }, [messages]);

  const isPreview = !sessionService.isUser(workspaceSlug!);

  const handleSubmit = useCallback<React.FormEventHandler>(
    async (event) => {
      event.preventDefault();

      if (isPreview) {
        return;
      }

      await createMessage({ message: inputValue, projectId: data?.accountId });

      setInputValue('');
    },
    [createMessage, inputValue, data?.accountId, isPreview],
  );

  const handleInputChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((event) => {
    setInputValue(event.target.value);
  }, []);

  return (
    <Container>
      <Header>
        {company?.logoUrl && <LogoImage src={company.logoUrl} />}
        <div>
          <CompanyName>{company?.name}</CompanyName>
          <Description>We are here to help</Description>
        </div>
      </Header>
      <Scrollable ref={scrollContainerNode}>
        <Content isEmpty>
          {!isLoading && messages.length === 0 && (
            <PlaceholderWithImage
              imgSrc={placeholderImg}
              primaryText="Chat with us"
              secondaryText="Have any questions? Please, share"
            />
          )}
          {messages.map((message) =>
            message.createdByType === CommentCreatedBy.User ? (
              <IncomingMessage key={message.id} message={message} />
            ) : (
              <OutgoingMessage key={message.id} message={message} />
            ),
          )}
        </Content>
      </Scrollable>
      <Footer>
        <form onSubmit={handleSubmit}>
          <Input
            value={inputValue}
            onChange={handleInputChange}
            placeholder={
              isPreview
                ? 'Messaging is disabled in preview'
                : 'Type your message'
            }
            disabled={isPreview}
            rightAddon={
              <SendButton
                onClick={handleSubmit}
                isDisabled={isPreview || !inputValue}
              >
                <Send size="16px" />
              </SendButton>
            }
          />
        </form>
      </Footer>
    </Container>
  );
};
