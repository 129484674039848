import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitRequestPayload, requestsApi } from '../api/requests';
import { showToast } from '../kit/components/Toast';
import { ReactQueryKeys } from './reactQueryKeys';

export const useSubmitRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: SubmitRequestPayload) => {
      await requestsApi.submitRequest(payload);
    },
    onSuccess: () => {
      showToast('Request submitted!', 'success');
      queryClient.invalidateQueries([ReactQueryKeys.Requests]);
    },
  });
};
