import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form';

import { scrollToFirstError } from './scrollToFirstError';

const applyServerValidation = <TFormValues extends FieldValues>(
  setError: UseFormSetError<TFormValues>,
  errors?: { message: string; path: string }[],
) => {
  if (!errors || !Array.isArray(errors)) {
    return;
  }

  errors.forEach(({ message, path }, index) => {
    const field = path as FieldPath<TFormValues>;

    setError(
      field,
      {
        message,
        type: 'server validations',
      },
      { shouldFocus: index === 0 },
    );
  });

  scrollToFirstError();
};

export { applyServerValidation };
