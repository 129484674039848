import { useMutation, useQueryClient } from '@tanstack/react-query';
import { commentsApi } from '../api/comments';
import { ReactQueryKeys } from './reactQueryKeys';

export const useCreateMessage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      message,
      projectId,
    }: {
      message: string;
      projectId?: number;
    }) => {
      const { id } = await commentsApi.create({ comment: message, projectId });
      return id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.Chat]);
    },
  });
};
