import styled from 'styled-components';

export const Contact = styled.div<{ isDimmed?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;

  > * {
    flex-shrink: 0;

    &:first-child {
      text-align: center;
    }
  }

  @media (min-width: 768px) and (max-width: 1439px) {
    gap: 8px;
  }

  ${({ isDimmed }) =>
    isDimmed &&
    `
    color: #828D9A;

    > *:first-child {
      opacity: 0.5;
    }
  `};
`;

export const ContactPhoto = styled.img<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 4px;

  @media (min-width: 768px) and (max-width: 1439px) {
    width: ${(props) => props.size / 2}px;
    height: ${(props) => props.size / 2}px;
    min-width: 24px;
    min-height: 24px;
  }
`;

export const ContactPhotoPlaceholder = styled.div<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 4px;
  background-color: #f7f7fc;
  color: #c4c4c4;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) and (max-width: 1439px) {
    width: ${(props) => props.size / 2}px;
    height: ${(props) => props.size / 2}px;
    min-width: 24px;
    min-height: 24px;
  }
`;

export const ContactRight = styled.div`
  min-width: 0;
  white-space: nowrap;
  flex: 1;
`;

export const ContactName = styled.div`
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;
