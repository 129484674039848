import React, { forwardRef, useCallback } from 'react';

import { Container, HtmlInput } from './styled';
import { InputProps, InputSize } from './types';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      size = InputSize.Default,
      className,
      leftAddon,
      rightAddon,
      minWidth,
      type,
      inputContainerRef,
      onKeyDown,
      ...props
    },
    ref,
  ) => {
    const handleWheel = useCallback((e: React.WheelEvent<HTMLInputElement>) => {
      (e.target as HTMLInputElement).blur();
    }, []);

    const handleKeyDown = useCallback<
      React.KeyboardEventHandler<HTMLInputElement>
    >(
      (e) => {
        if (type === 'number' && ['e', 'E', '+'].includes(e.key)) {
          e.preventDefault();
        }
        onKeyDown?.(e);
      },
      [type, onKeyDown],
    );

    return (
      <Container
        ref={inputContainerRef}
        size={size}
        className={className}
        minWidth={minWidth}
      >
        {leftAddon}
        <HtmlInput
          type={type}
          {...props}
          ref={ref}
          onKeyDown={handleKeyDown}
          onWheel={handleWheel}
        />
        {rightAddon}
      </Container>
    );
  },
);
