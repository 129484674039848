import styled, { createGlobalStyle, css } from 'styled-components';
import { Input } from '../Input';
import { SelectVariant } from './types';

export const SelectGlobalStyle = createGlobalStyle`
 .backdrop {
  z-index: 1000;
 }  
@media (max-width: 767px) {
    .backdrop {
      background: rgba(0, 0, 0, 0.5);
    }
  }
`;

export const Container = styled.div`
  position: relative;
  margin-top: 4px;
`;

export const SelectButton = styled.button<{
  isDisabled?: boolean;
  variant?: SelectVariant;
}>`
  border-radius: 4px;
  border: 1px solid #dfdfe8;
  background: #fff;
  outline: 0;
  height: 40px;
  width: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: border-color 0.15s ease-in-out;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family);
  color: ${({ isDisabled }) => (isDisabled ? '#C8C8D3' : '#1D1D35')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  svg {
    color: #9c9caa;
    flex-shrink: 0;
    transition: border-color 0.15s ease-in-out;

    &:first-of-type {
      margin-left: auto;
    }
    &:hover {
      color: #1d1d35;
    }
  }

  &:hover {
    border-color: #c8c8d3;
  }

  &:focus {
    border-color: #009688;
  }

  ${({ variant }) =>
    variant === SelectVariant.FlatOnDesktop &&
    css`
      @media (min-width: 768px) {
        display: inline-flex;
        width: auto;
        border: none;
        background-color: transparent;
        color: #009688;

        svg,
        svg:hover {
          color: #009688;
        }

        .clear-icon {
          display: none;
        }
      }
    `};
`;
export const MultiSelectButton = styled(SelectButton)`
  min-height: 40px;
  height: auto;
`;

export const SelectInput = styled(Input)`
  svg {
    cursor: pointer;
    color: #9c9caa;
    flex-shrink: 0;
    transition: border-color 0.15s ease-in-out;

    &:first-of-type {
      margin-left: auto;
    }
    &:hover {
      color: #1d1d35;
    }
  }
`;

export const ChipList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Chip = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #1d1d35;
  height: 24px;
  border-radius: 4px;
  background-color: #f2f4f6;
  margin: 4px 2px;

  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 4px;
`;

export const SelectValue = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Placeholder = styled.span`
  color: #9c9caa;
  font-size: 12px;
  font-weight: 400;
`;

export const Menu = styled.div`
  padding: 0;
  margin: 0;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background-color: #fff;
  max-height: 200px;
  min-width: 100px;
  overflow-y: auto;
  position: absolute;
  z-index: 2000;
  outline: 0;
  box-shadow: 0px 0px 16px 0px rgba(0, 150, 136, 0.12);

  @media (max-width: 767px) {
    width: 100% !important;
    min-width: 100% !important;
    left: 0;
    right: 0;
    bottom: 0;
    top: initial !important;
    transform: none !important;
    border-radius: 16px 16px 0 0;
  }
`;

export const Option = styled.div<{
  isDisabled?: boolean;
  isActive: boolean;
  isSelected: boolean;
}>`
  padding: 7px 32px 7px 12px;
  font-size: 14px;
  font-weight: 500;
  outline: 0;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  color: ${({ isDisabled }) => (isDisabled ? '#C8C8D3' : '#1D1D35')};

  background: ${({ isSelected }) => (isSelected ? '#F7F7FC' : 'transparent')};

  @media (min-width: 768px) {
    background: ${({ isActive, isSelected }) =>
      isActive || isSelected ? '#F7F7FC' : 'transparent'};

    &:hover {
      background: #f7f7fc;
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;

  @media (max-width: 767px) {
    min-height: 40px;
  }
`;

export const MobileMenuHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 16px 0;
`;

export const MobileMenuFooter = styled.div`
  padding: 16px 8px;
`;

export const SearchContainer = styled.div`
  padding: 16px 8px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
`;
