import React from 'react';
import { useMe } from '../../../apiHooks/useMe';
import { System, SystemPlaceholder } from '../../../kit/components/System';

export const Systems = () => {
  const { data: me } = useMe();

  if (!me) {
    return null;
  }

  if (!me.systems?.length) {
    return <SystemPlaceholder />;
  }

  return (
    <>
      {me.systems.map((system) => (
        <System key={system.id} system={system} />
      ))}
    </>
  );
};
