import React, { PropsWithChildren } from 'react';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DateTime, Interval } from 'luxon';
import { formatEnergy, formatPower } from './helpers';
import { PointTooltip, TooltipLabel, TooltipValue } from './styled';
import { CircleIcon } from '../../ui/icons/Circle';
import {
  ChartPoint,
  EnergyPoint,
  PowerPoint,
} from '../../../apiHooks/useSystemHealthChart';
import { ChartPeriod, chartPeriodConfig } from '../../../models/system';

enum Items {
  production = 'production',
  consumption = 'consumption',
  power = 'power',
}

type Props = PropsWithChildren<{
  points: ChartPoint[];
  period: ChartPeriod;
  kind: 'energy' | 'power';
  meters: { production?: boolean; consumption?: boolean };
}>;

export const SystemChart = ({
  points,
  period,
  kind,
  meters: { production, consumption },
  children,
}: Props) => {
  const periodConfig = chartPeriodConfig[period];

  const formatTooltipTime = (time: DateTime) => {
    switch (period) {
      case ChartPeriod.day:
        return kind === 'power'
          ? time.toLocaleString(DateTime.DATETIME_SHORT)
          : Interval.after(time, periodConfig.pointGranularity).toLocaleString(
              DateTime.DATETIME_SHORT,
            );
      case ChartPeriod.week:
        return time.toLocaleString(DateTime.DATE_SHORT);
      case ChartPeriod.month:
        return time.toLocaleString(DateTime.DATE_SHORT);
      case ChartPeriod.year:
        return time.toLocaleString({ month: 'long', year: 'numeric' });
      case ChartPeriod.lifetime:
        return time.toLocaleString({ year: 'numeric' });
      default:
        return period satisfies never;
    }
  };

  const formatAxisTime = (time: DateTime) => {
    switch (period) {
      case ChartPeriod.day:
        return time.toLocaleString(DateTime.TIME_SIMPLE);
      case ChartPeriod.week:
        return time.toLocaleString({
          day: 'numeric',
          month: 'numeric',
          weekday: 'short',
        });
      case ChartPeriod.month:
        return time.toLocaleString({ day: 'numeric', month: 'numeric' });
      case ChartPeriod.year:
        return time.toLocaleString({ month: 'short' });
      case ChartPeriod.lifetime:
        return time.toLocaleString({ year: 'numeric' });
      default:
        return period satisfies never;
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart data={points}>
        <defs>
          <linearGradient id="primaryLine" x1={0} y1={0} x2={0} y2={1}>
            <stop offset="0%" stopColor="#009688" stopOpacity={0.5} />
            <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0} />
          </linearGradient>

          <linearGradient id="primaryBar" x1={0} y1={0} x2={0} y2={1}>
            <stop offset="0%" stopColor="#36EBCA" />
            <stop offset="100%" stopColor="#009688" />
          </linearGradient>

          <linearGradient id="secondaryLine" x1={0} y1={0} x2={0} y2={1}>
            <stop offset="0%" stopColor="#E98841" stopOpacity={0.5} />
            <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0} />
          </linearGradient>

          <linearGradient id="secondaryBar" x1={0} y1={0} x2={0} y2={1}>
            <stop offset="0%" stopColor="#FF9512" />
            <stop offset="100%" stopColor="#CB5513" />
          </linearGradient>
        </defs>

        <CartesianGrid
          vertical={false}
          stroke="#DFDFE8"
          strokeDasharray="2 2"
        />

        <XAxis
          type="category"
          dataKey={({ time }: ChartPoint) =>
            kind === 'power'
              ? time.toSeconds()
              : time.minus(periodConfig.pointGranularity).toSeconds()
          }
          name="Time"
          tickLine={false}
          tickFormatter={(seconds) =>
            typeof seconds === 'number'
              ? formatAxisTime(DateTime.fromSeconds(seconds))
              : ''
          }
          interval="preserveStartEnd"
          minTickGap={24}
          tick={{ fill: '#828D9A', fontSize: 10, fontWeight: 400 }}
          tickMargin={4}
          axisLine={{ stroke: '#DFDFE8' }}
        />

        <YAxis
          type="number"
          name="Energy"
          axisLine={false}
          tickLine={false}
          tickFormatter={kind === 'power' ? formatPower : formatEnergy}
          interval="preserveEnd"
          width={80}
          minTickGap={8}
          tick={{ fill: '#828D9A', fontSize: 10, fontWeight: 400 }}
          tickMargin={8}
        />

        <Tooltip<number, Items>
          cursor={{ stroke: '#828D9A' }}
          filterNull={false}
          content={({ payload = [], label: seconds }) => (
            <PointTooltip>
              <TooltipLabel>
                {typeof seconds === 'number' &&
                  formatTooltipTime(DateTime.fromSeconds(seconds))}
              </TooltipLabel>

              {payload.map((item) => (
                <TooltipValue key={item.name}>
                  <CircleIcon
                    size={8}
                    color={item.color}
                    fill={(item as any).fill}
                  />
                  {((item.name === Items.production ||
                    item.name === Items.consumption) &&
                    formatEnergy(item.value)) ||
                    (item.name === Items.power && formatPower(item.value)) ||
                    'No data'}
                </TooltipValue>
              ))}
            </PointTooltip>
          )}
        />

        {kind === 'energy' && production && (
          <Bar
            dataKey={({ production }: EnergyPoint) => production}
            fill="url(#primaryBar)"
            fillOpacity={1}
            isAnimationActive={false}
            barSize={24}
            name={Items.production}
          />
        )}

        {kind === 'energy' && consumption && (
          <Bar
            dataKey={({ consumption }: EnergyPoint) => consumption}
            fill="url(#secondaryBar)"
            fillOpacity={1}
            isAnimationActive={false}
            barSize={24}
            name={Items.consumption}
          />
        )}

        {kind === 'power' && (
          <Area
            type="monotone"
            dataKey={({ power }: PowerPoint) => power}
            stroke="#009688"
            strokeWidth={2}
            fill="url(#primaryLine)"
            fillOpacity={1}
            isAnimationActive={false}
            baseValue={0}
            name={Items.power}
          />
        )}

        {children}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
