import React from 'react';
import {
  Cell,
  CreatedAt,
  FileIconThumbnail,
  FileImageThumbnail,
  FileNameCell,
  ProjectBadge,
  Row,
} from './styled';
import { FileIcon } from '../../kit/ui/icons/File';
import { cutFileName, formatFileSize } from './helpers';
import { ButtonVariant, IconButton } from '../../kit/ui/Button';
import { DownloadIcon } from '../../kit/ui/icons/Download';
import { useDownloadDoc } from '../../apiHooks/useDownloadDoc';
import { useDocList } from '../../apiHooks/useDocList';
import { Loader } from '../../kit/ui/Loader';
import { formatDateFromBEToLocal } from '../../utils/dates';

interface Props {
  doc: Exclude<ReturnType<typeof useDocList>['data'], undefined>[number];
}

export const DocRow = ({ doc }: Props) => {
  const { mutateAsync: download, isLoading } = useDownloadDoc();

  return (
    <Row key={doc.id}>
      <Cell width={40}>
        {Boolean(doc.metaData?.thumbnailUrl) && (
          <FileImageThumbnail src={doc.metaData.thumbnailUrl} size={40} />
        )}
        {Boolean(!doc.metaData?.thumbnailUrl) && (
          <FileIconThumbnail size={40}>
            <FileIcon size="24px" color="#9C9CAA" />
          </FileIconThumbnail>
        )}
      </Cell>
      <FileNameCell>
        {cutFileName(doc.name ?? '')}

        <CreatedAt>{formatDateFromBEToLocal(doc.createdAt, 'f')}</CreatedAt>
      </FileNameCell>

      <Cell width={150} onlyDesktop>
        {formatDateFromBEToLocal(doc.createdAt, 'f')}
      </Cell>

      <Cell width={60} align="flex-end" onlyDesktop>
        {doc.metaData?.size ? formatFileSize(doc.metaData?.size) : null}
      </Cell>

      <Cell width={200} onlyDesktop>
        {doc.project && (
          <ProjectBadge title={doc.project.title ?? ''}>
            {doc.project.title}
          </ProjectBadge>
        )}
      </Cell>
      <Cell width={32}>
        <IconButton
          disabled={isLoading}
          variant={ButtonVariant.Flat}
          onClick={() => download(doc.uuid)}
        >
          {!isLoading && <DownloadIcon size="20px" color="#009688" />}
          {isLoading && <Loader size={32} />}
        </IconButton>
      </Cell>
    </Row>
  );
};
