import { useMutation, useQueryClient } from '@tanstack/react-query';
import { documentsApi } from '../api/documents';
import { ReactQueryKeys } from './reactQueryKeys';

type Payload = {
  file: File;
  actionId?: number;
  projectId?: number;
  stageId?: number;
};

export const useUploadFile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ file, actionId, projectId, stageId }: Payload) => {
      const doc = await documentsApi.upload({
        actionId,
        projectId,
        stageId,
        file,
      });

      return doc;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.Docs]);
    },
  });
};
