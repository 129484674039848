import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  height: 100vh;
  background-color: #f7f7fc;

  padding: 0 16px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  max-width: 396px;

  .otpContainer {
    justify-content: center;
  }
`;

export const Logo = styled.div`
  margin: 0 auto;

  img {
    width: 160px;
    height: auto;
  }
`;

export const LogoImage = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 160px;
  height: 120px;
  margin: 0 auto;
`;

export const Form = styled.form`
  background-color: #fff;
  border-radius: 16px;
  padding: 32px;

  .otpInput {
    width: 40px !important;
    height: 40px;
    padding: 0;

    input {
      flex: 1;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .error {
    border-color: #d54855;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const VerificationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FieldLabel = styled.label`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  display: block;
  margin-bottom: 4px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 32px;
`;

export const Asterisk = styled.span`
  color: #d54855;
`;

export const ErrorMessage = styled.div`
  color: #d54855;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #d54855;
  background-color: #feefef;
`;

export const InputSeparator = styled.span`
  width: 18px;
  display: inline-block;
`;

export const Hint = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;

  b {
    font-weight: 500;
  }
`;

export const CompanyName = styled.div`
  color: #828d9a;
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 16px;
`;

export const HelpText = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
`;

export const LinkButton = styled.button`
  color: #009688;
  font-size: 12px;
  font-weight: 500;
  font-family: inherit;
  line-height: 130%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 !important;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const FieldError = styled.div`
  color: #d54855;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
`;
