import React from 'react';
import { List } from './styled';
import { H2 } from '../../kit/ui/Heading';
import placeholderImage from './placeholderImage.png';
import { PlaceholderWithImage } from '../../kit/components/PlaceholderWithImage';
import { useRequests } from '../../apiHooks/useRequests';

import { Request } from '../../kit/components/Request';

export const Requests = () => {
  const { data: requests = [], isLoading } = useRequests();

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <H2>Requests ({requests.length})</H2>

      {requests.length > 0 && (
        <List>
          {requests.map((request) => (
            <Request key={request.id} request={request} />
          ))}
        </List>
      )}

      {requests.length === 0 && (
        <PlaceholderWithImage
          imgSrc={placeholderImage}
          imgMaxWidth="400px"
          primaryText="You have no active requests... yet."
          secondaryText="We look forward to work with you!"
        />
      )}
    </div>
  );
};
