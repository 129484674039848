import React from 'react';

import solarPanelImage from '../../assets/solarPanel.svg';
import { Image, ImageContainer } from './styled';

interface Props {
  imageUrl?: string | null;
  width?: number;
  height?: number;
}

export const ProjectImage = ({ imageUrl, width, height }: Props) => {
  return (
    <ImageContainer width={width} height={height}>
      {!imageUrl && <img src={solarPanelImage} alt="" />}
      {imageUrl && (
        <Image
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        />
      )}
    </ImageContainer>
  );
};
