import React from 'react';
import { Container } from './styled';
import { Projects } from './Projects';
import { Services } from '../Services';
import { useProjects } from '../../apiHooks/useProjects';
import {
  AppointmentType,
  useAppointments,
} from '../../apiHooks/useAppointments';
import placeholdeImage from './placeholderImage.png';
import { PlaceholderWithImage } from '../../kit/components/PlaceholderWithImage';

export const ProjectsAndServices = () => {
  const { data: appointments = [], isLoading: isAppointmentsLoading } =
    useAppointments();
  const { data: projects = [], isLoading: isProjectsLoading } = useProjects();

  const serviceAppointments = appointments.filter(
    (appt) => appt.type === AppointmentType.ServiceVisit,
  );

  if (isAppointmentsLoading || isProjectsLoading) {
    return null;
  }

  if (projects.length === 0 && serviceAppointments.length === 0) {
    return (
      <Container>
        <PlaceholderWithImage
          imgSrc={placeholdeImage}
          imgMaxWidth="400px"
          primaryText="You have no active projects or services... yet"
          secondaryText="We look forward to work with you!"
        />
      </Container>
    );
  }

  return (
    <Container>
      <Projects />
      <Services />
    </Container>
  );
};
