import { useQuery } from '@tanstack/react-query';
import { ReactQueryKeys } from './reactQueryKeys';
import { LineString } from 'geojson';
import ky from 'ky';

export const useMapboxDirections = (points: { lat: number; lon: number }[]) =>
  useQuery({
    queryKey: [ReactQueryKeys.MapboxDirections, points],
    queryFn: async () => {
      const response = await ky
        .get(
          `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${points
            .map(({ lat, lon }) => `${lon},${lat}`)
            .join(';')}`,
          {
            searchParams: {
              geometries: 'geojson',
              overview: 'full',
              access_token:
                'pk.eyJ1IjoidHV2c2hpbnR1ciIsImEiOiJja2h6aTJ4YTkwcXAxMnFvZDlzc2YwaTkxIn0.rvLp0ow7j0SzjXiS0fimqQ',
            },
          },
        )
        .json<Directions>();

      if (response.code !== 'Ok') {
        throw new Error(
          `Error "${response.code}" getting directions: "${response.message}"`,
        );
      }

      return response.routes[0];
    },
    initialData: emptyRoute,
    keepPreviousData: true,
    enabled: points.length >= 2,
    staleTime: Infinity,
    cacheTime: 60 * 60 * 1000,
    initialDataUpdatedAt: 0,
  });

type Directions = {
  // https://docs.mapbox.com/api/navigation/directions/#directions-api-errors
  code: 'Ok' | string;
  message?: string;
  routes: Route[];
};

// https://docs.mapbox.com/api/navigation/directions#route-object
export type Route = {
  geometry: LineString;
};

const emptyRoute: Route = {
  geometry: { type: 'LineString', coordinates: [] },
};
