import styled from 'styled-components';

export const Container = styled.div<{ size: number; hasImage: boolean }>`
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  user-select: none;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: 1px solid ${({ hasImage }) => (hasImage ? 'transparent' : '#DFDFE8')};
`;

export const Image = styled.img`
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
`;
