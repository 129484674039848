import React from 'react';

export enum InputSize {
  Default = 'default',
  Small = 'small',
}

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: InputSize;

  leftAddon?: React.ReactNode;
  rightAddon?: React.ReactNode;
  minWidth?: string;
  inputContainerRef?: React.RefObject<HTMLDivElement>;
}
