class MemoryStorage implements Storage {
  private map = new Map();

  get length() {
    return this.map.size;
  }

  public key(idx: number) {
    return Array.from(this.map.keys())[idx];
  }

  public getItem(key: string) {
    return this.map.get(key);
  }

  public setItem(key: string, value: string) {
    return this.map.set(key, value);
  }

  public removeItem(key: string) {
    return this.map.delete(key);
  }

  public clear() {
    return this.map.clear();
  }
}

const TEST_KEY = '__test';

function isLocalStorageSupported() {
  try {
    window.localStorage.setItem(TEST_KEY, '1');
    window.localStorage.removeItem(TEST_KEY);

    return true;
  } catch (e) {
    return false;
  }
}

const storage = isLocalStorageSupported()
  ? window.localStorage
  : new MemoryStorage();

export { storage };
