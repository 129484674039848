import { useQuery } from '@tanstack/react-query';
import { companyApi } from '../api/company';
import { ReactQueryKeys } from './reactQueryKeys';

export const useCompany = (slug: string) => {
  return useQuery({
    queryKey: [ReactQueryKeys.Company, slug],
    queryFn: async () => {
      return companyApi.info(slug);
    },
    retry: false,
  });
};
