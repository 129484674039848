import styled from 'styled-components';

const progressStyles = `
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 4px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;

  width: 100%;
  margin: 16px 0;

  progress {
    ${progressStyles};
  }
  progress::-webkit-progress-bar {
    ${progressStyles};
  }
  progress::-webkit-progress-value {
    ${progressStyles};
    background: linear-gradient(90deg, #009688 0%, #19c9b9 100%);
  }
  progress::-moz-progress-bar {
    ${progressStyles};
  }

  progress[value]::-webkit-progress-bar {
    background-color: #ededef;
    background-image: #009688;
    border-radius: 4px;
  }
`;
