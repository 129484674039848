import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
`;

export const MobileNav = styled.nav`
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  position: sticky;
  top: 0;
  z-index: 2;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const MobileChatButton = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1d1d35;
  font-size: 14px;
  font-weight: 500;

  &,
  &:visited,
  &:active,
  &:focus,
  &:hover,
  &:link {
    text-decoration: none;
  }
`;

export const LogoImage = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 72px;
  height: 44px;
  flex-shrink: 0;
`;

export const LogoName = styled.div`
  font-size: 20px;
  font-weight: bold;
  max-width: 100px;
`;

export const NavCenter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    flex: 1;
  }

  @media (min-width: 992px) {
    gap: 40px;
  }

  @media (min-width: 1252px) {
    gap: 80px;
  }
`;

export const NavLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
`;

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  ${NavLeft} {
    ${LogoImage},
    ${LogoName} {
      display: none;
    }
  }

  @media (min-width: 768px) {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 32px;
    padding-right: 0;

    ${NavLeft} {
      ${LogoImage},
      ${LogoName} {
        display: block;
      }
    }
  }

  a,
  a:visited,
  a:active,
  a:focus,
  a:hover,
  a:link {
    text-decoration: none;
  }
`;

export const CloseMenu = styled.div`
  cursor: pointer;
  color: #9c9caa;
`;

export const MobileMenuHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.span<{
  isActive?: boolean;
  isDisabled?: boolean;
}>`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  color: ${({ isActive, isDisabled }) => {
    if (isDisabled) {
      return '#828D9A';
    }
    return isActive ? '#009688' : '#1D1D35';
  }};
  padding: 4px;
`;

export const UserMenuItem = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
`;

export const UserDropdownMenu = styled.div``;

export const MobileMenuItem = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
  gap: 8px;
  padding: 4px;
`;

export const MobileMenuButton = styled(MobileMenuItem)`
  color: #009688;
  justify-content: flex-start;
  text-transform: uppercase;
  letter-spacing: 0.7px;
`;

export const AvatarAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UserDropdownMenuItem = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 0.1s;

  &:hover {
    background-color: #f7f7fc;
  }
`;

export const AvatarPlaceholder = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: #e19201;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

export const Content = styled.div`
  display: flex;
  padding: 0 16px 16px;

  gap: 24px;

  > * {
    &:first-child {
      flex: 1;

      overflow-x: hidden;
    }

    flex-shrink: 0;
  }

  @media (min-width: 768px) {
    padding: 0 32px 24px 32px;
  }
`;

export const ChatContainer = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    flex: 0 0 300px;
    min-width: 300px;
    width: 300px;

    max-height: calc(100vh - 24px - 24px - 42px - 24px - 55px);
    height: 100%;
  }

  @media (min-width: 1220px) {
    flex: 0 0 443px;
    min-width: 443px;
    width: 443px;
  }
`;

export const StickyChat = styled.div`
  position: fixed;
  top: 145px;
  right: 32px;
  bottom: 24px;
  width: 300px;
  z-index: 1;
  height: calc(100vh - 24px - 24px - 42px - 24px - 55px);
  border-radius: 24px;
  border: 1px solid #dfdfe8;

  @media (min-width: 1220px) {
    width: 443px;
  }
`;

export const MobileSeparator = styled.div`
  height: 1px;
  background-color: #dfdfe8;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const MenuRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding-right: 32px;
    width: 300px;
    min-width: 300px;
    flex: 0 0 300px;
    justify-content: flex-end;
  }

  @media (min-width: 1220px) {
    flex: 0 0 calc(443px + 32px);
    min-width: calc(443px + 32px);
    width: calc(443px + 32px);
  }

  @media (min-width: 1356px) {
    gap: 36px;
  }

  .desktop {
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }
  }
`;
