import React from 'react';
import { Svg } from './styled';

interface Props {
  /**
   * Progress value from 0 to 100
   */
  progress: number;
  color: string;
  size: number;
  strokeWidth?: number;
}

export const ProgressCircle = ({
  progress,
  color,
  size,
  strokeWidth: providedStrokeWidth,
}: Props) => {
  const circleSize = size / 2;

  const strokeWidth = providedStrokeWidth ?? Math.floor(size / 8);
  const radius = size / 2 - strokeWidth / 2 - 2;

  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle
        cx={circleSize}
        cy={circleSize}
        r={radius}
        fill="none"
        stroke="#DFDFE8"
        strokeWidth={strokeWidth}
      />
      {progress > 0 && (
        <circle
          cx={circleSize}
          cy={circleSize}
          strokeDasharray={100}
          strokeDashoffset={100 - progress}
          r={radius}
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
          pathLength="100"
        />
      )}
    </Svg>
  );
};
