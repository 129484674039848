import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { FormField } from '../FormField';
import { FormControl, FormInputPropsToOmit } from '../types';
import { useControllerWithValidation } from '../useControllerWithValidation';
import { RichEditor } from '../../RichEditor';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<React.ComponentProps<typeof RichEditor>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {}

export const RichEditorField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  label,
  description,
  name,
  control,
  clearOnUnmount,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { value, ...controlProps },
    fieldState: { error },
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  return (
    <FormField
      name={name}
      label={label}
      error={error?.message}
      description={description}
    >
      <RichEditor
        {...controlProps}
        {...inputProps}
        value={value ?? ''}
        data-test-id={`field-${controlProps.name}`}
      />
    </FormField>
  );
};
