import React, {
  Fragment,
  useCallback,
  useState,
  createContext,
  createElement,
  useMemo,
} from 'react';

import { Modal, ModalProps } from './Modal';
import { ModalsRoot } from './ModalsRoot';

type ComponentWithOnCloseProp<T> = React.ElementType<{
  onClose: (value: T) => void;
}>;

const ModalsContext = createContext<{
  openModal: <T>(
    Component: ComponentWithOnCloseProp<T>,
    modalProps?: Omit<ModalProps, 'isOpen' | 'onClose'>,
  ) => Promise<T>;
  closeModals: () => void;
} | null>(null);

const getNextFreeKey = (keys: string[]) => {
  const lastKey = keys[keys.length - 1];

  return (parseInt(lastKey || '0', 10) + 1).toString();
};

const ModalsProvider = ({ children }: { children: React.ReactNode }) => {
  const [dialogs, setModals] = useState<{ [key: string]: React.ReactNode }>({});

  const openModal = useCallback(
    <T,>(
      Component: ComponentWithOnCloseProp<T>,
      modalProps?: Omit<ModalProps, 'isOpen' | 'onClose'>,
    ): Promise<T> =>
      new Promise((resolve) => {
        const handleClose: (key: string, value?: any) => void = (
          key,
          value,
        ) => {
          setModals((prevModals) => {
            const newModals = { ...prevModals };

            delete newModals[key];

            return newModals;
          });

          resolve(value ?? undefined);
        };

        setModals((prevModals) => {
          const key = getNextFreeKey(Object.keys(prevModals));

          return {
            ...prevModals,
            [key]: (
              <Modal
                key={key}
                isOpen
                onClose={() => handleClose(key)}
                {...modalProps}
              >
                {createElement(Component, {
                  onClose: (value) => handleClose(key, value),
                })}
              </Modal>
            ),
          };
        });
      }),
    [],
  );

  const closeModals = useCallback(() => {
    setModals({});
  }, []);

  const contextValue = useMemo(
    () => ({
      openModal,
      closeModals,
    }),
    [openModal, closeModals],
  );

  return (
    <>
      <ModalsContext.Provider value={contextValue}>
        {children}
        {Object.values(dialogs)}
      </ModalsContext.Provider>
      <ModalsRoot />
    </>
  );
};

export { ModalsProvider, ModalsContext };
