import React, { useEffect, useMemo, useState } from 'react';

import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
  FloatingOverlay,
} from '@floating-ui/react';

import {
  SelectButton,
  Menu,
  Placeholder,
  SelectValue,
  Option,
  MobileMenuHeader,
  SelectGlobalStyle,
  MobileMenuFooter,
  SearchContainer,
} from './styled';
import { ChevronUpIcon } from '../icons/ChevronUp';
import { ChevronDownIcon } from '../icons/ChevronDown';
import { CheckIcon } from '../icons/Check';
import { XIcon } from '../icons/X';
import { Button, ButtonSize, ButtonVariant } from '../Button';
import { Input } from '../Input';
import { SearchIcon } from '../icons/Search';
import { elementOrParentHasClass } from '../../../utils/elementOrParentHasClass';
import { SelectVariant } from './types';

const identity = <T, V>(option: T) => option as unknown as V;

interface Props<TOption, TValue extends string | number> {
  value: TValue | null;
  options: TOption[];
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  onChange: (value: TValue | null) => void;
  autoFocus?: boolean;
  variant?: SelectVariant;
  getOptionValue?: (option: TOption) => TValue;
  getOptionLabel?: (option: TOption) => string;
  renderOption?: (option: TOption) => React.ReactNode;
}

export const Select = <TOption, TValue extends string | number>({
  value,
  onChange,
  options,
  placeholder = 'Select',
  isDisabled = false,
  isClearable = true,
  autoFocus,
  variant = SelectVariant.Select,
  getOptionValue = identity,
  getOptionLabel = identity,
  renderOption = getOptionLabel,
}: Props<TOption, TValue>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const isMobile = window.innerWidth <= 767;

  useEffect(() => {
    if (!value) {
      setSelectedIndex(null);
    } else {
      const index = options.findIndex(
        (option) => getOptionValue(option) === value,
      );

      setSelectedIndex(index === -1 ? null : index);
    }
  }, [value, options, getOptionValue]);

  const filteredOptions = useMemo(() => {
    if (searchValue) {
      return options.filter((option) =>
        getOptionLabel(option)
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
      );
    }

    return options;
  }, [options, searchValue, getOptionLabel]);

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: (open, event, reason) => {
      if (
        event &&
        elementOrParentHasClass(event.target as HTMLElement, 'clear-icon')
      ) {
        return;
      }
      if (reason === 'outside-press') {
        if (isMobile) {
          return;
        }
      }
      setIsOpen(open);
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(3),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = React.useRef<Array<HTMLElement | null>>([]);
  const listContentRef = React.useRef(options.map(getOptionLabel));
  const isTypingRef = React.useRef(false);
  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    // This is a large list, allow looping.
    loop: true,
  });
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    },
    enabled: !isMobile && !isSearchFocused,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, role, listNav, typeahead, click],
  );

  const handleSelect = (value: TOption, index: number) => {
    setSelectedIndex(index);
    if (!isMobile) {
      setIsOpen(false);
    }
    onChange(getOptionValue(value));
  };

  const handleClear = () => {
    onChange(null);
  };

  const handleClose = () => {
    setIsOpen(false);
    setActiveIndex(null);
  };

  const selectedItemLabel =
    selectedIndex !== null ? getOptionLabel(options[selectedIndex]) : undefined;

  return (
    <>
      <SelectGlobalStyle />
      <SelectButton
        ref={refs.setReference}
        type="button"
        autoFocus={autoFocus}
        isDisabled={isDisabled}
        variant={variant}
        {...getReferenceProps()}
      >
        {selectedItemLabel ? (
          <SelectValue>{selectedItemLabel}</SelectValue>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}
        {value && isClearable && (
          <XIcon className="clear-icon" size="16px" onClick={handleClear} />
        )}
        {isOpen && <ChevronUpIcon size="16px" />}
        {!isOpen && <ChevronDownIcon size="16px" />}
      </SelectButton>

      {isOpen && (
        <FloatingPortal>
          <FloatingOverlay className="backdrop">
            <FloatingFocusManager
              context={context}
              initialFocus={-1}
              modal={false}
              visuallyHiddenDismiss
            >
              <Menu
                ref={refs.setFloating}
                style={{ ...floatingStyles }}
                {...getFloatingProps()}
              >
                <MobileMenuHeader className="mobile">
                  {placeholder}
                </MobileMenuHeader>
                {options.length > 5 && (
                  <SearchContainer>
                    <Input
                      leftAddon={<SearchIcon color="#9C9CAA" size="16px" />}
                      value={searchValue}
                      onFocus={() => setIsSearchFocused(true)}
                      onBlur={() => setIsSearchFocused(false)}
                      onChange={(e) => {
                        setActiveIndex(null);
                        setSearchValue(e.target.value);
                      }}
                      placeholder="Search..."
                    />
                  </SearchContainer>
                )}
                {searchValue && filteredOptions.length === 0 && (
                  <Option isActive={false} isSelected={false}>
                    No results found
                  </Option>
                )}
                {filteredOptions.map((option, i) => (
                  <Option
                    key={getOptionValue(option).toString()}
                    ref={(node) => {
                      listRef.current[i] = node;
                    }}
                    role="option"
                    tabIndex={i === activeIndex ? 0 : -1}
                    isActive={i === activeIndex}
                    isSelected={value === getOptionValue(option)}
                    aria-selected={value === getOptionValue(option)}
                    {...getItemProps({
                      // Handle pointer select.
                      onClick() {
                        handleSelect(option, i);
                      },
                      // Handle keyboard select.
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(option, i);
                        }

                        if (event.key === ' ' && !isTypingRef.current) {
                          event.preventDefault();
                          handleSelect(option, i);
                        }
                      },
                    })}
                  >
                    {renderOption(option)}
                    <span
                      aria-hidden
                      style={{
                        position: 'absolute',
                        right: 10,
                      }}
                    >
                      {getOptionValue(option) === value && (
                        <CheckIcon size="16px" color="#009A47" />
                      )}
                    </span>
                  </Option>
                ))}

                <MobileMenuFooter className="mobile">
                  <Button
                    onClick={handleClose}
                    size={ButtonSize.Large}
                    variant={ButtonVariant.Primary}
                    isBlock
                  >
                    Select
                  </Button>
                </MobileMenuFooter>
              </Menu>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  );
};
