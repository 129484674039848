import styled from 'styled-components';

export const Body = styled.div`
  background-color: #fff;
  padding: 16px;

  gap: 24px;
  display: flex;
  flex-direction: column;

  > * {
    &:last-child {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  @media (min-width: 768px) {
    padding: 0 24px 24px;

    flex-direction: row;
    border-radius: 0 0 16px 16px;

    > *:first-child {
      flex: 3;
      min-width: 0;
    }
    > *:last-child {
      flex: 2;
      min-width: 300px;
    }
  }

  @media (min-width: 1024px) {
    width: 900px;
  }

  img {
    width: auto;
    max-height: 180px;
    max-width: 100%;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
  order: 1;
  > * {
    flex-shrink: 0;
  }

  @media (min-width: 768px) {
    order: 0;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 16px;
`;

export const FieldsRow = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    flex: 1;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 16px;
  }
`;

export const DisabledInputWithCopy = styled.div`
  height: 40px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f7fc;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  font-size: 14px;

  > div:first-child {
    max-width: calc(100% - 32px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    color: #9c9caa;
    transition: color 0.15s ease-in-out;
  }

  &:hover {
    svg {
      color: #000;
    }
  }
`;

export const ShareButtons = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const ShareButton = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: border-color 0.15s ease-in-out;

  &:hover {
    border-color: #009688;
  }
`;

export const FieldLabel = styled.label`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
  display: flex;
  align-items: center;
  gap: 4px;
`;
