import React from 'react';
import {
  Channel,
  ChannelList,
  Container,
  ImgContainer,
  Stars,
  Title,
} from './styled';
import { StarIcon } from '../../../kit/ui/icons/Star';
import { useCompany } from '../../../apiHooks/useCompany';
import { useParams } from 'react-router-dom';
import { ReviewChannelType } from '../../../api/company';
import googleLogo from './assets/google.png';
import bbbLogo from './assets/bbb.png';
import yelpLogo from './assets/yelp.png';

const CHANNEL_CONFIG: Record<
  ReviewChannelType,
  { title: string; logo: string }
> = {
  [ReviewChannelType.Google]: {
    title: 'Google',
    logo: googleLogo,
  },
  [ReviewChannelType.BBB]: {
    title: 'Better Business Bureau',
    logo: bbbLogo,
  },
  [ReviewChannelType.Yelp]: {
    title: 'Yelp',
    logo: yelpLogo,
  },
};

export const Reviews = () => {
  const { workspaceSlug } = useParams();

  const { data: company } = useCompany(workspaceSlug ?? '');

  const channels =
    company?.settings?.portalReviews?.channels?.filter(
      (channel) => channel.url,
    ) ?? [];

  const isEnabled =
    company?.settings?.portalReviews?.enabled && channels.length > 0;

  if (!isEnabled) {
    return null;
  }

  return (
    <Container>
      <Title>
        Leave us a review
        <Stars>
          {Array.from({ length: 5 }).map((_, index) => (
            <StarIcon key={index} size="16px" color="#FFBD13" fill="#FFBD13" />
          ))}
        </Stars>
      </Title>

      <ChannelList>
        {channels.map((channel) => (
          <a
            href={channel.url}
            target="_blank"
            rel="noreferrer"
            key={channel.type}
          >
            <Channel>
              <ImgContainer>
                <img
                  src={CHANNEL_CONFIG[channel.type].logo}
                  alt={channel.type}
                />
              </ImgContainer>
              <div>Click here to leave us a review on {channel.type}!</div>
            </Channel>
          </a>
        ))}
      </ChannelList>
    </Container>
  );
};
