import styled from 'styled-components';

export const ModalHeader = styled.div<{ isMobileFullScreen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #fff;
  border-radius: ${({ isMobileFullScreen }) =>
    isMobileFullScreen ? '0' : '16px 16px 0px 0px'};

  @media (min-width: 768px) {
    border-radius: 16px 16px 0 0;

    padding: 16px 24px;
  }
`;

export const IconButton = styled.div`
  padding: 4px;
  cursor: pointer;
  color: #9c9caa;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #000;
  }
`;

export const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  word-break: break-word;
`;

export const ModalBodyContainer = styled.div`
  overflow-y: auto;
`;

export const ModalBody = styled.div<{ width?: string }>`
  background-color: #f7f7fc;
  padding: 16px;
  width: ${({ width }) => width || 'auto'};
  max-width: ${({ width }) => (width ? '100%' : 'auto')};

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const ModalFooter = styled.div`
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 0 0 16px 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;

  @media (max-width: 767px) {
    border-radius: 0;
  }
`;

export const ModalContainer = styled.div<{
  maxWidth?: string;
  isMobileFullScreen?: boolean;
}>`
  min-width: 500px;
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  max-height: 90vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    min-width: auto;
    width: 100%;
    max-width: 100%;
    max-height: ${({ isMobileFullScreen }) =>
      isMobileFullScreen ? '100vh' : 'calc(100% - 60px)'};
    border-radius: ${({ isMobileFullScreen }) =>
      isMobileFullScreen ? '0' : '16px 16px 0px 0px'};
  }
`;

export const ConfirmModalBody = styled.div`
  padding: 16px 24px;
  background-color: #f7f7fc;
  text-align: center;
  white-space: pre-line;
`;

export const ConfirmButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  background-color: #fff;
  padding: 16px 24px;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  display: flex;

  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    align-items: flex-end;
  }
`;
