import React from 'react';
import { AlertType } from './types';
import { Container, Title, Description } from './styled';
import { ALERT_ICONS } from './const';

interface Props {
  title: string;
  description?: string;
  type: AlertType;
}

export const Alert = ({ title, description, type }: Props) => {
  return (
    <Container type={type}>
      <Title>
        {ALERT_ICONS[type]}
        {title}
      </Title>

      {Boolean(description) && <Description>{description}</Description>}
    </Container>
  );
};
