import React from 'react';
import { Container, Image } from './styled';

interface Props {
  size?: number;
  src?: string | null;
  letters: string;
}

export const Avatar = ({ size = 32, src, letters }: Props) => {
  return (
    <Container hasImage={Boolean(src)} size={size}>
      {src && <Image src={src} />}
      {!src && letters}
    </Container>
  );
};
