import { graphql } from '../gql';
import { DateTime } from 'luxon';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryKeys } from './reactQueryKeys';
import { graphqlRequest } from '../api/graphqlRequest';

const query = graphql(/* GraphQL */ `
  query WorkerLocation($taskId: Int!, $after: Datetime!, $before: Datetime!) {
    geolocations(
      filter: {
        taskId: { equalTo: $taskId }
        task: { status: { equalTo: ON_THE_WAY } }
        timestamp: { greaterThanOrEqualTo: $after, lessThanOrEqualTo: $before }
      }
      orderBy: [ID_DESC]
      first: 1
    ) {
      id
      latitude
      longitude
    }

    geoEstimations(
      filter: {
        taskId: { equalTo: $taskId }
        task: { status: { equalTo: ON_THE_WAY } }
      }
      orderBy: [CREATED_AT_DESC]
      first: 1
    ) {
      duration
      createdAt
    }
  }
`);

export const useWorkerLocation = (taskId?: number) => {
  const now = DateTime.now();

  return useQuery({
    queryKey: [ReactQueryKeys.WorkerLocation, taskId],
    queryFn: async () => {
      const data = await graphqlRequest(query, {
        taskId,
        after: now.startOf('day'),
        before: now.endOf('day'),
      });

      return {
        location: data.geolocations?.[0],
        estimate: data.geoEstimations?.[0],
      };
    },
    keepPreviousData: true,
    enabled: !!taskId,
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  });
};
