import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  border-radius: 8px;
  border: 1px solid #dfdfe8;
  background-color: #f7f7fc;
  overflow: hidden;

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  input:checked + label {
    background-color: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 74, 0.12);
    font-weight: 500;
    border-radius: 6px;
  }
`;

export const Label = styled.label`
  flex: 1;
  color: #1d1d35;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  padding: 0px 16px;
  transition:
    font-weight 0.1s ease-in-out,
    background-color 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out;
  height: 32px;
  line-height: 32px;
  margin: 1px;
  &:hover {
    cursor: pointer;
  }

  &:first-of-type {
    border-radius: 6px 0 0 6px;
  }

  &:last-of-type {
    border-radius: 0 6px 6px 0;
  }
`;
