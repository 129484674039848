import React, { forwardRef, useEffect, useRef, useState } from 'react';

import {
  useFloating,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
  useId,
  FloatingOverlay,
} from '@floating-ui/react';

import {
  Menu,
  MobileMenuHeader,
  Option,
  SearchContainer,
  SelectGlobalStyle,
  SelectInput,
} from './styled';
import { XIcon } from '../icons/X';
import { ChevronUpIcon } from '../icons/ChevronUp';
import { ChevronDownIcon } from '../icons/ChevronDown';
import { Input } from '../Input';
import { SearchIcon } from '../icons/Search';

interface ItemProps {
  children: React.ReactNode;
  isActive: boolean;
  isSelected: boolean;
}

const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ children, isSelected, isActive, ...rest }, ref) => {
    const id = useId();

    return (
      <Option
        {...rest}
        ref={ref}
        role="option"
        id={id}
        aria-selected={isSelected}
        isActive={isActive}
        isSelected={isSelected}
      >
        {children}
      </Option>
    );
  },
);

interface Props {
  value: string | null;
  options: string[];
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  onChange: (value: string | null) => void;
  autoFocus?: boolean;
  renderOption?: (option: any) => React.ReactNode;
  filterOptions?: (options: string[], inputValue: string) => string[];
  onInputChange?: (inputValue: string) => void;
  getSelectedValue?: (option: any) => string;
}

const identity = <T,>(value: T) => value;

export const Autocomplete = ({
  value,
  getSelectedValue = identity,
  filterOptions,
  onInputChange,
  renderOption,
  onChange,
  options,
  placeholder = 'Type to search...',
  isDisabled = false,
  isClearable = true,
  autoFocus,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const isMobile = window.innerWidth <= 767;

  const isInputFocusedRef = useRef(false);

  const listRef = useRef<Array<HTMLElement | null>>([]);

  useEffect(() => {
    if (!value) {
      setInputValue('');
    } else {
      setInputValue(getSelectedValue(value));
    }
  }, [value, getSelectedValue]);

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav],
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    setIsOpen(true);
    setActiveIndex(0);

    onInputChange?.(value);
  };

  let filteredOptions = filterOptions
    ? filterOptions(options, inputValue)
    : options.filter((item) =>
        item.toLowerCase().startsWith(inputValue.toLowerCase()),
      );

  return (
    <>
      <SelectGlobalStyle />
      <SelectInput
        autoFocus={autoFocus}
        disabled={isDisabled}
        className="autocomplete-input"
        rightAddon={
          <>
            {value && isClearable && (
              <XIcon size="16px" onClick={() => onChange(null)} />
            )}

            {isOpen && (
              <ChevronUpIcon size="16px" onClick={() => setIsOpen(false)} />
            )}
            {!isOpen && (
              <ChevronDownIcon size="16px" onClick={() => setIsOpen(true)} />
            )}
          </>
        }
        inputContainerRef={refs.setReference as any}
        {...getReferenceProps({
          //ref: refs.setReference,
          onChange: handleInputChange,
          value: isMobile ? getSelectedValue(value ?? '') : inputValue,
          placeholder,
          'aria-autocomplete': 'list',
          onFocus(e) {
            isInputFocusedRef.current = true;
            if (e.relatedTarget) {
              setIsOpen(true);
            }
          },
          onBlur(e) {
            isInputFocusedRef.current = false;
            if (e.relatedTarget !== refs.floating.current) {
              setInputValue(value ? getSelectedValue(value) : '');
            }
          },
          onClick() {
            if (!isInputFocusedRef.current) {
              setIsOpen(true);
            }
          },
          onKeyDown(event) {
            if (isOpen && event.key === 'Enter') {
              event.preventDefault();
              event.stopPropagation();
            }
            if (
              event.key === 'Enter' &&
              activeIndex != null &&
              filteredOptions[activeIndex]
            ) {
              onChange(filteredOptions[activeIndex]);
              setActiveIndex(null);
              setIsOpen(false);
            }
          },
        })}
      />

      {isOpen && (
        <FloatingPortal>
          <FloatingOverlay className="backdrop">
            <FloatingFocusManager
              context={context}
              initialFocus={-1}
              visuallyHiddenDismiss
            >
              <Menu
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
              >
                <MobileMenuHeader className="mobile">
                  Choose value
                </MobileMenuHeader>
                {isMobile && (
                  <SearchContainer className="mobile">
                    <Input
                      autoFocus
                      leftAddon={<SearchIcon color="#9C9CAA" size="16px" />}
                      value={inputValue}
                      onFocus={() => {
                        isInputFocusedRef.current = true;
                      }}
                      onBlur={() => {
                        isInputFocusedRef.current = false;
                      }}
                      onChange={handleInputChange}
                      placeholder="Search..."
                      rightAddon={
                        inputValue && (
                          <XIcon
                            color="#9C9CAA"
                            size="16px"
                            onClick={() => setInputValue('')}
                          />
                        )
                      }
                    />
                  </SearchContainer>
                )}
                {filteredOptions.map((option, i) => (
                  <Item
                    isActive={i === activeIndex}
                    isSelected={value === option}
                    {...getItemProps({
                      key: option,
                      ref(node) {
                        listRef.current[i] = node;
                      },
                      onClick() {
                        setIsOpen(false);
                        onChange(option);
                      },
                    })}
                  >
                    {renderOption ? renderOption(option) : option}
                  </Item>
                ))}

                {filteredOptions.length === 0 && (
                  <Item isSelected={false} isActive={false}>
                    {inputValue.length > 0
                      ? 'No results found'
                      : 'Type to search...'}
                  </Item>
                )}
              </Menu>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  );
};
