import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { sessionService } from './services/SessionService';
import { useCompany } from './apiHooks/useCompany';
import { NotFound } from './features/NotFound';

export const Guard = () => {
  const { workspaceSlug } = useParams();

  const { isError, isLoading } = useCompany(workspaceSlug ?? '');

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isError) {
      return;
    }

    if (!sessionService.isAuthorized(workspaceSlug!)) {
      if (
        window.location.pathname === `/${workspaceSlug}/auth/login` ||
        window.location.pathname === `/${workspaceSlug}/new`
      ) {
        return;
      }

      navigate(`/${workspaceSlug}/auth/login`, { replace: true });
    }
  }, [isLoading, navigate, workspaceSlug, isError]);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <NotFound />;
  }

  return <Outlet />;
};
