import { useQuery } from '@tanstack/react-query';
import { graphql } from '../gql';
import { ReactQueryKeys } from './reactQueryKeys';
import { graphqlRequest } from '../api/graphqlRequest';
import { CoperniqUser, TaskStatus } from '../gql/graphql';

const query = graphql(/* GraphQL */ `
  query Appointemnts {
    reminders(filter: { isCompleted: { equalTo: false } }) {
      id
      createdAt
      dueDate
      assignee {
        id
        firstName
        lastName
        avatarUrl
        email
        phone
      }
      project {
        type
        address
      }
    }

    taskVisits(filter: { isCompleted: { equalTo: false } }) {
      id
      createdAt
      startDate
      endDate
      task {
        id
        title
        address
        status
        project {
          type
          address
          geoLocation
          accountType
        }
        assignee {
          id
          firstName
          lastName
          avatarUrl
          email
          phone
        }
      }
    }
  }
`);

export enum AppointmentType {
  RequestAppointment = 'requestAppointment',
  WorkOrderVisit = 'workOrderVisit',
  ServiceVisit = 'serviceVisit',
}

export type Appointment = {
  id: string;
  createdAt: Date;
  startDate: Date;
  endDate?: Date;
  assignee: Pick<
    CoperniqUser,
    'id' | 'firstName' | 'lastName' | 'avatarUrl' | 'email' | 'phone'
  > | null;
  address: string;
  geoLocation?: [latitude: number, longitude: number];
  type: AppointmentType;
  title?: string;
  taskId?: number;
  projectAccountType?: string | null;
  taskStatus?: TaskStatus;
};

export const useAppointments = () => {
  return useQuery({
    queryKey: [ReactQueryKeys.Appointments],
    queryFn: async () => {
      const { reminders, taskVisits } = await graphqlRequest(query);

      const appointments: Appointment[] =
        reminders
          ?.filter((reminder) => reminder.project?.type === 'DEAL')
          .map((reminder) => ({
            id: `reminder_${reminder.id}`,
            createdAt: new Date(reminder.createdAt),
            startDate: new Date(reminder.dueDate),
            assignee: reminder.assignee,
            address: reminder.project?.address?.[0] ?? '',
            type: AppointmentType.RequestAppointment,
          })) ?? [];

      const taskAppointments: Appointment[] =
        taskVisits?.map((taskVisit) => ({
          id: `task_${taskVisit.id}`,
          createdAt: new Date(taskVisit.createdAt),
          startDate: new Date(taskVisit.startDate),
          endDate: new Date(taskVisit.endDate),
          assignee: taskVisit.task?.assignee ?? null,
          title: taskVisit.task?.title,
          address:
            taskVisit.task?.address ||
            taskVisit.task?.project?.address?.[0] ||
            '',
          geoLocation: taskVisit.task?.project?.geoLocation?.[0]
            ?.split(',')
            ?.map(parseFloat) as [number, number],
          type:
            taskVisit.task?.project?.type === 'ACCOUNT'
              ? AppointmentType.ServiceVisit
              : AppointmentType.WorkOrderVisit,
          taskId: taskVisit.task?.id,
          projectAccountType: taskVisit.task?.project?.accountType,
          taskStatus: taskVisit.task?.status,
        })) ?? [];

      const allAppointments = [...appointments, ...taskAppointments];
      allAppointments.sort(
        (a, b) => a.startDate.getTime() - b.startDate.getTime(),
      );

      return allAppointments.filter((appointment) => {
        const now = new Date();
        const today = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
        );
        return appointment.startDate >= today;
      });
    },
  });
};
