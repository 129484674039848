import 'mapbox-gl/dist/mapbox-gl.css';
import React, { PropsWithChildren } from 'react';
import { Map as MapboxMap, MapProps, MapRef } from 'react-map-gl';

type Props = PropsWithChildren<MapProps>;

export const Map = React.forwardRef<MapRef, Props>(
  ({ children, ...props }, ref) => (
    <MapboxMap
      ref={ref}
      mapboxAccessToken="pk.eyJ1IjoidHV2c2hpbnR1ciIsImEiOiJja2h6aTJ4YTkwcXAxMnFvZDlzc2YwaTkxIn0.rvLp0ow7j0SzjXiS0fimqQ"
      reuseMaps
      mapStyle="mapbox://styles/mapbox/light-v10"
      attributionControl={false}
      {...props}
    >
      {children}
    </MapboxMap>
  ),
);
