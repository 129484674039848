import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { captureException, captureMessage } from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import { omitDeep } from '../utils/omitDeep';
import { ENV } from '../utils/env';

const sensitiveFields = [
  'token',
  'access_token',
  'refresh_token',
  'accessToken',
  'refreshToken',
  'first_name',
  'last_name',
  'firstName',
  'lastName',
  'email',
  'phone',
];

class ErrorHandling {
  constructor() {
    if (ENV.STAND === 'local') {
      return;
    }

    const integrations: Sentry.BrowserOptions['integrations'] = [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ];

    if (ENV.STAND !== 'local') {
      integrations.push(new Sentry.Replay());
    }

    Sentry.init({
      dsn: 'https://603edb73c37501aa2ca61938f4fb1c38@o476151.ingest.sentry.io/4506456309170176',
      environment: ENV.STAND,
      integrations,
      tracesSampleRate: ENV.STAND !== 'local' ? 0.01 : 0,
      beforeSend: (event) => omitDeep(event, sensitiveFields),
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  captureException(maybeException: any) {
    try {
      const exception =
        maybeException instanceof Error
          ? maybeException
          : Object.assign(
              new Error(),
              maybeException instanceof Object
                ? maybeException
                : { message: String(maybeException) },
            );

      captureException(exception);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error?.(error);
    }
  }

  identify(userId: string) {
    Sentry.setUser({ id: userId });
  }

  clearIdentity() {
    Sentry.configureScope((scope) => scope.setUser(null));
  }

  captureMessage(message: string) {
    try {
      captureMessage(message);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error?.(error);
    }
  }
}

const errorHandling = new ErrorHandling();

export { ErrorHandling, errorHandling };
