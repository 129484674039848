import { useQuery } from '@tanstack/react-query';
import { graphql } from '../gql';
import { ReactQueryKeys } from './reactQueryKeys';
import { graphqlRequest } from '../api/graphqlRequest';

const query = graphql(/* GraphQL */ `
  query Docs {
    files(orderBy: CREATED_AT_DESC) {
      id
      uuid
      name
      metaData
      createdAt
      project {
        title
      }
    }
  }
`);

export const useDocList = () => {
  return useQuery({
    queryKey: [ReactQueryKeys.Docs],
    queryFn: async () => {
      const response = await graphqlRequest(query);

      if (!response.files) {
        return [];
      }

      return response.files;
    },
  });
};
