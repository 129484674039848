import styled from 'styled-components';

export const Container = styled.div<{ size: number; color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) =>
      props.size / 2.5}px; /* Adjust the proportion as needed */
    height: ${(props) =>
      props.size / 2.5}px; /* Adjust the proportion as needed */
    margin: ${(props) =>
      props.size / 10}px; /* Adjust the proportion as needed */
    border: ${(props) => props.size / 20}px solid #fff; /* Adjust the proportion as needed */
    border-radius: 50%;
    animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
