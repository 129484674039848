import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 76px);

  @media (min-width: 768px) {
    border-radius: 24px;
    height: 100%;
  }
`;

export const Header = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;

    border-bottom: 1px solid #dfdfe8;
  }
`;

export const CompanyName = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const Description = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
`;

export const LogoImage = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 66px;
  height: 40px;
  flex-shrink: 0;
`;

export const Scrollable = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f8fafb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d2d9e1;
    outline: none;
    border-radius: 4px;
  }
`;

export const Content = styled.div<{ isEmpty: boolean }>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
  margin-top: ${({ isEmpty }) => (isEmpty ? '32px' : 'auto')};
`;

export const Footer = styled.div`
  border-top: 1px solid #dfdfe8;
  padding: 16px;

  position: relative;

  @media (min-width: 768px) {
    padding: 24px 16px;
  }
`;

export const SendButton = styled.div<{ isDisabled: boolean }>`
  transform: rotate(42deg);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isDisabled }) => (isDisabled ? '#9C9CAA' : '#009688')};
`;

export const IncomingMessageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const IncomingMessageContent = styled.div`
  flex: 1;
  border-radius: 16px 16px 16px 0px;
  background: #f7f7fc;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 8px;
  text-align: left;

  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    margin: 0;
  }

  b,
  strong {
    font-weight: 500;
  }
`;

export const OutgoingMessageContainer = styled.div`
  margin-left: 40px;
  text-align: right;
`;

export const OutgoingMessageContent = styled(IncomingMessageContent)`
  border-radius: 16px 16px 0px 16px;
  background: #e3f6f4;
  display: inline-flex;
`;

export const IncomingMessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const Author = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const Time = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  color: #3c3c4399;
`;
