import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  createRoutesFromElements,
  createBrowserRouter,
  Route,
  Navigate,
} from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import './index.css';
import { Guard } from './Guard';
import reportWebVitals from './utils/reportWebVitals';
import { Invite, Login, LoginAsCompany } from './features/Auth';
import './services/errorHandling';
import { MainLayout } from './layouts/MainLayout';
import { Home } from './features/Home';
import { ProjectsAndServices } from './features/ProjectsAndServices';
import { Requests } from './features/Requests';
import { Docs } from './features/Docs';
import { Chat } from './features/Chat';
import { RequestAndSignUp } from './layouts/RequestAndSignUp';
import { NotFound } from './features/NotFound';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ModalsProvider } from './kit/components/PromiseModal';
import { ToastProvider } from './kit/components/Toast';
import { sessionService } from './services/SessionService';
import { parseWorkspaceFromUrl } from './utils/parseWorkspaceFromUrl';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      onError: (error: unknown) => {
        if (!error) {
          return;
        }

        if (
          typeof error === 'object' &&
          (error as { response: { statusCode: number } })?.response
            ?.statusCode === 403
        ) {
          sessionService.logout(
            parseWorkspaceFromUrl(window.location.href) ?? '',
          );

          window.location.href = `/${parseWorkspaceFromUrl(
            window.location.href,
          )}/auth/login`;
        }
      },
    },
  },
});

const RedirectOrNotFound = () => {
  const authorizedWorkspace = sessionService.getFirstAuthorizedWorkspace();

  if (authorizedWorkspace) {
    return <Navigate to={`/${authorizedWorkspace}`} />;
  }

  return <NotFound />;
};

const AppRoot = () => (
  <QueryClientProvider client={queryClient}>
    <ModalsProvider>
      <Guard />
      <ReactQueryDevtools />
    </ModalsProvider>
    <ToastProvider />
  </QueryClientProvider>
);

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path=":workspaceSlug" element={<AppRoot />}>
      <Route path="invitation" element={<Invite />} />
      <Route path="auth/login" element={<Login />} />
      <Route path="auth/login-as-company" element={<LoginAsCompany />} />
      <Route path="new" element={<RequestAndSignUp />} />
      <Route path="*" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path="requests" element={<Requests />} />
        <Route path="projects-and-services" element={<ProjectsAndServices />} />
        <Route path="docs" element={<Docs />} />
        <Route path="chat" element={<Chat />} />
        <Route path="*" element={<Navigate to=".." />} />
      </Route>
    </Route>,
    <Route path="*" element={<RedirectOrNotFound />} />,
  ]),
);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
