import React, { forwardRef, useCallback, useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarContainer, ClearButton, Container } from './styled';
import { Input } from '../Input';
import { CalendarIcon } from '../icons/Calendar';
import { XIcon } from '../icons/X';

const Calendar = ({ children }: { children: React.ReactNode }) => {
  return <CalendarContainer>{children}</CalendarContainer>;
};

const formatDate = (date: Date) => {
  return new Date(date).toLocaleString('en-US', {
    dateStyle: 'short',
  });
};

interface Props {
  value: Date | null;
  placeholder?: string;
  isClearable?: boolean;
  onChange: (value: Date | null) => void;
}

export const DatePicker = ({
  value,
  placeholder = 'Pick a date',
  isClearable = true,
  onChange,
}: Props) => {
  const handleClear = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const InputComponent = useMemo(
    () =>
      forwardRef<
        HTMLInputElement,
        {
          onClick?: React.MouseEventHandler;
        }
      >((props, ref) => {
        return (
          <Input
            {...props}
            className="date-field-input"
            ref={ref}
            value={value ? formatDate(value) : ''}
            placeholder={placeholder}
            leftAddon={<CalendarIcon color="#009688" size="16px" />}
            rightAddon={
              isClearable &&
              value && (
                <ClearButton>
                  <XIcon size="16px" onClick={handleClear} />
                </ClearButton>
              )
            }
          />
        );
      }),
    [placeholder, value, isClearable, handleClear],
  );

  return (
    <Container>
      <ReactDatePicker
        preventOpenOnFocus={false}
        customInput={<InputComponent />}
        popperContainer={Calendar}
        selected={value}
        onChange={onChange}
      />
    </Container>
  );
};
