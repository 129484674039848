import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  FloatingArrow,
  arrow,
  useClick,
  FloatingFocusManager,
} from '@floating-ui/react';
import type { Placement } from '@floating-ui/react';
import { Content } from './styled';
import React, { useMemo, useRef, useState } from 'react';

interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const usePopover = ({
  initialOpen = false,
  placement = 'top',
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: TooltipOptions = {}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const arrowRef = useRef(null);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      shift({ padding: 5 }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const context = data.context;

  const click = useClick(context, {
    enabled: controlledOpen == null,
  });

  const dismiss = useDismiss(context);

  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data],
  );
};

export const Popover = ({
  children: trigger,
  content,
  ...options
}: {
  children: React.ReactNode;
  content: React.ReactNode;
} & TooltipOptions) => {
  const popover = usePopover(options);

  const triggerRef = (trigger as any).ref;
  const mergedTriggerFef = useMergeRefs([
    popover.refs.setReference,
    triggerRef,
  ]);

  const mergedContentRef = useMergeRefs([popover.refs.setFloating]);

  if (!React.isValidElement(trigger) || !React.isValidElement(content)) {
    return null;
  }

  return (
    <>
      {React.cloneElement(
        trigger,
        popover.getReferenceProps({
          ref: mergedTriggerFef,
          ...trigger.props,
          'data-state': popover.open ? 'open' : 'closed',
        }),
      )}

      {popover.context.open && (
        <FloatingPortal>
          <FloatingFocusManager context={popover.context} modal={false}>
            <Content
              ref={mergedContentRef}
              style={popover.floatingStyles}
              {...popover.getFloatingProps(options)}
            >
              {content}
            </Content>
          </FloatingFocusManager>
          <FloatingArrow ref={popover.arrowRef} context={popover.context} />
        </FloatingPortal>
      )}
    </>
  );
};
