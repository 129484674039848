import React, { forwardRef } from 'react';
import {
  Asterisk,
  Container,
  Description,
  Error,
  InputWrapper,
  Label,
} from './styled';
import { UserInputError } from '../UserInputError';
import { useFormValidationContext } from '../validation';

interface Props {
  label?: React.ReactNode;
  error?: string;
  description?: React.ReactNode;
  children: React.ReactNode;
  name?: string;
}

export const FormField = forwardRef<HTMLDivElement, Props>(
  ({ label, children, description, error, name }, ref) => {
    const context = useFormValidationContext();

    const isRequired = Boolean(name && context?.[name]?.('').required);

    return (
      <Container ref={ref} className="field-container">
        {label && (
          <Label>
            {label} {isRequired && <Asterisk>*</Asterisk>}
          </Label>
        )}
        <InputWrapper>{children}</InputWrapper>

        {error && (
          <Error>
            <UserInputError>{error}</UserInputError>
          </Error>
        )}
        {description && <Description>{description}</Description>}
      </Container>
    );
  },
);
