import { apiFetchWithAuth } from './fetch';

export const commentsApi = {
  create: ({ comment, projectId }: { comment: string; projectId?: number }) =>
    apiFetchWithAuth<{ id: number; comment: string }>({
      url: '/portal-service/comments',
      method: 'POST',
      data: {
        type: 'PROJECT',
        scope: 'PORTAL_COMMUNICATION',
        comment,
        projectId,
      },
    }),
};
