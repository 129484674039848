import styled from 'styled-components';
import { Panel } from '../../../kit/ui/Panel';

export const Container = styled(Panel)`
  background-color: #f0f5ff;
  border-color: transparent;

  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    display: grid;
    align-items: center;
    grid-template-columns: 220px 1fr minmax(100px, 250px);
    gap: 24px;
  }
`;

export const ImgContainer = styled.div`
  max-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    max-height: 150px;
    max-width: 100%;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
`;
