import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FileRow = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  overflow: hidden;
  white-space: nowrap;
`;

export const FileRowLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  text-decoration: none;
  cursor: pointer;

  min-width: 0;
`;

export const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileRowRight = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  white-space: nowrap;
`;

export const RemoveFileButton = styled.div`
  padding: 4px;
  cursor: pointer;
  color: #9c9caa;

  &:hover {
    color: #000;
  }
`;

export const ThumbnailContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

export const Thumbnail = styled.div<{ url: string }>`
  flex-shrink: 0;
  min-width: 32px;
  width: 32px;
  height: 32px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  border-radius: 4px;
  cursor: pointer;
`;

export const ProjectSelector = styled.div`
  display: flex;
  gap: 8px;

  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const Label = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
`;

export const ProjectOption = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 8px 9px 4px;
  gap: 8px;
  min-width: 0;

  @media (min-width: 768px) {
    max-width: 300px;
  }
`;

export const ProjectInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0;
`;

export const ProjectTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const AddressLink = styled.a`
  color: #235dff;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  text-decoration: none;
  display: flex;
  gap: 8px;
  text-decoration: none;

  cursor: pointer;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    flex-shrink: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    color: #235dff;
  }
`;
