import React, { useCallback, useMemo } from 'react';
import {
  StageContainer,
  StageContent,
  StageDescription,
  StageHeader,
  StageName,
  TasksProgress,
} from './styled';
import { CheckCircleIcon } from '../../../ui/icons/CheckCircle';
import { CircleIcon } from '../../../ui/icons/Circle';
import { Badge } from '../../../ui/Badge';
import { ProjectStage } from './types';
import { useCollapse } from 'react-collapsed';
import { ChevronUpIcon } from '../../../ui/icons/ChevronUp';
import { ChevronDownIcon } from '../../../ui/icons/ChevronDown';
import { TruncatedText } from '../../../ui/TruncatedText';
import { Events } from '../Events';
import { ActionStatus } from '../../../../gql/graphql';
import { ProgressCircle } from '../../../ui/ProgressCircle';
import { DiscFilledIcon } from '../../../ui/icons/DiscFilled';

interface Props {
  index: number;
  stage: ProjectStage;
  isFuture: boolean;
  isCurrent: boolean;
  isExpanded: boolean;
  onClick: (id: number) => void;
}

export const Stage = ({
  index,
  stage,
  isFuture,
  isCurrent,
  isExpanded,
  onClick,
}: Props) => {
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });

  const handleClick = useCallback(() => {
    onClick(stage.id);
  }, [onClick, stage.id]);

  const notCompletedTasks = useMemo(
    () =>
      stage.actions.filter(
        (action) =>
          action.status !== ActionStatus.Completed &&
          action.status !== ActionStatus.Cancelled,
      ),
    [stage.actions],
  );
  const completedTasksCount = stage.actions.length - notCompletedTasks.length;

  const isCompleted = !isFuture && !isCurrent && notCompletedTasks.length === 0;

  return (
    <StageContainer isFuture={isFuture}>
      <StageHeader
        title={stage.name}
        {...getToggleProps({ onClick: handleClick })}
      >
        {isCompleted && <CheckCircleIcon color="#009A47" size="24px" />}
        {(isCurrent || notCompletedTasks.length > 0) && (
          <DiscFilledIcon color="#235DFF" size="24px" />
        )}
        {isFuture && notCompletedTasks.length === 0 && (
          <CircleIcon size="24px" />
        )}
        <StageName>
          {index + 1}. {stage.name}
        </StageName>
        {isExpanded ? (
          <ChevronUpIcon size="24px" color="#9C9CAA" />
        ) : (
          <ChevronDownIcon size="24px" color="#9C9CAA" />
        )}

        {isCompleted && (
          <Badge color="#009A47" bgColor="#CDF3DF">
            COMPLETED
          </Badge>
        )}
        {!isCompleted && stage.actions.length > 0 && (
          <TasksProgress>
            <ProgressCircle
              progress={(completedTasksCount / stage.actions.length) * 100}
              color="#235DFF"
              size={20}
            />
            <div>
              {completedTasksCount}/{stage.actions.length} tasks
            </div>
          </TasksProgress>
        )}
      </StageHeader>
      <StageContent {...getCollapseProps()}>
        <div>
          <StageDescription>
            {stage.description && (
              <TruncatedText text={stage.description} maxChars={200} />
            )}
          </StageDescription>

          <Events actions={stage.actions} visits={stage.visits} />
        </div>
      </StageContent>
    </StageContainer>
  );
};
