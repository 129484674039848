const STORAGE_KEY = 'customer_portal_tokens';

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

type TokenWithExp = {
  token: string;
  expiresAt: Date;
  _isUser: boolean;
};

type LocalStorageTokenRecord = {
  token: string;
  expiresAt: number;
};

class SessionService {
  private static _instance: SessionService;
  private _tokens: Map<string, TokenWithExp>;
  private _expiresAt: Date | null;
  private _isUser: boolean;

  private constructor() {
    this._tokens = new Map();
    this._expiresAt = null;
    this._isUser = true;
    const tokens = localStorage.getItem(STORAGE_KEY);

    if (tokens) {
      const parsedTokens = new Map<string, LocalStorageTokenRecord>(
        JSON.parse(tokens),
      );

      for (const [key, value] of parsedTokens) {
        const { expiresAt, token } = value;

        const isExpired = Date.now() > expiresAt;

        if (!isExpired) {
          this._tokens.set(key, {
            token,
            expiresAt: new Date(expiresAt),
            _isUser: parseJwt(token).type !== 'company',
          });
        }
      }
    }
  }

  private _isTokenExpired(workspace: string): boolean {
    if (!this._tokens.has(workspace)) {
      return true;
    }

    return Date.now() > this._tokens.get(workspace)!.expiresAt.getTime();
  }

  public static get instance(): SessionService {
    if (!this._instance) {
      this._instance = new SessionService();
    }
    return this._instance;
  }

  public setToken(
    workspace: string,
    token: string,
    expiresInSeconds: number,
  ): void {
    const newTokenRecord: TokenWithExp = {
      token,
      expiresAt: new Date(Date.now() + expiresInSeconds * 1000),
      _isUser: parseJwt(token).type !== 'company',
    };

    this._tokens.set(workspace, newTokenRecord);

    localStorage.setItem(STORAGE_KEY, JSON.stringify([...this._tokens]));
  }

  public getToken(workspace: string): string | undefined {
    if (this.isAuthorized(workspace)) {
      return this._tokens.get(workspace)?.token;
    } else {
      this.logout(workspace);
      return undefined;
    }
  }

  public isAuthorized(workspace: string): boolean {
    return !this._isTokenExpired(workspace);
  }

  public logout(workspace: string): void {
    this._tokens.delete(workspace);
    localStorage.setItem(STORAGE_KEY, JSON.stringify([...this._tokens]));
  }

  public isUser(workspace: string): boolean {
    return this._tokens.get(workspace)?._isUser ?? false;
  }

  public getFirstAuthorizedWorkspace(): string | undefined {
    for (const [key] of this._tokens) {
      if (!this._isTokenExpired(key)) {
        return key;
      }
    }

    return undefined;
  }
}

export const sessionService = SessionService.instance;
