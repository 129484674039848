import React, { useCallback, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import usePlacesAutocomplete, { Suggestion } from 'use-places-autocomplete';
import { useUpdateEffect } from '@react-hookz/web';
import { FormField } from '../../FormField';
import { FormControl, FormInputPropsToOmit } from '../../types';
import { useControllerWithValidation } from '../../useControllerWithValidation';
import { Option } from './styled';
import { Autocomplete } from '../../../../ui/Select';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<
      React.ComponentProps<typeof Autocomplete>,
      FormInputPropsToOmit | 'options'
    >,
    FormControl<TFieldValues, TName> {}

export const toSuggestion = (address: string): Suggestion => ({
  description: address,
  structured_formatting: {
    main_text: address,
  },
});

export const toAddress = (suggestion: Suggestion): string =>
  suggestion?.description || '';

export const AddressField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  label,
  description,
  name,
  control,
  clearOnUnmount,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { value: fieldValue, onChange, ...controlProps },
    fieldState: { error },
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const [value, setValue] = useState(
    fieldValue ? toSuggestion(fieldValue) : null,
  );

  const handleChange = useCallback((value: string | null) => {
    setValue(value);
  }, []);

  useUpdateEffect(() => {
    if (fieldValue !== toAddress(value)) {
      setValue(toSuggestion(fieldValue));
    }
  }, [fieldValue]);

  useUpdateEffect(() => {
    onChange(toAddress(value));
  }, [value, onChange]);

  const {
    value: searchValue,
    setValue: setSearchValue,
    suggestions: { data },
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ['us'] },
    },
  });

  const defaultSuggestion = toSuggestion(searchValue);

  return (
    <FormField
      name={name}
      label={label}
      error={error?.message}
      description={description}
    >
      <Autocomplete
        value={value}
        filterOptions={(options) => options}
        getSelectedValue={toAddress}
        renderOption={(option) => (
          <Option>
            <strong>{option.structured_formatting.main_text}</strong>
            <small>{option.structured_formatting.secondary_text}</small>
          </Option>
        )}
        onInputChange={setSearchValue}
        options={
          (data.length && data) ||
          (!data.length && searchValue && [defaultSuggestion]) ||
          []
        }
        {...controlProps}
        {...inputProps}
        data-test-id={`field-${controlProps.name}`}
        onChange={handleChange}
      />
    </FormField>
  );
};
