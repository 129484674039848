import styled from 'styled-components';

export const Container = styled.div`
  .react-datepicker-wrapper {
    display: block;
  }
`;

export const CalendarContainer = styled.div`
  .react-datepicker-popper {
    box-shadow:
      0px 5px 5px -3px #00000033,
      0px 8px 10px 1px #00000024,
      0px 3px 14px 2px #0000001f;
    position: relative;
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
  }

  .react-datepicker {
    border: none;

    &__navigation {
      position: absolute;
      top: 20px;
      width: 20px;
      height: 20px;
      background: none;
      border: none;
      color: #98a9bc;
      cursor: pointer;

      &:hover {
        color: #778ca2;
      }

      &--previous {
        left: 14px;
      }
      &--next {
        right: 14px;
      }
    }

    &__month-container {
      .react-datepicker {
        &__header {
          background: none;
          border: none;
          padding: 0;

          &--has-time-select {
          }
        }

        &__month {
          margin: 0;

          &--selecting-range {
          }

          &-container {
            float: none;
          }
        }

        &__current-month {
          font: 14px/1.25 var(--font-family);
          margin: 8px 0 0 0;
          text-align: center;
        }

        &__day-names {
          display: flex;
          margin: 15px 0px 0 0;
        }

        &__day-name {
          text-align: center;
        }

        &__week {
          display: flex;
        }

        &__day {
          font: 14px/1.25 var(--font-family);
          color: #1d1d35;
          border-radius: 4px;
          width: 30px;
          line-height: 30px;
          margin: 3px;
          text-align: center;
          cursor: pointer;

          &:hover {
            color: #1d1d35;
            background-color: rgba(152, 169, 188, 0.21);
          }

          &--in-range,
          &--in-selecting-range {
            background-color: rgba(152, 169, 188, 0.21);
          }
          &--selected,
          &--keyboard-selected {
            background-color: #009688;
            color: #fff;
          }
          &--disabled {
            color: #98a9bc;
            &:hover {
              background-color: #fff;
              color: #98a9bc;
              cursor: not-allowed;
            }
          }

          &-name {
            font: 12px/1.25 var(--font-family);
            color: #778ca2;
            font-weight: 300;
            width: 30px;
            line-height: 20px;
            text-align: center;
            margin: 3px;
          }
        }
      }
    }
  }
`;

export const ClearButton = styled.div`
  padding: 4px;
  cursor: pointer;
  color: #9c9caa;
  transition: color 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #000;
  }
`;
