import React from 'react';
import { H2 } from '../../../kit/ui/Heading';
import { useProjects } from '../../../apiHooks/useProjects';
import { List, PlaceholderPrimary, PlaceholderSecondary } from './styled';

import { Project } from '../../../kit/components/Project';

export const Projects = () => {
  const { data: projects = [], isLoading } = useProjects();

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <H2>Your Projects ({projects.length})</H2>

      {projects.length === 0 && (
        <div>
          <PlaceholderPrimary>You have no Projects</PlaceholderPrimary>
          <PlaceholderSecondary>
            We look forward to work with you!
          </PlaceholderSecondary>
        </div>
      )}

      {projects.length > 0 && (
        <List>
          {projects.map((project) => (
            <Project isCollapsible key={project.id} project={project} />
          ))}
        </List>
      )}
    </div>
  );
};
