import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useWorkerLocation } from '../../../apiHooks/useWorkerLocation';
import {
  DestinationMarker,
  Header,
  MapContainer,
  Panel,
  Title,
  TitleBold,
} from './styled';
import { DateTime, Duration } from 'luxon';
import {
  AppointmentType,
  useAppointments,
} from '../../../apiHooks/useAppointments';
import { Map } from '../../../kit/ui/Map';
import { Layer, MapRef, Marker, Source } from 'react-map-gl';
import { LngLatBounds } from 'mapbox-gl';
import { useDeepCompareEffect } from '@react-hookz/web';
import { useMapboxDirections } from '../../../apiHooks/useMapboxDirections';
import distance from '@turf/distance';
import { HomeIcon } from '../../../kit/ui/icons/Home';
import { BuildingIcon } from '../../../kit/ui/icons/Building';
import { TaskStatus } from '../../../gql/graphql';
import { TruckMarker } from './Truck';
import { useCompany } from '../../../apiHooks/useCompany';
import { useParams } from 'react-router-dom';
import { Position } from 'geojson';
import { TruckIcon } from '../../../kit/ui/icons/Truck';
import { useProjects } from '../../../apiHooks/useProjects';
import { useServiceVisits } from '../../../apiHooks/useServiceVisits';

const calculateAngle = (position1?: Position, position2?: Position) => {
  if (!position1 || !position2) {
    return 0;
  }

  const angle = Math.atan2(
    position2[0] - position1[0],
    position2[1] - position1[1],
  );

  return (angle * 180) / Math.PI;
};

const TRUCK_INITIAL_ANGLE_OFFSET = 94;

const formatDuration = (seconds: number) => {
  const duration = Duration.fromMillis(seconds * 1000);

  const shifted =
    duration.as('hours') >= 1
      ? duration.shiftTo('hours', 'minutes')
      : duration.shiftTo('minutes');

  return shifted.normalize().toHuman({ maximumFractionDigits: 0 });
};

const useLiveEstimate = (estimate?: {
  duration: number;
  createdAt: string;
}) => {
  const [liveEstimate, setLiveEstimate] = useState(estimate?.duration ?? 0);

  useEffect(() => {
    if (!estimate) {
      return;
    }

    const interval = setInterval(() => {
      setLiveEstimate(
        estimate.duration +
          DateTime.fromISO(estimate.createdAt).diffNow('seconds').seconds,
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [estimate]);

  return liveEstimate;
};

const useClientAddresses = () => {
  const { data: projects = [] } = useProjects();
  const { data: serviceVisits = [] } = useServiceVisits();

  return useMemo(() => {
    const addresses = new Set<string>();

    projects.forEach((project) => {
      if (project.isActive && project.address?.[0]) {
        addresses.add(project.address?.[0]);
      }
    });

    serviceVisits.forEach((serviceVisit) => {
      if (serviceVisit.task?.address) {
        addresses.add(serviceVisit.task.address);
      }
    });

    return Array.from(addresses);
  }, [projects, serviceVisits]);
};

export const AppointmentMap = () => {
  const { data: appointments = [] } = useAppointments();
  const { workspaceSlug } = useParams();
  const { data: company } = useCompany(workspaceSlug ?? '');

  const clientAddresses = useClientAddresses();

  const appointment = useMemo(
    () =>
      appointments.find(
        (appointment) =>
          [
            AppointmentType.ServiceVisit,
            AppointmentType.WorkOrderVisit,
          ].includes(appointment.type) &&
          appointment.taskStatus === TaskStatus.OnTheWay &&
          DateTime.fromJSDate(appointment.startDate, { zone: 'utc' }) >=
            DateTime.now().startOf('day') &&
          appointment.endDate &&
          DateTime.fromJSDate(appointment.endDate, { zone: 'utc' }) <=
            DateTime.now().endOf('day'),
      ),
    [appointments],
  );

  const appointmentLocation = useMemo(() => {
    if (!appointment) {
      return null;
    }

    if (appointment.geoLocation) {
      return {
        lat: appointment.geoLocation[0],
        lon: appointment.geoLocation[1],
      };
    }

    return null;
  }, [appointment]);

  const { data: { location: workerLocation, estimate } = {} } =
    useWorkerLocation(appointment?.taskId);

  const liveEstimate = useLiveEstimate(estimate);

  const [firstWorkerLocation, setFirstWorkerLocation] = useState<
    typeof workerLocation | null
  >(null);

  useEffect(() => {
    if (!firstWorkerLocation && workerLocation) {
      setFirstWorkerLocation(workerLocation);
    }
  }, [firstWorkerLocation, workerLocation]);

  const mapRef = useRef<MapRef>(null);

  useDeepCompareEffect(() => {
    if (appointmentLocation && firstWorkerLocation) {
      const startAndFinish = new LngLatBounds(
        {
          lat: firstWorkerLocation.latitude,
          lng: firstWorkerLocation.longitude,
        },
        {
          lat: firstWorkerLocation.latitude,
          lng: firstWorkerLocation.longitude,
        },
      ).extend(appointmentLocation);

      mapRef.current?.fitBounds(startAndFinish, {
        padding: { top: 120, bottom: 24, left: 24, right: 24 },
        linear: true,
      });
    }
  }, [appointmentLocation, firstWorkerLocation]);

  const { data: route } = useMapboxDirections(
    [
      firstWorkerLocation
        ? {
            lat: firstWorkerLocation.latitude,
            lon: firstWorkerLocation.longitude,
          }
        : null,
      appointmentLocation,
    ].filter(Boolean) as { lat: number; lon: number }[],
  );

  const [remainingRoute, setRemainingRoute] = useState<typeof route | null>(
    null,
  );

  useEffect(() => {
    if (!workerLocation || !route) {
      return;
    }

    let closestPointIndex = -1;
    let closestDistance = Number.MAX_VALUE;
    route.geometry.coordinates.forEach((point, index) => {
      const d = distance(
        [workerLocation.longitude, workerLocation.latitude],
        point,
        {
          units: 'meters',
        },
      );

      if (d < closestDistance) {
        closestDistance = d;
        closestPointIndex = index;
      }
    });

    if (closestPointIndex >= 0) {
      setRemainingRoute({
        geometry: {
          type: 'LineString',
          coordinates: route.geometry.coordinates.slice(closestPointIndex),
        },
      });
    }
  }, [workerLocation, route]);

  const truckRotation = calculateAngle(
    workerLocation
      ? [workerLocation.longitude, workerLocation.latitude]
      : undefined,
    remainingRoute ? remainingRoute.geometry.coordinates[1] : undefined,
  );

  const timeOfArrival = useMemo(() => {
    if (!estimate) {
      return null;
    }

    return DateTime.fromISO(estimate.createdAt)
      .plus({ seconds: estimate.duration })
      .toFormat('H:mm');
  }, [estimate]);

  if (!appointment || !workerLocation) {
    return null;
  }

  return (
    <Panel>
      <Header>
        <TruckIcon size="24px" color="#009688" />

        <Title>
          Hey! Your technician{' '}
          <TitleBold>
            {appointment.assignee?.firstName} {appointment.assignee?.lastName}
          </TitleBold>{' '}
          is on the way
          {clientAddresses.length > 1 && ` to ${appointment.address}`}.
          {estimate && (
            <div>
              Estimated time of arrival is{' '}
              <TitleBold>
                {timeOfArrival}
                {liveEstimate > 60 && ` (${formatDuration(liveEstimate)} away)`}
              </TitleBold>
              .
            </div>
          )}
        </Title>
      </Header>

      <MapContainer>
        <Map
          ref={mapRef}
          initialViewState={{
            latitude: appointment.geoLocation?.[0],
            longitude: appointment.geoLocation?.[1],
            zoom: 13,
          }}
          style={{ border: 'none', borderRadius: '0 0 16px 16px' }}
        >
          {workerLocation && (
            <Marker
              rotation={truckRotation + TRUCK_INITIAL_ANGLE_OFFSET}
              longitude={workerLocation.longitude}
              latitude={workerLocation.latitude}
            >
              <TruckMarker logoUrl={company?.logoUrl} />
            </Marker>
          )}
          {appointment && appointment.geoLocation && (
            <Marker
              longitude={appointment.geoLocation[1]}
              latitude={appointment.geoLocation[0]}
            >
              <DestinationMarker>
                {appointment.projectAccountType === 'COMMERCIAL' ? (
                  <BuildingIcon size={12} color="#9C9CAA" />
                ) : (
                  <HomeIcon size={12} color="#9C9CAA" />
                )}
              </DestinationMarker>
            </Marker>
          )}
          <Source type="geojson" data={remainingRoute?.geometry}>
            <Layer
              type="line"
              layout={{
                'line-cap': 'round',
                'line-join': 'round',
              }}
              paint={{
                'line-color': '#009688',
                'line-width': 3,
              }}
            />
          </Source>
        </Map>
      </MapContainer>
    </Panel>
  );
};
