import styled from 'styled-components';
import { ModalBody, ModalFooter } from '../../kit/components/PromiseModal';

export const Container = styled.div<{
  isHighlighted?: boolean;
  isDimmed?: boolean;
}>`
  padding: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  ${({ isDimmed }) =>
    isDimmed &&
    `
    color: #828D9A;
  `}
`;

export const HeaderLeft = styled.div`
  max-width: 100%;

  @media (min-width: 768px) {
    max-width: calc(100% - 150px);
  }
`;

export const Description = styled.div`
  flex: 1 1 100%;
  font-size: 14px;
  font-weight: 400;
  flex: 1 1 100%;
`;

export const UploadButton = styled.div`
  order: 1;

  @media (min-width: 768px) {
    order: initial;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 8px;
`;

export const When = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  ${Title} {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

export const AddressLink = styled.a`
  margin: 8px 0;
  color: #235dff;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  text-decoration: none;
  display: inline-flex;
  gap: 8px;
  text-decoration: none;
  cursor: pointer;
  svg {
    flex-shrink: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:link {
    color: #235dff;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ThumbnailContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

export const Thumbnail = styled.div<{ url: string }>`
  flex-shrink: 0;
  min-width: 32px;
  width: 32px;
  height: 32px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  border-radius: 4px;
  cursor: pointer;
`;

export const Spinner = styled.div`
  min-width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const FileRow = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  overflow: hidden;
  white-space: nowrap;
`;

export const FileRowLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  min-width: 0;
`;

export const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileRowRight = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const RemoveFileButton = styled.div`
  padding: 4px;
  cursor: pointer;
  color: #9c9caa;

  &:hover {
    color: #000;
  }
`;

export const ActionUploadContainer = styled.div`
  .cancel {
    display: none;
  }

  ${ModalBody} {
    max-height: calc(100vh - 72px - 64px - 60px);
    overflow: auto;
  }

  ${ModalFooter} {
    justify-content: center;
    > * {
      flex: 1;
    }
  }

  @media (min-width: 768px) {
    .cancel {
      display: flex;
    }

    ${ModalFooter} {
      justify-content: flex-end;
      > * {
        flex: initial;
      }
    }
  }
`;

export const ActionUploadHeader = styled.div`
  display: flex;
  gap: 8px;

  > svg {
    margin-top: -4px;
  }

  > *:last-child {
    flex: 1;
  }
`;

export const StatusAndDate = styled.div`
  @media (min-width: 768px) {
    text-align: right;
  }
`;

export const CompletedAt = styled(When)`
  margin-top: 4px;
`;
