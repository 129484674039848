import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { ReactQueryKeys } from './reactQueryKeys';
import {
  ChartDTO,
  fleetChartsApi,
  Meter,
  SystemEnergy,
  SystemPower,
  SystemStorageEnergy,
  TotalEnergy,
} from '../api/fleetCharts';
import { ChartPeriod, chartPeriodConfig } from '../models/system';
import { groupBy } from '../utils/groupBy';

export type EnergyPoint = {
  time: DateTime;
  production?: number;
  consumption?: number;
};

export type PowerPoint = {
  time: DateTime;
  power?: number;
};

export type ChartPoint = EnergyPoint | PowerPoint;

export type EnergyChart = {
  totalProduction: number | null;
  totalConsumption: number | null;
  totalExport: number | null;
  totalImport: number | null;
  totalCharge: number | null;
  totalDischarge: number | null;
  energy: EnergyPoint[];
  power: ChartPoint[];
};

export const useSystemHealthChart = (
  systemId: number,
  period: ChartPeriod,
  startAt?: DateTime<true>,
  endBefore?: DateTime<true>,
) =>
  useQuery<EnergyChart>({
    queryKey: [
      ReactQueryKeys.SystemHealthChart,
      systemId,
      { period, startAt, endBefore },
    ],
    queryFn: async () => {
      const periodConfig = chartPeriodConfig[period];

      const chartDtos: ChartDTO[] = [
        {
          metric: 'energy',
          kind: 'total',
          meter: Meter.Production,
          startAt: startAt?.toISO(),
          endBefore: endBefore?.toISO(),
          period: periodConfig.totalGranularity.toISO(),
        },
        {
          metric: 'energy',
          kind: 'points',
          meter: Meter.Production,
          startAt: startAt?.toISO(),
          endBefore: endBefore?.toISO(),
          period: periodConfig.pointGranularity.toISO(),
        },
        {
          metric: 'energy',
          kind: 'total',
          meter: Meter.Consumption,
          startAt: startAt?.toISO(),
          endBefore: endBefore?.toISO(),
          period: periodConfig.totalGranularity.toISO(),
        },
        {
          metric: 'energy',
          kind: 'points',
          meter: Meter.Consumption,
          startAt: startAt?.toISO(),
          endBefore: endBefore?.toISO(),
          period: periodConfig.pointGranularity.toISO(),
        },
        {
          metric: 'energy',
          kind: 'total',
          meter: Meter.Export,
          startAt: startAt?.toISO(),
          endBefore: endBefore?.toISO(),
          period: periodConfig.totalGranularity.toISO(),
        },
        {
          metric: 'energy',
          kind: 'total',
          meter: Meter.Import,
          startAt: startAt?.toISO(),
          endBefore: endBefore?.toISO(),
          period: periodConfig.totalGranularity.toISO(),
        },
        {
          metric: 'power',
          kind: 'points',
          meter: Meter.Production,
          startAt: startAt?.toISO(),
          endBefore: endBefore?.toISO(),
          period: periodConfig.pointGranularity.toISO(),
        },
        {
          metric: 'storageEnergy',
          kind: 'total',
          startAt: startAt?.toISO(),
          endBefore: endBefore?.toISO(),
          period: periodConfig.totalGranularity.toISO(),
        },
      ];

      const charts = await fleetChartsApi.getSystemsCharts({
        systems: [systemId],
        charts: chartDtos,
      });

      const [
        totalProduction,
        pointsProduction,
        totalConsumption,
        pointsConsumption,
        totalExport,
        totalImport,
        pointsPower,
        totalStorage,
      ] = charts as [
        totalProduction: TotalEnergy,
        pointsProduction: SystemEnergy[],
        totalConsumption: TotalEnergy,
        pointsConsumption: SystemEnergy[],
        totalExport: TotalEnergy,
        totalImport: TotalEnergy,
        pointsPower: SystemPower[],
        totalStorage: SystemStorageEnergy,
      ];

      const productionByTime = groupBy(pointsProduction, (point) => point.time);
      const consumptionByTime = groupBy(
        pointsConsumption,
        (point) => point.time,
      );

      const energyByTime: { [time: string]: EnergyPoint } = {};
      Object.entries(productionByTime).forEach(([time, production]) => {
        if (!(time in energyByTime)) {
          energyByTime[time] = { time: DateTime.fromISO(production[0].time) };
        }

        energyByTime[time].production = production[0].value;
      });
      Object.entries(consumptionByTime).forEach(([time, consumption]) => {
        if (!(time in energyByTime)) {
          energyByTime[time] = { time: DateTime.fromISO(consumption[0].time) };
        }

        energyByTime[time].consumption = consumption[0].value;
      });

      return {
        totalProduction: totalProduction.value,
        totalConsumption: totalConsumption.value,
        totalExport: totalExport.value,
        totalImport: totalImport.value,
        totalCharge: totalStorage.charge,
        totalDischarge: totalStorage.discharge,
        energy: Object.entries(energyByTime)
          .map(([_, point]) => point)
          .toSorted((p1, p2) => +p1.time - +p2.time),
        power: pointsPower.map((node) => ({
          time: DateTime.fromISO(node.time).toLocal(),
          power: node.value,
        })),
      };
    },
    keepPreviousData: true,
    enabled: !!systemId,
  });
