import styled, { css } from 'styled-components';
import { ButtonProps, ButtonSize, ButtonVariant } from './types';

export const Container = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 0.7px;
  border: none;
  cursor: ${(props) => (props.isLoading ? 'default' : 'pointer')};
  border-radius: 8px;
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
  padding: 0px 8px;
  height: 32px;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  border: 1px solid transparent;
  width: ${(props) => (props.isBlock ? '100%' : 'auto')};

  @media (min-width: 768px) {
    padding: 0px 20px;
  }

  ${({ size = ButtonSize.Default }) => {
    switch (size) {
      case ButtonSize.Large:
        return css`
          height: 40px;
          font-size: 14px;
        `;

      case ButtonSize.Small:
        return css`
          height: 24px;
        `;

      default:
        return css`
          height: 32px;
        `;
    }
  }};

  ${(props: any) => {
    switch (props.variant) {
      case ButtonVariant.Primary:
        return css`
          background-color: #009688;
          color: #ffffff;

          &:hover {
            background-color: ${props.isLoading ? '#009688' : '#00635a'};
          }

          &:active {
            background-color: #004a43;
          }

          &:focus {
            outline: none;
            border: 1px solid #000;
          }

          &:disabled {
            background-color: #dfdfe8;
            color: #ecf0f1;
            cursor: not-allowed;
          }
        `;

      case ButtonVariant.Secondary:
        return css`
          background-color: #dfdfe8;
          color: #1d1d35;

          &:hover {
            background-color: #c8c8d3;
          }

          &:active {
            background-color: #c8c8d3;
          }

          &:focus {
            outline: none;
            border: 1px solid #1d1d35;
          }

          &:disabled {
            background-color: #dfdfe8;
            color: #9c9caa;
            cursor: not-allowed;
          }
        `;
      case ButtonVariant.Flat:
        return css`
          background-color: transparent;
          color: #009688;

          &:hover {
            color: #00635a;
            background-color: #e3f6f4;
          }

          &:active {
            color: #004a43;
            background-color: #e3f6f4;
          }

          &:focus {
            outline: none;
            border: 1px solid #000;
          }

          &:disabled {
            color: #c8c8d3;
            cursor: not-allowed;
          }
        `;

      default:
        return null;
    }
  }}
`;

export const IconButton = styled(Container)<ButtonProps>`
  height: 32px;
  width: 32px;
  padding: 0;
  justify-content: center;
`;
