export class ApiError extends Error {
  payload: any;

  constructor(message: string, payload: any) {
    super(message);

    if (payload?.message) {
      this.payload = payload.message;
    }
  }
}
