import React, { useMemo, useState } from 'react';

import {
  useFloating,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
  offset,
  useClick,
  useTypeahead,
  FloatingOverlay,
} from '@floating-ui/react';

import {
  Chip,
  ChipList,
  Menu,
  Option,
  Placeholder,
  MultiSelectButton,
  MobileMenuHeader,
  MobileMenuFooter,
  SearchContainer,
  SelectGlobalStyle,
} from './styled';
import { XIcon } from '../icons/X';
import { ChevronUpIcon } from '../icons/ChevronUp';
import { ChevronDownIcon } from '../icons/ChevronDown';
import { CheckIcon } from '../icons/Check';
import { XCircleIcon } from '../icons/XCircle';
import { Button, ButtonSize, ButtonVariant } from '../Button';
import { elementOrParentHasClass } from '../../../utils/elementOrParentHasClass';
import { Input } from '../Input';
import { SearchIcon } from '../icons/Search';

interface Props {
  value: string[];
  options: string[];
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  onChange: (value: string[]) => void;
  autoFocus?: boolean;
}

export const MultiSelect = ({
  value = [],
  onChange,
  options,
  placeholder = 'Select',
  isDisabled = false,
  isClearable = true,
  autoFocus,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const isMobile = window.innerWidth <= 767;

  const filteredOptions = useMemo(() => {
    if (searchValue) {
      return options.filter((option) =>
        option.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }

    return options;
  }, [options, searchValue]);

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: (open, event, reason) => {
      if (
        event &&
        elementOrParentHasClass(event.target as HTMLElement, 'clear-icon')
      ) {
        return;
      }

      if (reason === 'outside-press') {
        if (isMobile) {
          return;
        }
      }
      setIsOpen(open);
    },
    middleware: [
      offset(3),
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = React.useRef<Array<HTMLElement | null>>([]);
  const listContentRef = React.useRef(options);
  const isTypingRef = React.useRef(false);
  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    // This is a large list, allow looping.
    loop: true,
  });
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    },
    enabled: !isMobile && !isSearchFocused,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, role, listNav, typeahead, click],
  );

  const handleSelect = (item: string) => {
    const newValue = value.includes(item)
      ? value.filter((i) => i !== item)
      : [...value, item];
    onChange(newValue);
  };

  const handleClose = () => {
    setIsOpen(false);
    setActiveIndex(null);
  };

  return (
    <>
      <SelectGlobalStyle />
      <MultiSelectButton
        ref={refs.setReference}
        type="button"
        autoFocus={autoFocus}
        isDisabled={isDisabled}
        {...getReferenceProps()}
      >
        {value.length > 0 && (
          <ChipList>
            {value.map((item) => (
              <Chip key={item}>
                {item}

                <XCircleIcon
                  size="16px"
                  className="clear-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(value.filter((i) => i !== item));
                  }}
                />
              </Chip>
            ))}
          </ChipList>
        )}

        {value.length === 0 && <Placeholder>{placeholder}</Placeholder>}

        {value.length > 0 && isClearable && (
          <XIcon
            className="clear-icon"
            size="16px"
            onClick={() => onChange([])}
          />
        )}

        {isOpen && (
          <ChevronUpIcon size="16px" onClick={() => setIsOpen(false)} />
        )}
        {!isOpen && (
          <ChevronDownIcon size="16px" onClick={() => setIsOpen(true)} />
        )}
      </MultiSelectButton>

      {isOpen && (
        <FloatingPortal>
          <FloatingOverlay className="backdrop">
            <FloatingFocusManager
              context={context}
              initialFocus={-1}
              visuallyHiddenDismiss
              modal={false}
            >
              <Menu
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
              >
                <MobileMenuHeader className="mobile">
                  Choose value
                </MobileMenuHeader>
                {options.length > 5 && (
                  <SearchContainer>
                    <Input
                      leftAddon={<SearchIcon color="#9C9CAA" size="16px" />}
                      value={searchValue}
                      onFocus={() => setIsSearchFocused(true)}
                      onBlur={() => setIsSearchFocused(false)}
                      onChange={(e) => {
                        setActiveIndex(null);
                        setSearchValue(e.target.value);
                      }}
                      placeholder="Search..."
                    />
                  </SearchContainer>
                )}
                {searchValue && filteredOptions.length === 0 && (
                  <Option isActive={false} isSelected={false}>
                    No results found
                  </Option>
                )}
                {filteredOptions.map((option, i) => (
                  <Option
                    key={option}
                    ref={(node) => {
                      listRef.current[i] = node;
                    }}
                    role="option"
                    tabIndex={i === activeIndex ? 0 : -1}
                    aria-selected={value.includes(option)}
                    isSelected={value.includes(option)}
                    isActive={i === activeIndex}
                    {...getItemProps({
                      onClick() {
                        setSelectedIndex(i);
                        handleSelect(option);
                      },
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          setSelectedIndex(i);
                          handleSelect(option);
                        }

                        if (event.key === ' ' && !isTypingRef.current) {
                          event.preventDefault();
                          setSelectedIndex(i);
                          handleSelect(option);
                        }
                      },
                    })}
                  >
                    {option}

                    <span
                      aria-hidden
                      style={{
                        position: 'absolute',
                        right: 10,
                      }}
                    >
                      {value.includes(option) && (
                        <CheckIcon size="16px" color="#009A47" />
                      )}
                    </span>
                  </Option>
                ))}
                <MobileMenuFooter className="mobile">
                  <Button
                    onClick={handleClose}
                    size={ButtonSize.Large}
                    variant={ButtonVariant.Primary}
                    isBlock
                  >
                    Select
                  </Button>
                </MobileMenuFooter>
              </Menu>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  );
};
