import React from 'react';
import { useMe } from '../../apiHooks/useMe';
import { H2 } from '../../kit/ui/Heading';
import { Container, Content } from './styled';
import { Reviews } from './Reviews';
import { useProjects } from '../../apiHooks/useProjects';
import { AppointmentMap } from './AppointmentMap';
import { ReferralWidget } from '../Referral';
import { ActiveProjects } from './ActiveProjects';
import { Services } from '../Services';
import { ActiveRequests } from './ActiveRequests';
import { Systems } from './Systems';

export const Home = () => {
  const { data: currentUser } = useMe();

  const { isLoading } = useProjects();
  return (
    <Container>
      <H2>Welcome, {currentUser?.me?.name}! 👋</H2>

      {!isLoading && (
        <Content>
          <AppointmentMap />
          <Services hideIfEmpty />
          <ActiveProjects />
          <ActiveRequests />
          <Systems />
          <ReferralWidget />
          <Reviews />
        </Content>
      )}
    </Container>
  );
};
