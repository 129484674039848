export const validateUrl = (url: unknown) => {
  if (!url || typeof url !== 'string' || !url.trim()) {
    return undefined;
  }

  const errorMessage = 'URL is not valid';

  if (url.startsWith('http://')) {
    return 'URL should be HTTPS';
  }

  const urlRegex = /^(https:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  if (!urlRegex.test(url)) {
    return errorMessage;
  }

  return undefined;
};
