import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f7f7fc;
  min-height: 100vh;
  padding: 16px;

  @media (min-width: 768px) {
    padding: 24px 32px;
  }
`;

export const Nav = styled.nav`
  min-height: 44px;

  @media (min-width: 768px) {
    min-height: 72px;
  }
`;

export const LogoImage = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 72px;
  height: 44px;
  flex-shrink: 0;
`;

export const LogoName = styled.div`
  font-size: 20px;
  font-weight: bold;
  max-width: 100px;
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 32px;

  @media (min-width: 768px) {
    margin-top: 72px;
    width: 752px;
    max-width: 100%;
    margin: 32px auto 0;
  }
`;

export const FormContainer = styled.div`
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #dfdfe8;
  padding: 16px;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const FormTitle = styled.h1`
  font-size: 20px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 24px;
`;

export const SuccessContainer = styled.div`
  padding: 24px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 16px;
  text-align: center;
  margin-bottom: 40px;
  max-width: 752px;

  @media (min-width: 768px) {
    padding: 56px 24px;
  }
`;

export const SuccessCircle = styled.div`
  width: 104px;
  height: 104px;
  border-radius: 50%;
  background-color: #e3f6f4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SuccessTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const SuccessDescription = styled.div`
  color: #828d9a;
  font-size: 20px;
  font-weight: 400;
  max-width: 608px;
`;

export const SuccessFooter = styled.div`
  text-align: center;
  color: #828d9a;
  font-size: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;
