import { apiFetchWithAuth } from './fetch';

export const documentsApi = {
  download: (documentUuid: string) =>
    apiFetchWithAuth<{ data: Blob; fileName: string; contentType: string }>({
      url: `/databank-service/portal/files/${documentUuid}/download`,
      method: 'GET',
      requestContentType: '',
    }),

  upload: ({
    file,
    actionId,
    projectId,
    stageId,
  }: {
    file: File;
    actionId?: number;
    projectId?: number;
    stageId?: number;
  }) => {
    const formData = new FormData();
    formData.append('file', file);
    if (actionId) {
      formData.append('actionId', actionId.toString());
    }

    if (projectId) {
      formData.append('projectId', projectId.toString());
    }

    if (stageId) {
      formData.append('stageId', stageId.toString());
    }

    return apiFetchWithAuth<{
      id: number;
      metaData: { size: number; thumbnailUrl: string };
      name: string;
    }>({
      url: `/databank-service/portal/files/upload`,
      method: 'POST',
      requestContentType: 'multipart/form-data',
      data: formData,
    });
  },
};
